import { ContentWrapper, Disclaimer, Div, H3, H4, Overlay, overlayAnimationVariant, P } from "styles"
import { CredentialsCard, ListItem, ProviderCredentialsWrapper, VerticalSeparator } from "./restaurants.styled"
import { ChevronRight, EditIcon, GreenCheckCircle } from "assets"
import { LogoCircle } from "pagesPartner/Dashboard/partnerDashboard.styled"
import { useNavigate, useParams } from "react-router"
import { useEffect, useState } from "react"
import { ProviderModal } from "./providerModal"
import { IProvider } from "./data"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { useRestaurants } from "store/restaurants"
import { useBrands } from "store/brands"

export const Providers = () => {

    const navigate = useNavigate()
    const [modal, setModal] = useState({ open: false, data: null })
    const { loading, getProviderStatuses, providers, clearProviderStatuses } = useRestaurants(state => state)
    const { getBrandsProviderStatuses, brandProviders, clearBrandsProviderStatuses } = useBrands(state => state)
    const brandsLoading = useBrands(state => state.loading)
    const { id, brandId } = useParams()
    const searchParams = new URLSearchParams(window.location.search);

    const handleOpenModal = (p: IProvider) => {
        setModal({
            open: true, data: { ...p.config, provider: p.provider, name: p.name }
        })
    }


    useEffect(() => {
        if (brandId) {
            getBrandsProviderStatuses(brandId, searchParams.get('provider'))
            return (() => {
                clearBrandsProviderStatuses()
            })
        } else {
            getProviderStatuses(id, searchParams.get('provider'))
            return (() => {
                clearProviderStatuses()
            })
        }

    }, [id, brandId])


    const displayProviders = brandId ? brandProviders : providers

    return <ContentWrapper>
        <SpinnerFullscreen loading={loading || brandsLoading} />
        <H3>{searchParams.get('name')}</H3>
        <Div $flex $column $gap="1rem" $p="0 2rem" >
            {displayProviders.map(p => <ListItem key={p.provider} onClick={() => navigate((p.isConnected ? 'disconnect/' : 'connect/') + p.provider + (p.config?.storeId ? ('?storeId=' + p?.config?.storeId) : ''))} >
                <Div $flex $alignCenter $gap="2rem">
                    <img src={p.logo} alt="" />
                    <VerticalSeparator />
                    <H4 $mb="0 !importnat" $noTranform $bold $textSpacing="0" >{p.name}</H4>
                </Div>
                {p.isConnected ? <GreenCheckCircle cName='check' /> : <ChevronRight />}
            </ListItem>)}
        </Div>
        <H3 $mt="3.5rem " $mb="2rem" color="primary" >Provider Credentials</H3>
        <ProviderCredentialsWrapper >

            {displayProviders.map(p => <CredentialsCard key={p.provider} >
                <Div $flex $gap="2rem" $alignCenter >
                    <LogoCircle>
                        <img src={p.logo} alt='' />
                    </LogoCircle>
                    <Div $height="100%" >
                        <H4 $noTranform $bold $textSpacing="0" $mb="0" >{p.name}</H4>
                        {p.config ? <>
                            <Disclaimer $mb=".2rem" >Username: {p.config.username}</Disclaimer>
                            <Disclaimer $mb=".2rem" >Password: ********</Disclaimer>
                            {p.config?.storeId && <Disclaimer $mb="0" >Store ID: {p.config?.storeId}</Disclaimer>}

                        </> : <P>Credentials not set</P>}
                    </Div>
                </Div>
                <Div $cursor="pointer " $height="100%" onClick={() => handleOpenModal(p)}>
                    <EditIcon />
                </Div>
            </CredentialsCard>)}

            {modal.open && <>
                <ProviderModal data={modal.data} handleClose={() => setModal({ open: false, data: null })} />
                <Overlay
                    key="modalOverlay"
                    variants={overlayAnimationVariant}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                /></>}
        </ProviderCredentialsWrapper>
    </ContentWrapper>
}

export interface IProviderStatusResponse {
    isConnected: boolean
    provider: 'doordash' | 'ubereats' | 'grubhub'
}

export interface IProviderConfigResponse {
    provider: 'doordash' | 'ubereats' | 'grubhub',
    username: string,
    password: string,
    storeId: string
}
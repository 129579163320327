import styled from "styled-components";


export const OnlineStatus = styled.div<{$active?:boolean}>`
    width:  10px;
    height: 10px;
    border-radius: 50px;
    background-color: ${({$active,theme})=>$active ? theme.green : theme.secondary};

`

export const ChevronCount = styled.div<{$active?:boolean}>`
  display: flex;
  gap: 2rem;
  align-items: center;

  svg{
    height: 1.2rem;
  transition: .3s ease-out;
    ${({$active})=>$active && 'transform: rotate(180deg);'}
    path{
      fill: ${({theme})=>theme.primary};
      stroke: none !important;
    }
  }

`
export const AgentsListContainer = styled.div<{$active?:boolean}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: .2s ease;
  height: ${({$active})=>$active ? 'max-content' : '8rem'};
  padding:1rem;
`

export const AgentsListItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 6rem;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  padding:  0 3rem;
  cursor: pointer;

`

export const NoAgentsContainer = styled.div`

display: flex;
flex-direction: column;
align-items: center;
gap: 2rem;

@media (max-width: 800px) {
  
   margin-top: 7rem;

   
  }

`
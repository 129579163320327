import {
  bagNWich,
  bagelBos,
  nycBagels,
  omeletteStation,
  phillyCheez,
  saladSnack,
  wrap,
} from 'assets';
import { AnimatedDiv, Separator } from 'components';
import { styled } from 'styled-components';
import { H4, Img, WrapperCenter } from 'styles';

const brands = [
  bagNWich,
  wrap,
  bagelBos,
  nycBagels,
  omeletteStation,
  phillyCheez,
  saladSnack,
];

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 6rem;

  img {
    transition: all 0.2s ease;
  }
  img:hover {
    transform: scale(1.4);
  }

  @media (max-width: 800px) {
    justify-content: center;
    img:hover {
      transform: scale(1.3);
    }
  }
`;

export const Section3 = () => {
  return (
    <>
      <WrapperCenter $gap='5rem' $gapSM='3rem' $reverse>
        <AnimatedDiv>
          <H4 $center>trusted by</H4>
        </AnimatedDiv>
        <AnimatedDiv>
          <BrandWrapper>
            {brands.map((brand, i) => (
              <Img
                $width='13rem'
                $widthSM='12rem'
                key={i}
                src={brand}
                alt='brand'
              />
            ))}
          </BrandWrapper>
        </AnimatedDiv>
        <Separator borderLeft borderRight />
      </WrapperCenter>
    </>
  );
};

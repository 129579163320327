import api from "api";
import Modal from "components/modal/modal";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { SuccessToast } from "components/Toasts/Toasts";
import { ToggleButton } from "components/toggleButton/toggleButton";
import { handleError } from "helpers";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CMS_LOGIN } from "routes/routeConstantsCMS";
import { useAuth } from "store/auth";
import { Button, ContentWrapper, Div, H3, H4, HR, P } from "styles";
import {
    PARTNER_PRIVACY_POLICY,
} from "routes/routeConstantsPartner";

export const Settings = () => {
    const { user, updateNotificationStatus, deleteAccount } = useAuth(
        (state) => state
    );
    const [active, setActive] = useState(user?.notificationsEnabled);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    const updateNotifications = async (enabled: boolean) => {
        setLoading(true);
        try {
            await api.put("v1/users/notification-status", { enabled });
            updateNotificationStatus(enabled);
            setActive(enabled);
            setLoading(false);
            SuccessToast("Notification status successfully changed.");
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const handleDelete = () => {
        deleteAccount();
        navigate(CMS_LOGIN);
    };

    return (
        <ContentWrapper $flex $between $column>
            <SpinnerFullscreen loading={loading} />
            <Div>
                <HR />
                <Div $flex $center $between $width="100%">
                    <Div>
                        <H4 $mb="0rem" $mt="2rem" $noTranform $bold $textSpacing="0">
                            Notifications
                        </H4>
                        <P $mb="2rem">Enable push notifications</P>
                    </Div>
                    <ToggleButton
                        onClick={() => updateNotifications(!active)}
                        active={active}
                    />
                </Div>
                <HR />
                <H4
                    $mb="2rem"
                    $mt="2rem"
                    $noTranform
                    $bold
                    $textSpacing="0"
                    $pointer
                    onClick={() => navigate('/portal-privacy-policy')}
                >
                    Privacy Policy
                </H4>
                <HR />
                <H4
                    $mb="2rem"
                    $mt="2rem"
                    $noTranform
                    $bold
                    $textSpacing="0"
                    $pointer
                    onClick={() => navigate('/portal-terms-and-conditions')}
                >
                    Terms & Conditions
                </H4>
                <HR />
            </Div>
            <Button $w="27rem" $m="5px 0" $round onClick={() => setModal(true)}>
                DELETE ACCOUNT
            </Button>
            <Modal isOpen={modal} setIsOpen={setModal} exitButton={false}>
                <H3 $mb="1.2rem" $center>
                    Delete Account
                </H3>

                <P $mb="2.4rem" $center>
                    Are you sure you want to delete your account?
                </P>

                <Div $flex $gap="1.6rem">
                    <Button onClick={handleDelete} $w="100%">
                        Yes
                    </Button>
                    <Button $w="100%" onClick={() => setModal(false)} $secondary>
                        Cancel
                    </Button>
                </Div>
            </Modal>
        </ContentWrapper>
    );
};

import { AnimatedDiv, Separator } from 'components';
import { useState } from 'react';
import { Div, H1, H4, P, PageWrapper } from 'styles';
import { FaqQuestion } from './faqQuestion';

export const Faq = () => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const faqData = [
    {
      question: 'What is the GRUBCON Concierge Team?',
      answer:
        'The GRUBCON Concierge Team is a group of professionals trained to analyze and recover potentially lost revenue due to order cancellations or adjustments.    ',
    },
    {
      question: 'How does the GRUBCON Concierge Team work?    ',
      answer:
        'The team receives automated notifications of cancellations or adjustments and immediately begins analyzing the reasons behind them. They review the data from the order and take necessary actions to recover any potentially lost revenue.      ',
    },
    {
      question:
        'What types of situations does the GRUBCON Concierge Team handle?    ',
      answer:
        'The team handles various situations, such as customer adjustments to orders, requests for refunds, or canceled orders that have already been prepared in the kitchen.      ',
    },
    {
      question:
        'How does the team recover lost revenue from customer adjustments?    ',
      answer: `In the case of customer adjustments, the team ensures that the requested changes are accurately made to the order. They communicate with the delivery apps' concierge service to make the necessary adjustments promptly.`,
    },
    {
      question:
        'How does the team handle canceled orders that have already been prepared?    ',
      answer:
        'If an order is canceled after it has been accepted and food preparation has started, the team immediately contacts the delivery provider to recover the lost revenue. Their goal is to prevent any food wastage and ensure that the restaurant does not suffer financially.    ',
    },
    {
      question: 'Do I need to contact the GRUBCON Concierge Team myself?',
      answer:
        'No, you do not need to contact the team directly. They are automatically notified of cancellations or adjustments and take proactive measures to recover any potential lost revenue.      ',
    },
    {
      question:
        'Can the team help recover revenue from unclear refund requests?    ',
      answer: `Yes, if a customer requests a refund with unclear or confusing reasons, the team's agents will contact the delivery provider directly to understand the situation better and explore opportunities for revenue recovery. They will then notify you, the owner, of the results.`,
    },
    {
      question:
        'How long does it typically take for the GRUBCON Concierge Team to recover lost revenue?    ',
      answer:
        'The timeframe for revenue recovery may vary depending on the specific situation. However, the team strives to take immediate action and resolve issues as quickly as possible to minimize any potential financial losses.    ',
    },
    {
      question: `Is there a cost associated with using the GRUBCON Concierge Team's services?    `,
      answer: 'Yes, it is $99      ',
    },
  ];

  return (
    <PageWrapper>
      <AnimatedDiv>
        <H4>FAQ</H4>
      </AnimatedDiv>
      <AnimatedDiv>
        <H1>
          <span>Frequently </span> Asked Questions
        </H1>
      </AnimatedDiv>
      <Div $mb='7rem'>
        <AnimatedDiv>
          {faqData.map((q, i) => (
            <FaqQuestion
              key={i}
              question={q.question}
              index={i}
              expanded={expanded}
              setExpanded={setExpanded}
            >
              <P $m='0'>{q.answer}</P>
            </FaqQuestion>
          ))}
        </AnimatedDiv>
      </Div>
      <Separator />
    </PageWrapper>
  );
};

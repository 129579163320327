import { hero } from 'assets';
import { AnimatedDiv } from 'components';
import { GetStartedButton } from 'components/getStartedButton/getStartedButton';
import { Separator } from 'components/separator/separator';
import { Div, Img, WrapperCenterSimplerHome } from 'styles';
import { H1, H4, P } from 'styles/text';

export const Section1 = () => {
  return (
    <>
      <WrapperCenterSimplerHome>
        <Div $width={'40%'} $widthMD={'45%'} $widthSM={'100%'}>
          <Div $flex $column>
            <AnimatedDiv>
              <H4>welcome to</H4>
            </AnimatedDiv>
            <AnimatedDiv delay={0.1}>
              <H1>
                <span>
                  Restaurant <br /> management{' '}
                </span>{' '}
                <br /> made convenient
              </H1>
            </AnimatedDiv>
            <AnimatedDiv delay={0.2}>
              <P $mb='35'>
                Wishing you didn’t have to waste time on delivery apps, menu
                item pauses, disputes, cancellations, customer complaints, and
                other headaches? Now you don’t have to. Sign up, sit back,
                relax, and GRUBCON will do it for you.
              </P>
            </AnimatedDiv>
            <AnimatedDiv delay={0.3}>
              <GetStartedButton />
            </AnimatedDiv>
          </Div>
        </Div>
        <Div $width={'60%'} $widthMD={'55%'} $widthSM={'100%'} $flex $center>
          <AnimatedDiv
            delay={0.2}
            $width='100%'
            $flex
            $centerSM
            $justifyEnd
            $alignEnd
          >
            <Img
              src={hero}
              alt=''
              $maxWidth={'100%'}
              $widthSM={'100%'}
              $maxHeight={'70vh'}
            />
          </AnimatedDiv>
        </Div>
      </WrapperCenterSimplerHome>
      <Separator borderLeft borderRight />
    </>
  );
};

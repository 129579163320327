import { ActiveAgentIcon, ChevronDown, ChevronLeft, InactiveAgentIcon, LocationIcon, PercentageIcon, UserListIcon } from "assets";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { existsEvenZero } from "helpers";
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll";
import { PercentageModal } from "pagesPartner/Restaurants/percentageModal";
import { HomeSection } from "pagesSales/Home/home.styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAgents } from "store/agents";
import { Button, ContentWrapper, Disclaimer, Div, H3, H4, Overlay, overlayAnimationVariant, P } from "styles";
import { AgentsListContainer, AgentsListItem, ChevronCount } from "./agents.styled";

export const AgentDetail = () => {
    const {
        loading,
        singleAgent,
        getSingleAgent,
        activateAgent,
        deactivateAgent,
        clearSingleAgent
    } = useAgents((state) => state);
    const { id } = useParams();
    const [openLocations, setOpenLocations] = useState(false)
    const [openAgents, setOpenAgents] = useState(false)
    const [percentageModal, setPercentageModal] = useState(false)
    const navigate = useNavigate()

    useDisableBodyScroll(percentageModal)

    useEffect(() => {
        getSingleAgent(id);

        return (() => clearSingleAgent())
    }, []);

    const handleAgent = (id: number) => {
        navigate('/agent-details/' + id)
    }

    const handleRestaurants = (id: number) => {
        navigate('/restaurants/' + id)
    }

    return (
        <ContentWrapper>
            <SpinnerFullscreen loading={loading} />
            <Div $width="100%" $flex $alignCenter $between>
                <H3 $mb="0" color="primary">
                    {singleAgent?.firstName} {singleAgent?.lastName}
                </H3>
                {singleAgent?.deactivated ? (
                    <Button $round $w="24rem" onClick={() => activateAgent(id)}>
                        ACTIVATE AGENT
                    </Button>
                ) : (
                    <Button
                        $round
                        $secondary
                        $w="24rem"
                        onClick={() => deactivateAgent(id)}
                    >
                        DEACTIVATE AGENT
                    </Button>
                )}
            </Div>
            <Div $flex $mt="1rem" $gap="2rem" $alignCenter>
                {singleAgent?.deactivated ? <InactiveAgentIcon /> : <ActiveAgentIcon />}
                {singleAgent?.deactivated ? (
                    <P $mb="0" >Inactive</P>
                ) : (
                    <P $mb="0"  >Active</P>
                )}
            </Div>
            <Div $alignCenter $mt="1rem" $flex $gap="2rem" >
                <PercentageIcon />
                {existsEvenZero(singleAgent?.referralPercentage) ?
                    <P $mb="0" >Deal Percentage: {singleAgent?.referralPercentage}%</P> :
                    <H4 onClick={() => setPercentageModal(true)} $mb="0" $pointer $noTranform $textSpacing="0" $bold color="primary" >Add Deal Percentage</H4>
                }

            </Div>
            <Div $mt="3rem" $flex $column $gap="2rem">
                <AgentsListContainer $active={openLocations} >
                    <HomeSection $bigSvg $svgGray={singleAgent?.deactivated} onClick={() => setOpenLocations(prev => !prev)} >
                        <Div $flex $alignCenter $gap="2rem">
                            <LocationIcon />
                            <Div>
                                <H4 $mb="0" $bold $noTranform $textSpacing="0">
                                    Signup Locations
                                </H4>
                            </Div>
                        </Div>
                        <H3 $mb="0" color="primary">
                            <ChevronCount $active={openLocations} >
                                <H3 $mb="0" color="primary">
                                    {singleAgent?.invitedLocationsCount}
                                </H3>
                                <ChevronDown />
                            </ChevronCount>
                        </H3>
                    </HomeSection>
                    {openLocations && (singleAgent?.locations.length ? singleAgent?.locations.map(l => <AgentsListItem onClick={() => handleRestaurants(l.id)} >
                        <H4 $mb="0" $textSpacing="0" $bold $noTranform >{l.name}</H4>
                        <Disclaimer $mb="0" >{l.address}</Disclaimer>
                    </AgentsListItem>) : <Div $flex $center $mt="2rem" ><Disclaimer>There are no locations to be displayed.</Disclaimer></Div>)}
                </AgentsListContainer>
                <AgentsListContainer $active={openAgents} >
                    <HomeSection $bigSvg $svgGray={singleAgent?.deactivated} onClick={() => setOpenAgents(prev => !prev)}  >
                        <Div $flex $alignCenter $gap="2rem">
                            <UserListIcon />
                            <Div>
                                <H4 $mb="0" $bold $noTranform $textSpacing="0">
                                    Agent List
                                </H4>
                            </Div>
                        </Div>
                        <ChevronCount $active={openAgents} >
                            <H3 $mb="0" color="primary">
                                {singleAgent?.invitedAgentsCount}
                            </H3>
                            <ChevronDown />
                        </ChevronCount>
                    </HomeSection>
                    {openAgents && (singleAgent?.agents.length ? singleAgent?.agents.map(a => <AgentsListItem onClick={() => handleAgent(a.id)} >
                        <H4 $mb="0" $textSpacing="0" $bold $noTranform >{a.name}</H4>
                        <Disclaimer $mb="0" >Signups: {a.signedLocationsCount}</Disclaimer>
                    </AgentsListItem>) : <Div $flex $center $mt="2rem" ><Disclaimer>There are no agents to be displayed.</Disclaimer></Div>)}
                </AgentsListContainer>
            </Div>
            {percentageModal && <>
                <PercentageModal agentId={singleAgent?.id} percentage={singleAgent?.referralPercentage} handleClose={() => setPercentageModal(false)} />
                <Overlay
                    key="modalOverlay"
                    variants={overlayAnimationVariant}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                /></>}
        </ContentWrapper>
    );
};

// import Cookies from 'js-cookie';

const localStorageNode: string = 'grubcon-auth';
const localStoragePhoneNode: string = 'grubcon-cms-phone';
export const localStorageHasEmailNode: string = 'grubcon-hasEmail';

export const store = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const read = (key: string) => {
  return localStorage.getItem(key) as string;
};

export const remove = (key: string) => {
  return localStorage.removeItem(key);
};


export const getTokens = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens;
};

export const getAccessToken = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens?.accessToken;
};

export const getRefreshToken = () => {
  return JSON.parse(read(localStorageNode))?.state?.tokens?.refreshToken;
};

export const getUserFromLS = () => {
  return JSON.parse(read(localStorageNode))?.state?.user;
};

export const getPhoneFromLS = () => {
  return JSON.parse(read(localStoragePhoneNode));
};

export const getHasEmailFromLS = () => {
  try {
    return JSON.parse(read(localStorageHasEmailNode));
  } catch (error) {
    return false
  }
};

export const setNewAccessToken = (accessToken: string) => {
  let data = JSON.parse(read(localStorageNode));
  data.state.tokens.accessToken = accessToken;
  store(localStorageNode, JSON.stringify(data));
};

export const setLoginDataToLS = (data: any) => {
  store(localStorageNode, JSON.stringify(data));
};

export const setPhoneNumberLS = (data: any) => {
  store(localStoragePhoneNode, JSON.stringify(data));
};

export const setHasEmailLS = (data: any) => {
  store(localStorageHasEmailNode, JSON.stringify(data));
};

export const removeLoginDataFromLS = () => {
  localStorage.removeItem(localStorageNode);
  localStorage.removeItem(localStoragePhoneNode);
  localStorage.removeItem(localStorageHasEmailNode);
};

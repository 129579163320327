import api from "api";
import { handleError } from "helpers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface TermsState {
  loading: boolean;
  terms:{__html:string | TrustedHTML}
  privacyPolicy:{__html:string | TrustedHTML}
  getTerms:()=>void
  getPrivacyPolicy:()=>void
}

export const useTerms = create<TermsState>()(
  devtools(
    persist(
      (set) => ({
        loading:false,
        privacyPolicy:null,
        terms:null,
        getTerms:async()=>{
          try {
            set({ loading: true });
            const {data} = await api.get("v1/policies/termsAndConditions");
            set({loading: false, terms:{__html:data.data.content}});
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getPrivacyPolicy:async()=>{
          try {
            set({ loading: true });
            const {data} = await api.get("v1/policies/privacyPolicy");
            set({loading: false, privacyPolicy:{__html:data.data.content}});
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        }

      }),
      { name: "grubcon-terms" }
    )
  )
);

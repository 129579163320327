import {
    ContentWrapper,
    Div,
    H3,
    H5,
    P,
    PageDateContainer,
} from "styles";
import {

    RestaurantCardsGrid,

} from "pagesPartner/Restaurants/restaurants.styled";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { useRestaurants } from "store/restaurants";
import React, { useEffect, useRef, useState } from "react";
import { isSales } from "helpers";
import { Input } from "components/input/Input";
import Restaurant from "../assets/images/restaurant.png";
import { RestaurantCard } from "pagesPartner/Restaurants/restaurantCard";

export const AdminRestaurants = () => {
    const {
        restaurants,
        loading,
        getRestaurants,
        clearRestaurants,
        allAdminRestaurants,
        getAdminRestaurantsCount,
        loadedRestaurants,
    } = useRestaurants((state) => state);
    const [params, setParams] = useState({ page: 1, limit: 10, query: "" });
    const containerRef = useRef(null);

    useEffect(() => {
        getRestaurants(params, params.page === 1);
    }, [params]);

    useEffect(() => {
        getAdminRestaurantsCount();

        return () => {
            clearRestaurants();
        };
    }, []);

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - scrollTop - clientHeight < 100) {
            setParams((prev) => {
                const copy = structuredClone(prev);
                copy.page = copy.page + 1;
                return copy;
            });
        }
    };

    const throttleScroll = () => {
        let timeout: any;
        return () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    handleScroll();
                }, 800); // Adjust the throttle time as needed
            }
        };
    };

    const throttledScroll = throttleScroll();

    const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({ page: 1, limit: 10, query: e.target.value });
    };


    if (loadedRestaurants && !params.query && restaurants.length < 1) {
        return (
            <ContentWrapper>
                <SpinnerFullscreen loading={loading} />
                {loadedRestaurants && !params.query && (
                    <Div $flex $column $center $gap="3rem">
                        <img src={Restaurant} alt="" />
                        <H3 $mb="0" color="primary">
                            No Restaurants!
                        </H3>
                        <P $mb="0">You currently don’t have any restaurant for review.</P>
                    </Div>
                )}
            </ContentWrapper>
        );
    }


    return (
        <ContentWrapper ref={containerRef} onScroll={throttledScroll} $scroll>
            <SpinnerFullscreen loading={loading} />
            <PageDateContainer>
                <H5 $noWrap $mb="0">
                    All locations: {allAdminRestaurants || 0}
                </H5>
                <Input
                    $width="36rem"
                    $responsive
                    onChange={handleQuery}
                    $search
                    placeholder="Search Restaurants"
                />
            </PageDateContainer>
            <RestaurantCardsGrid>
                <div>
                    {restaurants.map((r, i) => i % 2 === 0 && <RestaurantCard data={r} />)}
                </div>
                <div>
                    {restaurants.map((r, i) => i % 2 !== 0 && <RestaurantCard data={r} />)}
                </div>
            </RestaurantCardsGrid>
        </ContentWrapper>
    );
};

export interface IRestaurant {
    id: number;
    name: string;
    address: string;
    verified: boolean;
    phoneNumber?: string;
    amount?: number;
    orders?: number;
    brands: {
        name: string
        id: number
    }[]
    last4Added?: boolean
    voidCheckAdded?: boolean

}

export interface ILocationsRecoveryResponse {
    todaysSales: number;
    todaysOrders: number;
    location: {
        address: string;
        brands: { id: number, name: string }[];
        id: number;
        name: string;
        phoneNumber: string;
        verified: boolean;
        zipCode: string;
    };
}

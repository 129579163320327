import { useDebounce } from "@uidotdev/usehooks"
import api from "api"
import { CirlceCheckIcon, LocationIcon } from "assets"
import { Input } from "components/input/Input"
import { AnimatePresence } from "framer-motion"
import { handleError } from "helpers"
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll"
import { DropdownButtonWrapper } from "pagesPartner/Dashboard/partnerDashboard.styled"
import { MiniCircle } from "pagesPartner/FindRestaurant/findRestaurants.styled"
import { OptionsWrapper } from "pagesPartner/Orders/orders.styled"
import { ILocationsRecoveryResponse, IRestaurant } from "pagesPartner/Restaurants/restaurants"
import React, { SetStateAction, useEffect, useRef, useState } from "react"
import { useDashboard } from "store/dashboard"
import { Button, Disclaimer, Div, H4, Overlay, overlayAnimationVariant, variants } from "styles"
import { DropDownButton } from "./dropDownButton"
import { RestaurantSearchBox, SingleRestaurantOption } from "./dropdownButton.styled"

export const RestaurantButton = ({ selectedRestaurants, setSelectedRestaurants, $width, $noIcon, $center }: IRestaurantButton) => {

    const ref = useRef(null)
    const [open, setOpen] = useState(false)
    const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
    const [localSelected, setLocalSelected] = useState(selectedRestaurants)
    const [query, setQuery] = useState('')
    const deboundecQuery = useDebounce<string>(query, 200)
    const { setLoading } = useDashboard(state => state)
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const containerRef = useRef(null)

    useDisableBodyScroll(open);

    const fetchData = async (params?: any, q?: boolean) => {
        try {
            setLoading(true)
            const respRest = await api.get("v1/locations/recovery", {
                params: { ...params, verified: true, query: deboundecQuery },
            });


            setRestaurants((prev: any) => {
                let add: any = []
                if (!q) {
                    add = [...prev]
                }
                return [...add, ...(respRest.data.data as ILocationsRecoveryResponse[]).map((r: any) => ({
                    name: r.location.name,
                    address: r.location.address,
                    verified: r.location.verified,
                    id: r.location.id,
                    brands: r.location.brands
                }))]
            }

            );
            setLoading(false)
        } catch (error) {
            handleError(error)
            setLoading(false)
        }

    }
    useEffect(() => {
        open && fetchData(params)
    }, [open, params])


    useEffect(() => {
        open && fetchData({ page: 1, limit: 10 }, true)
    }, [deboundecQuery])

    const handleSelect = (res: { id: number, brands: { name: string, id: number }[], name: string }) => {
        setLocalSelected((prev) => {
            let copy = [...prev]
            if (copy.some(r => r.id == res.id)) {
                copy = copy.filter(r => r.id !== res.id)
            } else {
                copy.push(res)
            }
            return copy
        })

    }


    const handleConfirm = () => {
        setSelectedRestaurants(localSelected)
        setRestaurants([])
        setQuery('')
        setOpen(false)
    }

    const handleReset = () => {
        setLocalSelected([])
        setSelectedRestaurants([])
        setRestaurants([])
        setQuery('')
        setOpen(false)
    }

    const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

    const getTitle = () => {
        if (localSelected.length > 1) {
            return localSelected.length + " restaurants"
        } else if (localSelected.length === 1) {
            const fetched = restaurants.find(r => r.id == localSelected[0].id)
            if (fetched) {
                return fetched.name
            }
            return selectedRestaurants[0].name
        }

        return $noIcon ? 'No Locations Selected' : 'All locations'
    }


    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - scrollTop - clientHeight < 100) {
            setParams(prev => {
                const copy = structuredClone(prev)
                copy.page = copy.page + 1
                return copy
            })
        }
    };

    const throttleScroll = () => {
        let timeout: any
        return () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    handleScroll();
                }, 800); // Adjust the throttle time as needed
            }
        };
    };

    const throttledScroll = throttleScroll();

    return (
        <AnimatePresence>
            <DropdownButtonWrapper ref={ref} onClick={() => setOpen(true)} >
                <DropDownButton title={getTitle()} width={$width ?? "35rem"} $noIcon={$noIcon} icon={<LocationIcon />} />
                {open &&
                    <RestaurantSearchBox onClick={e => e.stopPropagation()} key="DropdownMenu"
                        $center={$center}
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{
                            type: 'spring',
                            bounce: 0.4,
                            duration: 0.8,
                        }}
                    >

                        <Input $width='36rem' onChange={handleQuery} $search placeholder="Search Restaurants" />
                        <Div $mt="2rem" $flex $width="100%" $column $gap="1rem" $center>
                            <OptionsWrapper ref={containerRef} onScroll={throttledScroll} >
                                {restaurants.map(r => <SingleRestaurantOption onClick={() => handleSelect({ id: r.id, brands: r.brands, name: r.name })} >
                                    <Div $height="100%" $flex $column $between >
                                        <H4 $bold $noTranform $textSpacing="0" $mb="0" >{r.name}</H4>
                                        <Disclaimer $mb="0">{r.address}<br />
                                            {r.phoneNumber}</Disclaimer>
                                    </Div>
                                    {localSelected.some(re => re.id == r.id) ? <CirlceCheckIcon stroke="#C28831" /> : <MiniCircle />}
                                </SingleRestaurantOption>)}
                            </OptionsWrapper>


                            <Div $flex $width="36rem" $gap="2rem" $mt="2rem">
                                <Button $w="100%" $round onClick={handleReset} >RESET SELECTION</Button>
                                <Button $w="100%" $selected $round onClick={handleConfirm} >CONFIRM</Button>
                            </Div>
                        </Div>
                    </RestaurantSearchBox>
                }
            </DropdownButtonWrapper>
            {open && (
                <Overlay
                    key="modalOverlay"
                    variants={overlayAnimationVariant}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    onClick={() => setOpen(false)}
                />
            )}
        </AnimatePresence>
    )

}
interface IRestaurantButton {
    selectedRestaurants: { id: number, brands?: { id: number, name: string }[], name?: string }[]
    setSelectedRestaurants: React.Dispatch<SetStateAction<{ id: number, brands?: { id: number, name: string }[], name?: string, address?: string }[]>>
    $width?: string
    $noIcon?: boolean
    $center?: boolean
}
import { logo } from 'assets';
import { DropdownMenu } from 'components/dropdownMenu/dropdownMenu';
import { pathname, useOnClickOutside } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CLIENTS, ROOT } from 'routes';
import { Button, Div } from 'styles';
import { MenuIcon } from './menuIcon';
import { NavStyled, NavigationStyled } from './navigation.styled';

export const Navigation = () => {
  const [sticky, setSticky] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? setSticky(true) : setSticky(false);
  };

  const handleMenu = () => setDropdown((prevState) => !prevState);

  useEffect(() => {
    if (dropdown) {
      handleMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useOnClickOutside(dropdownRef, () => dropdown && setDropdown(false));

  return (
    <NavStyled
      $sticky={sticky}
      initial={{ opacity: 0, marginTop: -100 }}
      animate={{ opacity: 1, marginTop: 0 }}
      exit={{ opacity: 0, marginTop: -100 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
    >
      <nav>
        <NavigationStyled $sticky={sticky}>
          <Link to={ROOT}>
            <img src={logo} alt='Grub concierge' />
          </Link>
          <Div $flex $center $gap='30px' $gapSM='20px'>
            <Div $desktop>
              <Link to={CLIENTS}>
                <Button $w='25rem' $negative>
                  GET STARTED FOR FREE
                </Button>
              </Link>
            </Div>
            <Div $flex $center $relative ref={dropdownRef}>
              <MenuIcon isOpen={dropdown} onClick={handleMenu} />
              <DropdownMenu
                isOpen={dropdown}
                setIsOpen={setDropdown}
                location={pathname}
              />
            </Div>
          </Div>
        </NavigationStyled>
      </nav>
    </NavStyled>
  );
};

import { ChevronDown } from "assets"
import { DropdownButton } from "pagesPartner/Dashboard/partnerDashboard.styled"
import { Disclaimer } from "styles"

export const DropDownButton = ({ icon, title, width, ref, $noIcon }: IDropDownButton) => {

    return <DropdownButton width={width} tabIndex={1} ref={ref} >
        <div style={{ marginLeft: $noIcon ? '1rem' : 0 }} >
            {!$noIcon && <div>
                {icon}
            </div>}
            <Disclaimer $mb="0" >{title}</Disclaimer>
        </div>
        <ChevronDown />
    </DropdownButton>

}

interface IDropDownButton {
    icon: JSX.Element
    title: string
    width?: string
    ref?: any
    $noIcon?: boolean
}
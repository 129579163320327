import { DashboardStatusButton } from "pagesPartner/Dashboard/partnerDashboard.styled";
import { useEffect, useState } from "react";
import { Button, Div, H1, H4, P } from "styles";
import { QRBox } from "./locations.styled";
import QRCode from "react-qr-code";
import { useInvite } from "store/invite";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { SuccessToast } from "components/Toasts/Toasts";
import { Range } from "components/Range";

export const Invite = () => {
    const {
        loading,
        restaurantLink,
        agentLink,
        getAgentLink,
        getRestaurantLink,
    } = useInvite((state) => state);
    const searchParams = new URLSearchParams(window.location.search);
    const [type, setType] = useState(searchParams.get('type') === 'agents' ? "AGENTS" : "RESTAURANTS");
    const isRestaurant = type === "RESTAURANTS";
    const [value, setValue] = useState(isRestaurant ? 35 : 5)


    useEffect(() => {
        isRestaurant ? getRestaurantLink() : getAgentLink();
    }, [type]);

    const copyLink = () => {
        navigator.clipboard.writeText((isRestaurant ? restaurantLink : agentLink) + '&percentage=' + value);
        SuccessToast('Link copied to clipboard!')
    }

    useEffect(() => {
        setValue(isRestaurant ? 35 : 5)
    }, [type])

    return (
        <>
            <SpinnerFullscreen loading={loading} />
            <Div $flex $gap="2rem" $width="100%" $justifyEnd>
                <DashboardStatusButton
                    active={isRestaurant}
                    onClick={() => setType("RESTAURANTS")}
                >
                    <p>RESTAURANTS</p>
                </DashboardStatusButton>
                <DashboardStatusButton
                    active={!isRestaurant}
                    onClick={() => setType("AGENTS")}
                >
                    <p>AGENTS</p>
                </DashboardStatusButton>
            </Div>

            <Div $mt="5rem" $width="100%" $flex $column $alignCenter $gap="3rem">
                <QRBox>
                    <QRCode value={isRestaurant ? restaurantLink : agentLink} />
                </QRBox>
                <Div $column $flex $gap="2rem" $width="100%" $alignCenter  >
                    <H1 $mb="0" $color="primary" >{value} %</H1>
                    <Range max={type == 'RESTAURANTS' ? 35 : 10} value={value} defaultValue={type == 'AGENTS' ? 5 : 35} min={type == 'AGENTS' ? 0 : 20} onChange={(e: any) => setValue(Number(e.target.value))} />
                    <H4 $noTranform $textSpacing="0" $bold > {type == 'AGENTS' ? 'RETAIL PRICE' : 'DEAL PERCENTAGE'} </H4>
                </Div>
                <P $center $mb="0">
                    Scan QR code, copy link or share
                    <br /> link to invite a new {isRestaurant ? "restaurant" : "agent"}
                </P>
                <Div $flex $column $gap="2rem">
                    <Button $round $secondary $w="25rem" onClick={copyLink} >
                        COPY REFERRAL LINK
                    </Button>
                </Div>
            </Div>
        </>
    );
};

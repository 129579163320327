import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts';
import { SpinnerFullscreen } from 'components/spinner/spinner';
import { getHasEmailFromLS, getPhoneFromLS, store } from 'helpers/localstorage-helper';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import OtpInput from 'react18-input-otp';
import { CMS_DASHBOARD, CMS_RESTAURANTS } from 'routes/routeConstantsCMS';
import styled from 'styled-components';
import api from '../../api';
import { isAdmin, isPartner, isSales, setDocumentTitle } from '../../helpers';
import { useAuth } from '../../store/auth';
import {
  Button,
  Disclaimer,
  Div,
  H3,
  H5,
  P,
  SeparatorBorderCMS,
  theme,
} from '../../styles';
import { getFCMTokenAndUpdate } from 'helpers/axiosHelper';

const Form = styled.form`
  width: 100%;
`;

export const OtpAdmin = () => {
  const { updateAuthState, sendCodeToEmail } = useAuth((state) => state);
  const [code, setCode] = useState('');

  const onChange = (value: string) => {
    setCode(value);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const phoneNumber = getPhoneFromLS();
  const hasEmail = getHasEmailFromLS();
  const authLoading = useAuth(state => state.loading)
  const ref = useRef(null);
  const searchParams = new URLSearchParams(window.location.search);
  async function resendCode(isViaEmail: boolean) {

    if (isViaEmail) {
      sendCodeToEmail(isAdmin ? 'admin' : isPartner ? 'partner' : 'salesAgent', phoneNumber)
      return
    }

    try {
      let params: any = {
        phoneNumber
      }

      if (isAdmin) {
        params.role = 'admin'
      }
      setLoading(true);
      const { data } = await api.post(`/v1/auth/sign-in`,
        params
      );
      setLoading(false);
      SuccessToast(data?.message);
    } catch (error: any) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }
  async function signIn() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/auth/verify-sms`, {
        phoneNumber,
        code,
      });
      let navigateTo = CMS_DASHBOARD
      updateAuthState(data.data);
      if (data.data.incompleteLocation) {
        store('incompleteLocation', JSON.stringify(data.data.incompleteLocation))
      }
      if (isPartner) {
        navigateTo = !data.data.user.firstName ? '/user-info' : data.data.locationsAmount > 0 && !data.data.incompleteLocation ? '/dashboard' : '/standalone-find-restaurant'
        if (!data.data.user.firstName && searchParams.get('referralId')) {
          store('referralId', searchParams.get('referralId'))
        }

        if (!data.data.user.firstName && searchParams.get('referralPercentage')) {
          store('referralPercentage', searchParams.get('referralPercentage'))
        }


      } else if (isSales) {
        await getFCMTokenAndUpdate()
        navigateTo = !data?.data?.user?.lastName ? '/user-info' : '/home'
      }

      setLoading(false);
      navigate(navigateTo);
    } catch (error: any) {
      console.log(error)
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit() {
    if (code.length < 4) {
      ErrorToast('Enter 4 digit code');
    } else {
      signIn();
    }
  }

  useEffect(() => {
    if (code.length === 4) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  setDocumentTitle(isAdmin ? 'Grub Concierge Admin' : isPartner ? 'Grub Concierge Partner' : 'Grub Concierge Sales');

  return (
    <>
      <SpinnerFullscreen loading={loading || authLoading} />

      <H3 $mb='3.2rem'>
        <span>Confirm Phone Number</span>
      </H3>

      <P $mb='0'>
        We sent a 4-digit code to {phoneNumber} <br /> To validate your account
        insert received code below.
      </P>

      <SeparatorBorderCMS $m='3.2rem 0' />

      <Form>
        <Div $flex $center $column>
          <H5 $m='0 0 1.6rem 0'>Enter Code</H5>

          <OtpInput
            isInputNum
            shouldAutoFocus
            ref={ref}
            value={code}
            onChange={onChange}
            numInputs={4}
            inputStyle={{
              height: 45,
              width: 45,
              borderRadius: '50%',
              margin: '0 0.8rem',
              border: `1px solid ${theme.secondary}`,
            }}
            focusStyle={{
              outline: 'none',
              border: `1px solid ${theme.primary}`,
            }}
          />
        </Div>
        <SeparatorBorderCMS $m='3.2rem 0' />

        <Disclaimer $center $m='2.4rem 0 1.6rem 0'>
          Did not receive the code?
        </Disclaimer>
        <Button
          type='button'
          onClick={() => resendCode(false)}
          $w='100%'
          $selected
          $m={hasEmail && '0 0  1.6rem 0'}
        >
          Resend Code via SMS
        </Button>
        {hasEmail && (
          <Button type='button' onClick={() => resendCode(true)} $w='100%'>
            Resend Code via Email
          </Button>
        )}
      </Form>
    </>
  );
};

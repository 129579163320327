import api from "api";
import { handleError } from "helpers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface InviteState {
  loading: boolean;
    restaurantLink:string
    agentLink:string
    getRestaurantLink:()=>void
    getAgentLink:()=>void
}

export const useInvite = create<InviteState>()(
  devtools(
    persist(
      (set) => ({
        loading:false,
        restaurantLink:'',
        agentLink:'',
        getRestaurantLink:async() =>{
            try {
                set({ loading: true });
                const {data} = await api.get("v1/agents/invite-location");
                set({loading: false, restaurantLink:data.data});
              } catch (error) {
                handleError(error);
                set({ loading: false });
              }
        },
        getAgentLink:async()=>{
            try {
                set({ loading: true });
                const {data} = await api.get("v1/agents/invite-agent");
                set({loading: false, agentLink:data.data});
              } catch (error) {
                handleError(error);
                set({ loading: false });
              }
        }
      }),
      { name: "grubcon-invite" }
    )
  )
);

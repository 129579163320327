import api from "api"
import { CloseIcon } from "assets"
import { Input } from "components/input/Input"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { ErrorToast, SuccessToast } from "components/Toasts/Toasts"
import { AnimatePresence } from "framer-motion"
import { changeState, handleError } from "helpers"
import React, { useState } from "react"
import { useParams } from "react-router"
import { useBrands } from "store/brands"
import { useRestaurants } from "store/restaurants"
import { Button, Div, H3, P, SeparatorBorderCMS } from "styles"
import { ExplainModal } from "./explainModal"
import { ProvidersModalContainer } from "./restaurants.styled"

export const ProviderModal = ({ data, handleClose }: IProviderModal) => {

    const [loading, setLoading] = useState(false)
    const [localState, setLocalState] = useState(data ?? { provider: '', username: '', password: '', storeId: '', name: '' })
    const [explainModal, setExplainModal] = useState(false)
    const { providers, setProviders } = useRestaurants(state => state)
    const { brandProviders, setBrandProviders } = useBrands(state => state)
    const { id, brandId } = useParams()
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        changeState(setLocalState, name, value);
    };



    const handleConfirm = async () => {

        if (!localState.username || !localState.password) {
            ErrorToast('Username and Password must be set.')
            return
        }

        try {
            const forDispatch = { ...localState }
            delete forDispatch.name
            if (brandId) {
                await api.post('v1/brands/' + brandId + '/provider/credentials', { ...forDispatch })
            } else {
                await api.post('v1/locations/' + id + '/provider/credentials', { ...forDispatch })
            }
            SuccessToast('Credentials successfully set.')
            const copy = brandId ? [...brandProviders] : [...providers]
            const index = copy.findIndex(p => p.provider === localState.provider)
            copy[index].config = forDispatch
            brandId ? setBrandProviders(copy) : setProviders(copy)
            handleClose()

        } catch (error) {
            handleError(error)
            setLoading(false)

        }
    }


    return <AnimatePresence>
        <ProvidersModalContainer>
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    User Info
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <SeparatorBorderCMS />
            <Div $mt="2rem" $mb="2rem" $flex $column $gap="2rem" $width="100%">
                <Input
                    label="Username"
                    defaultValue={localState.username}
                    onChange={onChange}
                    name="username"
                    placeholder="Enter here"
                />
                <Input
                    label="Password"
                    defaultValue={localState.password}
                    onChange={onChange}
                    name="password"
                    placeholder="Enter here"
                />
                <Input
                    label="Store ID"
                    defaultValue={localState.storeId}
                    onChange={onChange}
                    name="storeId"
                    placeholder="Enter here"
                />
            </Div>
            <Button $mt="2rem" $round $selected $w="100% " onClick={handleConfirm}>
                CONFIRM
            </Button>

            <P $mb="0" $mt="2rem" >Click <span style={{ cursor: 'pointer' }} onClick={() => setExplainModal(true)} > here</span> on how to create another user in the {localState.name} portal</P>

        </ProvidersModalContainer>
        {explainModal && <ExplainModal closeModal={() => setExplainModal(false)} type={data.name} />}
    </AnimatePresence>
}

interface IProviderModal {
    data: {
        username: string
        provider: string
        password: string
        storeId: string
        name: 'DoorDash' | 'UberEats' | 'GrubHub'
    } | null
    handleClose: () => void
}

import { LoginAdmin } from '../pagesCMS/Login/login.cms';
import {  DashboardIcon, LocationIcon, QRCodeIcon, SettingsIcon, UserIcon } from 'assets';
import { OtpAdmin } from 'pagesCMS/Otp/otp.cms';
import { UserInfoSales } from 'pagesSales/Auth/userInfoSales';
import { AccountCreated } from 'pagesSales/Auth/accountCreated';
import { SalesHome } from 'pagesSales/Home/home';
import { VerifyAccount } from 'pagesSales/Home/verifyAccount';
import { UnderReview } from 'pagesSales/Home/underReview';
import { Agents } from 'pagesSales/Agents/agents';
import { Settings } from 'pagesPartner/Settings/settings';
import { AgentDetail } from 'pagesSales/Agents/agentDetails';
import { Invite } from 'pagesSales/Locations/invite';
import { Providers } from 'pagesPartner/Restaurants/providers';
import { ConnectProvider } from 'pagesPartner/Restaurants/connectProvider';
import { DisconnectProvider } from 'pagesPartner/Restaurants/disconnectProvider';
import { Restaurants } from 'pagesPartner/Restaurants/restaurants';
import { ConnectBank } from 'pagesSales/Home/connectBak';
import { PrivacyPolicy } from 'pagesPartner/TermsAndConditions/privacyPolicy';
import { TermsAndConditions } from 'pagesPartner/TermsAndConditions/termsAndConditions';
import { RestaurantOrders } from 'pagesPartner/Restaurants/restaurantOrders';
import { ViewBrand } from 'pagesPartner/Restaurants/viewBrand';
import { ViewRestaurantSales } from 'pagesPartner/Restaurants/viewRestaurantSales';

export const SALES_ROOT = '/';
export const SALES_LOGIN = `${SALES_ROOT}login`;
export const SALES_OTP = `${SALES_ROOT}otp`;
export const SALES_USER_INFO= `${SALES_ROOT}user-info`;
export const SALES_ACC_CREATED= `${SALES_ROOT}account-created`;
export const SALES_HOME = `${SALES_ROOT}home`;
export const SALES_CONNECT_BANK = `${SALES_ROOT}connect-bank`;
export const SALES_VERIFY_ACCOUNT = `${SALES_ROOT}verify-account`;
export const SALES_UNDER_REVIEW = `${SALES_ROOT}portal-under-review`;
export const SALES_SETTINGS = `${SALES_ROOT}settings`;
export const SALES_LOCATIONS = `${SALES_ROOT}locations`;
export const SALES_MY_AGENTS = `${SALES_ROOT}agents`;
export const SALES_AGENT_DETAILS = `${SALES_ROOT}agent-details/:id`;
export const SALES_INVITE = `${SALES_ROOT}invite`;
export const SALES_RESTAURANTS = `${SALES_ROOT}restaurants`;
export const SALES_ADD_RESTAURANT = `${SALES_ROOT}restaurants/add/:id`;
export const SALES_VIEW_RESTAURANT = `${SALES_ROOT}restaurants/:id`;
export const SALES_RESTAURANTS_ORDERS = `${SALES_ROOT}restaurants/:id/orders`;
export const SALES_PROVIDERS = `${SALES_ROOT}restaurants/:id/providers`;
export const SALES_CONNECT_PROVIDER = `${SALES_ROOT}restaurants/:id/providers/connect/:providerName`;
export const SALES_DISCONNECT_PROVIDER = `${SALES_ROOT}restaurants/:id/providers/disconnect/:providerName`;
export const SALES_VIEW_BRAND = `${SALES_ROOT}restaurants/:id/brand/:brandId`;
export const SALES_BRAND_PROVIDERS = `${SALES_ROOT}restaurants/:id/brand/:brandId/providers`;
export const SALES_BRAND_CONNECT_PROVIDER = `${SALES_ROOT}restaurants/:id/brand/:brandId/providers/connect/:providerName`;
export const SALES_BRAND_DISCONNECT_PROVIDER = `${SALES_ROOT}restaurants/:id/brand/:brandId/providers/disconnect/:providerName`;
export const SALES_PRIVACY_POLICY = `${SALES_ROOT}portal-privacy-policy`;
export const SALES_TERMS = `${SALES_ROOT}portal-terms-and-conditions`;

export const authRoutesSales = [
  {
    path: SALES_LOGIN,
    Page: LoginAdmin,
  },
  {
    path: SALES_OTP,
    Page: OtpAdmin,
  },
  {
    path: SALES_USER_INFO,
    Page: UserInfoSales, 
  }, 
  {
    path: SALES_ACC_CREATED,
    Page: AccountCreated,
  }, 
];

export const routesSales = [
  {
    path: SALES_HOME,
    Page: SalesHome,
    name: 'Home',
    permissions: ['salesAgent'],
    Icon: DashboardIcon,
  },
  {
    path: SALES_CONNECT_BANK,
    Page: ConnectBank,
    name: '',
    permissions: ['salesAgent'],
    Icon: DashboardIcon,
  },
  {
    path: SALES_VERIFY_ACCOUNT,
    Page: VerifyAccount,
    name: '',
    subName:'Verify Account',
    permissions: ['salesAgent'],
    Icon: DashboardIcon,
  },
  {
    path: SALES_UNDER_REVIEW,
    Page: UnderReview,
    name: '',
    permissions: ['salesAgent'],
    Icon: DashboardIcon,
  },
  {
    path: SALES_RESTAURANTS,
    Page: Restaurants,
    name: 'Restaurants',
    permissions: ['salesAgent'],
    Icon: LocationIcon,
  },
  {
    path: SALES_VIEW_RESTAURANT,
    Page: ViewRestaurantSales,
    permissions: ['salesAgent'],
    name:'',
    subName:'View Restaurant',
    Icon:LocationIcon,
    backLink:'restaurants'
  },
  {
    path: SALES_RESTAURANTS_ORDERS,
    Page: RestaurantOrders,
    permissions: ['salesAgent'],
    name:'',
    subName:'Orders',
    Icon:LocationIcon,
  },
  {
    path: SALES_PROVIDERS,
    Page: Providers,
    permissions: ['salesAgent'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
    backLink:'restaurants/'

  },
  {
    path: SALES_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['salesAgent'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: SALES_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['salesAgent'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: SALES_INVITE,
    Page: Invite,
    name: 'Invite',
    permissions: ['salesAgent'],
    Icon: QRCodeIcon,
  },
  {
    path: SALES_MY_AGENTS,
    Page: Agents,
    name: 'My Agents',
    permissions: ['salesAgent'],
    Icon: UserIcon,
  },
  {
    path: SALES_AGENT_DETAILS,
    Page: AgentDetail,
    name: '',
    subName:'Agent Details',
    permissions: ['salesAgent'],
    Icon: UserIcon,
  },
  {
    path: SALES_SETTINGS,
    Page: Settings,
    name: 'Settings',
    permissions: ['salesAgent'],
    Icon: SettingsIcon,
  },  
  {
    path: SALES_PRIVACY_POLICY,
    Page: PrivacyPolicy,
    name: '',
    permissions: ['salesAgent'],
    Icon: SettingsIcon,
  },
  {
    path: SALES_TERMS,
    Page: TermsAndConditions,
    name: '',
    permissions: ['salesAgent'],
    Icon: SettingsIcon,
  },
  {
    path: SALES_VIEW_BRAND,
    Page: ViewBrand,
    permissions: ['salesAgent'],
    name:'',
    subName:'Brand Details',
    Icon:LocationIcon,
  },
  {
    path: SALES_BRAND_PROVIDERS,
    Page: Providers,
    permissions: ['salesAgent'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
  },
  {
    path: SALES_BRAND_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['salesAgent'],
    name:'',
    subName:'Connect Provider',
    Icon:LocationIcon,
  },
  {
    path: SALES_BRAND_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['salesAgent'],
    name:'',
    subName:'Disonnect Provider',
    Icon:LocationIcon,
  },
];


import { ChevronDown, RecoveryIcon } from "assets"
import { Div, H4, P } from "styles"
import { DateOrdersContainer, LogoContainer, OrdersSectionHead, SingleDateOrder, StatusLabel } from "./orders.styled"
import GrubHubLogo from '../../assets/images//logos/GrubHubLogo.png'
import UberEatsLogo from '../../assets/images//logos/UberEatsLogo.png'
import DoorDashLogo from '../../assets/images//logos/DoorDashLogo.png'
import { useState } from "react"
import { capitalize, formatNumber } from "helpers"

export const DateOrders = ({ o, defaultOpen }: { o: any, defaultOpen: boolean }) => {
    const [active, setActive] = useState(defaultOpen)

    const getColor = (status: string) => {
        switch (status) {
            case 'recovered':
                return 'green'
            case 'pending':
                return 'primary'
            case 'adjustment':
                return 'orange'
            case 'notEligible':
                return 'red'
            case 'evidenceNeeded':
                return 'blue'
        }
    }

    const getLogo = (provider: string) => {
        switch (provider) {
            case 'ubereats':
                return UberEatsLogo
            case 'doordash':
                return DoorDashLogo
            case 'grubhub':
                return GrubHubLogo
            case 'grubhubPos':
                return GrubHubLogo
        }
    }

    const getStatusName = (status: string) => {
        switch (status) {
            case 'recovered':
                return 'RECOVERED'
            case 'pending':
                return 'PENDING'
            case 'adjustment':
                return 'ADJUSTMENT'
            case 'notEligible':
                return 'NOT ELIGIBLE'
            case 'evidenceNeeded':
                return 'EVIDENCE NEEDED'
        }
    }

    return <DateOrdersContainer $active={active} >
        <OrdersSectionHead $noBorder $active={active} onClick={() => setActive(!active)}>
            <H4 $mb="0" color={active ? 'white' : 'secondary'} $noTranform $bold $textSpacing="0" >{o.date}</H4>
            <ChevronDown fill="#fff" stroke="#fff" />
        </OrdersSectionHead>
        {active && <Div $mt="2rem" $flex $column $gap="1rem" >
            {o.objects.map((o: any) => <SingleDateOrder $red={o.status === 'notEligible'} >
                <Div $flex $height="100%" $gap="2rem" >
                    <LogoContainer $border={o.status !== 'notEligible'}  >
                        <img src={getLogo(o.provider)} alt='' />
                    </LogoContainer>
                    <Div $flex $column $between $height="100%" >
                        <H4 $lineHeight="0" $mb="0" $noTranform $textSpacing="0" $bold color="primary" >{o.customerName}</H4>
                        <H4 className="smallest" $lineHeight="0" $mb="1rem" $noTranform $textSpacing="0" $bold color="darkRed" >$ {o.total}</H4>
                        <Div >
                            <P $mb="0" >Order ID: {o.id}</P>
                            <P $mb="0" >Number: {o.number}</P>
                            <P $mb="0" >{capitalize(o.type)}</P>
                        </Div>
                        <StatusLabel $mobile $mb="0" $color={getColor(o.status)} >
                            <RecoveryIcon /> ${formatNumber(o.amount)} {getStatusName(o.status)}
                        </StatusLabel>
                    </Div>

                </Div>
                <Div $flex $column $between $height="100%" >
                    <Div>
                        <H4 className="normal" $right $lineHeight="0" $mb="1rem" $noTranform $textSpacing="0" $bold color="darkRed" >$ {o.total}</H4>
                    </Div>
                    <StatusLabel $desktop $mb="0" $color={getColor(o.status)} >
                        <RecoveryIcon />${formatNumber(o.amount)} {getStatusName(o.status)}
                    </StatusLabel>
                </Div>
            </SingleDateOrder>)}
        </Div>}
    </DateOrdersContainer>
}

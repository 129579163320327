import { isAdmin } from 'helpers';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
      font-size: 62.5%; /* 16px * 0.625 = 10px */
    }

    @media (min-width: 3500px) {
        html {
          font-size: 125%; 
        }
    }

  @media (max-width: 1450px) {
    html {
          font-size: 57%; 
        }
  }

  @media (max-width: 800px) {
    html {
          font-size: 47%; 
        }
  }

  @media (max-width: 500px) {
    html {
          font-size: 43%; 
        }
  }

  /* ${
    isAdmin &&
    `
    body, html {
      overflow: hidden
    }
  `
  } */

 
  

  body {
    background-color: ${({ theme }) => theme.white} !important;
  }


  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::selection {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.primary}b2;
  }


  a:hover {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }

  a:active {
    color: ${({ theme }) => theme.secondary};
    text-decoration: none;
  }

  a:visited {
    color:  ${({ theme }) => theme.secondary};
    text-decoration: none;
  }

  a:link {
    color:  ${({ theme }) => theme.secondary};
    text-decoration: none;
  }

 `;

import { notFoundImage } from 'assets';
import { AnimatedDiv, Separator } from 'components';
import { Link } from 'react-router-dom';
import { ROOT } from 'routes';
import { Button, Div, H1, H4, Img, P, PageWrapper } from 'styles';

export const NotFound404 = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $columnSM $alignCenter>
        <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>404 error</H4>
            <H1>
              <span>Page </span>
              not
              <br />
              found!
            </H1>
            <Div $maxWidth='50rem'>
              <P>
                The page you are looking for does not exist or link is broken.
                Return to the home page.
              </P>
            </Div>
            <Link to={ROOT}>
              <Button>HOME</Button>
            </Link>
          </AnimatedDiv>
        </Div>
        <Div $desktop $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <Img src={notFoundImage} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
      </Div>
      <Separator />
    </PageWrapper>
  );
};

import styled from 'styled-components';


export const DashboardWrapper = styled.div`

  display: flex;
  gap: 2.5rem;
  margin-top: 2.5rem;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

`

export const DropdownButtonWrapper = styled.div`

  position: relative;

  @media (max-width: 800px) {
    width: 100%;
  }


`

export const DropdownButton = styled.div<{width?:string}>`
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
width:${({width})=>width || '20rem'};
height: 4rem;
padding: .5rem;

@media (max-width: 800px) {
    width: 100%;
  }

&>div{
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;

  &>div{
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme})=>theme.primary};
    border-radius: 2rem;

    svg{
      width: 17px;
        height: 17px;
      path{
        stroke: #fff;
      }
    }
  }
}

>svg{
  margin-right: 1rem;
}

border-radius:50px;
box-shadow: ${({ theme }) => theme.shadows.shadow1};
border: 1px solid ${({ theme }) => theme.secondary}33;
background-color: ${({ theme }) => theme.white};

`


export const DashboardStatusButton = styled.data<{active?:boolean}>`

height: 4.8rem;
width: 17rem;
min-width: 13rem;
display: flex;
align-items: center;
justify-content: center;
border-radius:50px;
box-shadow: ${({ theme }) => theme.shadows.shadow1};
border: 1px solid ${({ theme }) => theme.secondary}33;
background-color: ${({ theme }) => theme.white};  
cursor: pointer;
@media (max-width: 600px) {
    box-shadow: unset;
  }

p{
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  color:${({theme,active})=> active ?  theme.primary : theme.secondary};
  font-weight:${({active})=> active ?  'bold' : 'normal'};
    transition: .2s ease-out;
}

`



export const DashboardMainContent = styled.div<{width:string}>`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    width: ${({width})=>width};
    height: 55rem;
    padding:3rem;

    @media (max-width: 800px) {
    width: 100%;
    height: min-content;
  }
`
export const LogoCircle  =styled.div`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: 20rem;
    min-width: 6.4rem;
    max-width: 6.4rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

export const LogoCircleBig  =styled(LogoCircle)`
    min-width: 12rem;
    width: 12rem;
    min-height: 12rem;
    height: 12rem;



`


export const AreaTooltip = styled.div`
width:max-content;
height:max-content;
padding:1rem;
background-color: white;
border-radius: 10px;
position: relative;
font-size: .8rem;

span{
  font-size: 1rem;
}

.time{
  position: absolute;
  bottom:.5rem;
  right:.5rem;
  p{
    font-size: 1.1rem;
  }
}
`


export const StatisticsCardsContainer = styled.div`

  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: calc(100% - 8rem);

  h2{
      font-size: 3rem !important;
      text-transform: none;
        letter-spacing: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
  @media (max-width: 800px) {
    flex-direction: row;
    height: min-content;

    h2{
      font-size: 2rem !important;
    }
  }

`

export const StatisticsCard = styled.div`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    width: 100%;
    height: 100%;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    gap: 5px;
    height: 100%;
    *{
      margin:0;
      text-align:center;
    }
  }


  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  @media (max-width: 450px) {
    height: 18rem;
    padding: 0;
  }

`

export const StatisticsIconCircle=styled.div<{green?:boolean}>`

    min-width: 6.4rem;
    max-width: 6.4rem;
    min-height: 6.4rem;
    max-height: 6.4rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme,green})=>green ? theme.green : theme.primary};
`
export const GraphTopWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
*{
  margin:0 !important
}

@media (max-width: 800px) {
  gap: 2rem;

  div{
    flex-direction: column;
    align-items: flex-start;
  }



  :nth-child(3){
    flex-direction: column-reverse !important;
    align-items: flex-end;

  }

  }

`

export const GraphWrapper = styled.div`

  height: calc(100% - 4rem);
  @media (max-width: 800px) {
   height: 55rem;
  }

  @media (max-width: 500px) {
   height: 30rem;
  }
`


export const PDSelectsWrapper = styled.div`

display: flex;
gap: 1rem;

@media (max-width: 800px) {
  width: 100%;
   flex-direction: column;
  }

`

export const PDDatesWrapper = styled.div`

display: flex;
gap: 1rem;
@media (max-width: 800px) {   
  width: 100%;
  justify-content: space-between;
  align-items: center;
  }



`



export const StatusButtonsWrapper = styled.div<{$mobile?:boolean, $desktop?:boolean}>`

/* $flex $mt="2.4rem" $gap="1rem" $alignCenter */
width: 100%;
display: flex;
gap: 1rem;
align-items: center;
padding: 2rem;

@media (max-width: 800px) {
  overflow: auto;
  }

  @media (max-width: 600px) {
  padding: 2rem 0;
  }

  ${({ $desktop }) =>
    $desktop &&
    `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ $mobile }) =>
    $mobile &&
    `
    @media (min-width: 800px) {
    display: none;
  }
`}
`

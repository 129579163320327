import { section4Image1, section4Image2 } from 'assets';
import { CheckIcon, ChevronLeft, ChevronRight } from 'assets/svg';
import { AnimatedDiv, SliderAnimation } from 'components';
import { GetStartedButton } from 'components/getStartedButton/getStartedButton';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import {
  Div,
  Icon,
  Img,
  P,
  SliderPaginationCircle,
  SliderPlaceholder,
  WrapperCenterSimpler,
} from 'styles';
import { H2, H3, H4 } from 'styles/text';

const checks = [
  'Offer support 24/7 to address any concerns or provide assistance to restaurant owners.',
  'Handle menu changes, descriptions, pauses and any editing requests.',
  'Handle customer adjustments and ensure accurate order modifications.',

  `Manage your restaurant's reviews and reply to dissatisfied customers to address complaints.`,
  'Be a buffer between you and customers by handling complaints to ensure the most positive experience possible. ',
  'Do just about anything with white-glove service to accommodate your special requests.',
];

export const Section4 = () => {
  const first = [0, 1, 2];
  const second = [3, 4, 5];
  const [slider, setSlider] = useState(first);

  const handleSliderChange = () => {
    setSlider((prev) => (prev.includes(first[0]) ? second : first));
  };

  const SliderNavigation = () => (
    <Div $flex $alignCenter $gap='3rem' $centerSM $widthSM='100%'>
      <Icon>
        <ChevronLeft
          disabled={slider.includes(0)}
          onClick={() => !slider.includes(0) && handleSliderChange()}
        />
      </Icon>
      <SliderPaginationCircle
        $selected={slider.includes(0)}
        onClick={handleSliderChange}
      />
      <SliderPaginationCircle
        $selected={!slider.includes(0)}
        onClick={handleSliderChange}
      />
      <Icon>
        <ChevronRight
          disabled={!slider.includes(0)}
          onClick={() => slider.includes(0) && handleSliderChange()}
        />
      </Icon>
    </Div>
  );

  return (
    <>
      <H4 $center>The Help Desk Team</H4>
      <H2 $center>
        <span>Manage everything</span> with just a text
      </H2>
      <WrapperCenterSimpler $gap='5rem' $gapSM='3rem'>
        <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H3>Here’s what we do</H3>
          </AnimatedDiv>
          <SliderPlaceholder>
            <span>
              {checks.map((text, i) => {
                if (second.includes(i)) {
                  return (
                    <div key={i}>
                      <Div
                        key={i}
                        $flex
                        $alignCenter
                        $gap='1.5rem'
                        $mb='2rem'
                        $mbSM='2rem'
                        $box
                      >
                        <Icon></Icon>
                        <P $mb='0'>{text}</P>
                      </Div>
                    </div>
                  );
                } else return null;
              })}
            </span>

            <div>
              <AnimatePresence mode='wait'>
                {checks.map((text, i) => {
                  if (slider.includes(i)) {
                    return (
                      <SliderAnimation key={i} delay={i / 15}>
                        <AnimatedDiv
                          key={i}
                          $flex
                          $alignCenter
                          $gap='1.5rem'
                          $mb='2rem'
                          $mbSM='2rem'
                          $box
                        >
                          <Icon>
                            <CheckIcon />
                          </Icon>
                          <P $mb='0'>{text}</P>
                        </AnimatedDiv>
                      </SliderAnimation>
                    );
                  } else return null;
                })}
              </AnimatePresence>
            </div>
          </SliderPlaceholder>

          <AnimatedDiv $flex $alignCenter $mt='5rem' $gap='5rem'>
            <Div $desktop>
              <GetStartedButton />
            </Div>
            <SliderNavigation />
          </AnimatedDiv>
        </Div>
        <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <AnimatePresence mode='wait'>
              {slider.includes(0) && (
                <SliderAnimation key='section4Image1'>
                  <Div $flexSM $centerSM>
                    <Img
                      src={section4Image1}
                      alt=''
                      $width={'100%'}
                      $widthSM={'100%'}
                    />
                  </Div>
                </SliderAnimation>
              )}

              {!slider.includes(0) && (
                <SliderAnimation key='section4Image2'>
                  <Div $flexSM $centerSM>
                    <Img
                      src={section4Image2}
                      alt=''
                      $width={'100%'}
                      $widthSM={'100%'}
                    />
                  </Div>
                </SliderAnimation>
              )}
            </AnimatePresence>
          </AnimatedDiv>
        </Div>
        <AnimatedDiv $mobile>
          <GetStartedButton />
        </AnimatedDiv>
      </WrapperCenterSimpler>
    </>
  );
};

export const logo = '/images/logo.svg';
export const hero = '/images/hero.png';
export const section2Image2 = '/images/section2Image2.png';
export const section4Image2 = '/images/section4Image2.png';
export { default as section2Image1 } from './images/section2Image1.png';
// export { default as section2Image2 } from './images/section2Image2.png';
export { default as section4Image1 } from './images/section4Image1.png';
// export { default as section4Image2 } from './images/section4Image2.png';
export { default as section5Image } from './images/section5Image.png';
export { default as homeBannerImage } from './images/homeBannerImage.png';
export { default as homeBannerImageMobile } from './images/homeBannerImageMobile.png';
export { default as contactImage } from './images/contactImage.png';
export { default as partnerImage } from './images/partnerImage.png';
export { default as clientImage } from './images/clientImage.png';
export { default as pricingComponentImage } from './images/pricingComponentImage.png';
export { default as notFoundImage } from './images/404Image.png';

export { default as bagNWich } from './images/brands/bagNWich.png';
export { default as wrap } from './images/brands/wrap.png';
export { default as bagelBos } from './images/brands/bagelBos.png';
export { default as nycBagels } from './images/brands/nycBagels.png';
export { default as omeletteStation } from './images/brands/omeletteStation.png';
export { default as phillyCheez } from './images/brands/phillyCheez.png';
export { default as saladSnack } from './images/brands/saladSnack.png';

export * from './svg';

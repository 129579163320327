import { P } from "styles"

export const UberEatsExplain = () => {
    return <>
        <P $mt="1rem">{`To create a new user on UberEats, please go to your Ubereats portal.  Click on Users > Add User > type in Grubcon Concierge with email uber@grubcon.com  as Manager > click Add`}<br /> <br /></P>
    </>
}

export const DoorDashExplain = () => {
    return <>
        <P $mt="1rem">{`To create a new user on DoorDash, please go to your DoorDash portal.  Click on Settings > Manage Users > +Add User > type in Grubcon Concierge with email storename@grubcon.com  > Admin or Manager > click Send Invite > It will ask for 2-step verification code which you will receive in your inbox `}<br /> <br /></P>
        <P>{`•⁠  ⁠Be sure to replace the storename to your store. Ex: if your store is called Bagel Buzz, then the email should be bagelbuzz@grubcon.com`}<br /> <br /></P>
    </>
}

export const GrubHubExplain = () => {
    return <>
        <P $mt="1rem" >{`To create a new user on Grubhub, please go to your Grubhub portal.  Click on Settings > Permissions > Create User > Admin > type in Grubcon Concierge with email storename@grubcon.com  > password: Grubcon888$$$ > click Save`}<br /> <br /></P>
        <P>{`•⁠  ⁠Be sure to replace the storename to your store. Ex: if your store is called Bagel Buzz, then the email should be bagelbuzz@grubcon.com`}<br /> <br /></P>
    </>
}
import { styled } from 'styled-components';

export const PricingComponentStyled = styled.div`
  margin-top: 6rem;
  border: 0.1rem solid ${({ theme }) => theme.secondary}33;
  box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  position: relative;
  img {
    width: 100%;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  > div {
    padding: 0 3rem 3rem 3rem;
    > div {
      border-bottom: 0.1rem solid ${({ theme }) => theme.secondary}33;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border: 0.1rem solid ${({ theme }) => theme.secondary}33;
    background: ${({ theme }) => theme.white};
    padding: 1.6rem;
    position: absolute;
    left: 4rem;
    top: 0%;
    transform: translate(0, -50%);
  }

  width: 41rem;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const PricingTextBig = styled.div`
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-right: 1.8rem;
  color: ${({ theme }) => theme.primary};
`;
export const PricingTextMedium = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 0.7rem;
  color: ${({ theme }) => theme.primary};
  span {
    color: ${({ theme }) => theme.secondary};
  }
`;
export const PricingTextSmall = styled.div`
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.secondary};
`;

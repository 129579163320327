import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NavStyled = styled(motion.div)<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.2s ease;
  padding: 0 100px;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    padding: 0 30px;
  }

  ${({ $sticky }) =>
    $sticky &&
    `
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  background: #ffffff;

  `}

  > nav {
    max-width: 144rem;

    width: 100vw;
  }
`;

export const NavigationStyled = styled.div<{ $sticky: boolean }>`
  gap: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
  transition: all 0.2s ease;

  img {
    transition: all 0.2s ease;
    height: 7rem;
  }

  @media (max-width: 800px) {
    padding: 1.5rem 0;
    img {
      height: 6rem;
    }
  }

  ${({ $sticky }) =>
    $sticky &&
    `
  padding: 1rem 0;
    img {
      height: 6rem;
      transition: all 0.2s ease;
    }
  `}
`;

export const MenuIconStyled = styled(motion.div)`
  @media (min-width: 3500px) {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalStyled = styled(motion.div)`
  padding: 3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.white};
  z-index: 100;
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  /* text-align: center; */
  width: 50rem;
  max-width: 90%;

  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;

  > svg {
    width: 13rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 800px) {
    max-width: calc(100% - 6rem);

    > svg {
      width: 10rem;
    }
  }
`;

export const ModalOverlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.white + theme.transparency.t50};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

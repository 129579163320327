import { Disclaimer, Div } from 'styles';
import {
  PaymentComponentImage,
  PricingComponentStyled,
  PricingTextBig,
  PricingTextMedium,
  PricingTextSmall,
} from './payment.styled';

export const PricingComponent = () => {
  return (
    <PricingComponentStyled>
      <PaymentComponentImage />
      <div>
        <Div $flex $alignCenter $p='2.8rem 0'>
          <div>
            <PricingTextMedium>
              UNLIMITED <span>PLAN</span>
            </PricingTextMedium>
            <PricingTextSmall>PER MONTH</PricingTextSmall>
          </div>
        </Div>
        <PricingTextBig>$99</PricingTextBig>
        <Div $p='1.6rem 0 0 0' $width='100%'>
          <Div $flex $alignCenter $gap='1.6rem' $width='100%'>
            <svg
              style={{ flex: 'none' }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z'
                stroke='#737373'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12 16L12 11'
                stroke='#737373'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12.0059 8L11.9969 8'
                stroke='#737373'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <Disclaimer $mb='0'>$0 for the first 15 days. </Disclaimer>
          </Div>

          <Div $flex $alignCenter $gap='1.6rem' $mt='0.8rem'>
            <svg
              style={{ flex: 'none' }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z'
                stroke='#E61E29'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12 8V13'
                stroke='#E61E29'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M11.9941 16H12.0031'
                stroke='#E61E29'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <Disclaimer $mb='0'>
              Results can cause a smile on your face.
            </Disclaimer>
          </Div>
        </Div>
      </div>
    </PricingComponentStyled>
  );
};

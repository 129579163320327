import { Link } from 'react-router-dom';
import { CLIENTS } from 'routes';
import { Button } from 'styles';

export const GetStartedButton = () => {
  return (
    <Link to={CLIENTS}>
      <Button $w='25rem'>GET STARTED FOR FREE</Button>
    </Link>
  );
};

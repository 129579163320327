import { AnimatedDiv } from 'components';
import { H1, H4, P, PageWrapper } from 'styles';

export const Privacy = () => {
  return (
    <PageWrapper>
      <AnimatedDiv>
        <H4>vgrubs</H4>
        <H1>
          <span>Privacy</span> Policy
        </H1>
        <P>
          Effective Date: June 18, 2023
          <br />
          <br />
          At GRUBCON, we are committed to protecting your privacy and
          safeguarding your personal information. This Privacy Policy outlines
          how we collect, use, disclose, and protect your personal information
          when you access and use our services. By using our services, you
          consent to the practices described in this Privacy Policy.
          <br />
          <br />
          Information We Collect
          <br />
          <br />
          1.1 Personal Information We may collect personal information that you
          provide to us voluntarily when you access or use our services. This
          may include:
          <br />
          <br />
          Contact information (such as name, email address, phone number, and
          postal address)
          <br />
          Account information (such as username and password)
          <br />
          Payment information (such as credit card details)
          <br />
          Communication preferences
          <br />
          Any other information you choose to provide to us
          <br />
          <br />
          1.2 Usage Information We may collect information about your use of our
          services, including: Log information (such as IP addresses, browser
          type, access .mes, and pages visited) Device information (such as
          device type, opera.ng system, and mobile network information) Usage
          patterns and preferences
          <br />
          <br />
          Cookies and similar technologies (for more information, please refer
          to our Cookie Policy) Use of Information
          <br />
          <br />
          2.1 Providing and Improving Services We use the collected information
          to provide and improve our services, including:
          <br />
          <br />
          Processing and fulfilling orders
          <br />
          Providing customer support
          <br />
          Communicating with you about your orders, inquiries, and service
          updates
          <br />
          Customizing and personalizing your experience with our services
          <br />
          Analyzing and improving the effectiveness of our services
          <br />
          Developing new features and offerings
          <br />
          2.2 Marketing and Communication With your consent, we may use your
          personal information to send you promotional materials, newsletters,
          or other communications regarding our services, special offers, or
          events. You can opt out of receiving marketing communications at any
          .me by following the instructions provided in the communication or by
          contacting us directly.
          <br />
          <br />
          2.3 Legal Compliance We may use and disclose your personal information
          as required by applicable laws, regulations, legal processes, or
          governmental requests. We may also use and disclose your information
          to protect our rights, privacy, safety, or property, and the rights,
          privacy, safety, or property of our users or others.
          <br />
          <br />
          Disclosure of Information
          <br />
          <br />
          3.1 Third-Party Service Providers We may share your personal
          information with trusted third-party service providers who assist us
          in opera.ng our business and providing our services. These service
          providers are contractually obligated to handle your information
          securely and only for the purposes outlined in this Privacy Policy.
          <br />
          <br />
          3.2 Business Transfers In the event of a merger, acquisition, or sale
          of all or a por.on of our assets, your personal information may be
          transferred or disclosed as part of the transaction. We will take
          appropriate measures to ensure the confidentiality of your information
          and no.fy you of any changes to the ownership or control of your
          personal information.
          <br />
          <br />
          3.3 Legal Requirements We may disclose your personal information if we
          believe it is necessary to comply with applicable laws, regulations,
          legal processes, or governmental requests.
          <br />
          <br />
          Data Security
          <br />
          <br />
          4.1 Security Measures We employ industry-standard security measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction.
          <br />
          <br />
          However, please note that no method of transmission over the Internet
          or electronic storage is completely secure. Therefore, we cannot
          guarantee absolute security of your information.
          <br />
          <br />
          4.2 Retention of Information We will retain your personal information
          for as long as necessary to fulfill the purposes outlined in this
          Privacy Policy, unless a longer retention period is required or
          permitted by law.
          <br />
          <br />
          Your Choices and Rights
          <br />
          <br />
          5.1 Access and Correction You may access and update your personal
          information by logging into your account or contacting us directly.
          <br />
          <br />
          5.2 Opt-Out You may opt out of receiving marketing communications from
          us by following the instructions provided in the communication or by
          contacting us directly.
          <br />
          <br />
          5.3 Cookies and Similar Technologies You can manage your cookie
          preferences and se`ngs through your browser or device se`ngs. Please
          refer to our Cookie Policy for more information.
          <br />
          <br />
          Children's Privacy
          <br />
          <br />
          Our services are not intended for individuals under the age of 16. We
          do not knowingly collect personal information from children without
          parental consent. If we become aware that we have collected personal
          information from a child without parental consent, we will take steps
          to delete the information as soon as possible.
          <br />
          <br />
          Compliance with Privacy Laws
          <br />
          <br />
          GRUBCON is committed to complying with applicable privacy laws,
          including but not limited to:
          <br />
          <br />
          General Data Protection Regulation (GDPR) - For more information,
          please visit: GDPR
          <br />
          California Consumer Privacy Act (CCPA) - For more information, please
          visit: CCPA
          <br />
          California Online Privacy Protection Act (CalOPPA) - For more
          information, please visit:
          <br />
          CalOPPA
          <br />
          Children’s Online Privacy Protection Act (COPPA) - For more
          information, please visit: COPPA Other relevant privacy laws around
          the world - Please consult the privacy laws applicable to your
          jurisdiction for more information.
          <br />
          <br />
          Third-Party Services
          <br />
          <br />
          If we utilize third-party services that involve monitoring your
          website traffic, displaying ads, utilizing affiliate links, or
          accepting payments, we are committed to adhering to the privacy
          requirements of these services. This includes but is not limited to:
          <br />
          Monitoring website traffic in compliance with the terms and conditions
          of services such as Google Analytics
          <br />
          <br />
          Displaying ads in accordance with the guidelines and policies of
          platforms like the Apple App Store and Google Play Store
          <br />
          <br />
          Utilizing affiliate links in compliance with applicable regulations
          and the terms of affiliate programs
          <br />
          <br />
          Handling payments securely and in accordance with the privacy
          practices and standards of payment processors such as PayPal and
          Stripe
          <br />
          <br />
          We strive to ensure that our use of these services aligns with their
          respective privacy policies and requirements to safeguard your
          personal information.
          <br />
          <br />
          Please note that the specific practices and privacy requirements of
          these third-party services may vary, and it is recommended to review
          their individual privacy policies for more information.
          <br />
          <br />
          Changes to this Privacy Policy
          <br />
          <br />
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          material changes by posting the updated Privacy Policy on our website
          or through other communication channels. We encourage you to review
          this Privacy Policy periodically for the latest information.
          <br />
          <br />
          Contact Us
          <br />
          <br />
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our privacy practices, please contact us at:
          <br />
          <br />
          GRUBCON
          <br />
          <br />
          info@grubcon.com
        </P>
      </AnimatedDiv>
    </PageWrapper>
  );
};

import { Faq } from 'pages/Faq/Faq';
import { Home } from '../pages';
import { Privacy } from 'pages/Privacy/privacy';
import { Terms } from 'pages/Terms/terms';
import { Contact } from 'pages/Contact/contact';
import { Partner } from 'pages/Partner/partner';
import { Client } from 'pages/Client/client';
import { ThankYou } from 'pages/ThankYou/thankYou';
import { Pricing } from 'pages/Pricing/pricing';
import { PricingStart } from 'pages/Pricing/pricingStart';
import { Payment } from 'pages/Payment/payment';

export const ROOT = '/';
export const PARTNERS = '/partners';
export const CLIENTS = '/client';
export const PRICING = '/pricing';
export const PRICING_START = '/pricing/start';
export const PAYMENT = '/payment';
export const THANK_YOU = '/thank-you';
export const FAQ = '/faq';
export const CONTACT = '/contact-us';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const NOT_FOUND = '/*';

export const routes = [
  {
    path: ROOT,
    Page: Home,
  },
  {
    path: PARTNERS,
    Page: Partner,
  },
  {
    path: CLIENTS,
    Page: Client,
  },
  {
    path: THANK_YOU,
    Page: ThankYou,
  },
  {
    path: PRICING,
    Page: Pricing,
  },
  {
    path: PRICING_START,
    Page: PricingStart,
  },
  {
    path: PAYMENT,
    Page: Payment,
  },
  {
    path: FAQ,
    Page: Faq,
  },
  {
    path: CONTACT,
    Page: Contact,
  },
  {
    path: PRIVACY_POLICY,
    Page: Privacy,
  },
  {
    path: TERMS_AND_CONDITIONS,
    Page: Terms,
  },
];

import api from "api";
import { SuccessToast } from "components/Toasts/Toasts";
import { formatDataForGraph, getGraphType, handleError } from "helpers";
import { IProvider, providersInitialState } from "pagesPartner/Restaurants/data";
import { IProviderConfigResponse, IProviderStatusResponse } from "pagesPartner/Restaurants/providers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface BrandsState {
  loading: boolean;
  singleBrand:IBrand
  getSingleBrand:(id:string)=>void
  clearSingleBrand:()=>void
  getBrandSalesData:(id:string,dates:Date[],status:string)=>void
  getBrandGraph:(locationId:string,brandId:string,status:string, dates:Date[]) => void
  connectBrandsProvider:(providerName:string,storeId:string, brandId:string, locationId:string) => Promise<string>,
  disconnectBrandsProvider:(providerName:string, id:string) => Promise<void>,
  getBrandsProviderStatuses:(id:string, provider?:string) => void,
  clearBrandsProviderStatuses:()=>void
  setBrandProviders:(providers:IProvider[]) =>void,
  brandGraph:any
  brandProviders:IProvider[]

}

export const useBrands = create<BrandsState>()(
  devtools(
    persist(
      (set) => ({
        loading:false,
        singleBrand:null,
        brandGraph:[],
        brandProviders:[],
        getSingleBrand:async(id:string)=>{
          try {
            set({ loading: true });
            const {data} = await api.get("v1/brands/"+id);
            set({loading: false, singleBrand:data.data});
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getBrandSalesData:async(id:string)=>{
          try {
            set({ loading: true });
            const {data} = await api.get("v1/brands/"+id+'/sales');
            set({loading: false, singleBrand:data.data});
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getBrandGraph:async (locationId:string,brandId:string, status:string,dates:Date[],) => {
          try {
              set({loading:true})
              const resp = await api.get("v1/orders/grubcon-dashboard", {
                  params: {
                      status,
                      from: dates[0],
                      to: dates[1],
                      groupBy: getGraphType(dates),
                      locationIds: [locationId],
                      brandIds: [brandId],
                      providers: ["grubhub", "doordash", "ubereats"],
                  },
              });
              set({brandGraph:formatDataForGraph(resp.data.data, getGraphType(dates), dates[0]), loading:false})
          } catch (error) {
              console.log(error)
              handleError(error);
              set({loading:false})
          }
      },
      connectBrandsProvider:async(providerName:string,storeId:string, brandId:string, locationId:string): Promise<string> =>{
        try {
            set({loading:true})
            const resp = await api.post("v1/brands/" + brandId + "/provider/connect", {
                storeId,
                provider: providerName,
                redirectUrl:
                    process.env.REACT_APP_BASE_URL + `restaurants/${locationId}/brands/${brandId}/providers?provider=${providerName}`,
            });
            set({loading:false})
            return resp.data.data
        } catch (error) {
            handleError(error);
            set({loading:false})
        }
    },
    disconnectBrandsProvider:async(providerName:string, id:string): Promise<void> =>{
        try {
            set({loading:true})
            const resp = await api.post("v1/locations/" + id + "/provider/disconnect", {
                provider: providerName,
            });
            set({loading:false})
            SuccessToast('Provider successfully disconnected.')
            return resp.data.data
        } catch (error) {
            handleError(error);
            set({loading:false})
        }
    },
    getBrandsProviderStatuses:async(id:string,provider?:string) =>{
      try {
          set({loading:true})
          const { data } = await api.get('v1/brands/' + id + '/provider-connection-statuses')
          const copy = structuredClone(providersInitialState)
          for (const p of (data.data as IProviderStatusResponse[])) {
              const foundProviderIndex = copy.findIndex(pr => pr.provider === p.provider)
              if(p.provider === provider){
                  copy[foundProviderIndex].isConnected = true
              }else{
                  if(copy[foundProviderIndex]){
                      copy[foundProviderIndex].isConnected = p.isConnected
                  }
              }
              
          }

          const configResp = await api.get('v1/brands/' + id + '/provider/configs')
          for (const p of (configResp.data.data as IProviderConfigResponse[])) {
              const foundProviderIndex = copy.findIndex(pr => pr.provider === p.provider)
              delete p.provider
              copy[foundProviderIndex].config = p
          }

          set({loading:false, brandProviders:copy})

      } catch (error) {
          handleError(error)
          console.log(error)
          set({loading:false})


      }
  },
  clearBrandsProviderStatuses:()=>set({brandProviders:structuredClone(providersInitialState)}),
  setBrandProviders:(brandProviders:IProvider[]) => set({brandProviders}),
  clearSingleBrand:()=>set({singleBrand:null,brandGraph:[]}),

      }),
      { name: "grubcon-terms" }
    )
  )
);


interface IBrand{
    id: number,
    name: string,
    locationId: number,
    isLocation: boolean,
    crmApiKey: string,
    providers: [
      any
    ],
    imageUrl: string,
    notes: string,
    sip: {
      server: string,
      user: string,
      password: string,
      phoneNumber: string
    },
    type: "vGrubs" | "TDOC" | "FF" | "RB"
}
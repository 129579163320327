import { PlusCircleIcon } from "assets"
import { useNavigate } from "react-router"
import { Button, Disclaimer, Div, H4 } from "styles"
import { IdBox } from "./home.styled"
import { SALES_UNDER_REVIEW } from "routes/routeConstantsSales"
import { useRef, useState } from "react"
import { getImage } from "helpers"
import { useAgents } from "store/agents"
import { useAuth } from "store/auth"
import { SpinnerFullscreen } from "components/spinner/spinner"

export const VerifyAccount = () => {
    const frontRef = useRef(null)
    const backRef = useRef(null)
    const { postIdCard, loading } = useAgents(state => state)
    const { isVerificationInfoSent } = useAuth(state => state)

    const [front, setFront] = useState(null)
    const [back, setBack] = useState(null)

    const navigate = useNavigate()

    const handleImage = (e: any, back?: boolean) => {
        if (e.target.files[0]) {
            if (back) {
                setBack(e.target.files[0])
            } else {
                setFront(e.target.files[0])
            }
        }

    }

    const handleSubmit = async () => {
        try {
            await postIdCard('front', front)
            await postIdCard('back', back)
            isVerificationInfoSent(true)
            navigate(SALES_UNDER_REVIEW)
        } catch (error) {
            return error
        }

    }

    return <>
        <SpinnerFullscreen loading={loading} />
        <Div $flex $gap="6rem" $mb="4.5rem">
            <Div>
                <H4 $mb="1rem" $noTranform $bold $textSpacing="0" >Front ID:</H4>
                <IdBox onClick={() => frontRef.current.click()} >
                    {front ? <img alt="" src={getImage(front)} /> : <PlusCircleIcon />}
                    <input hidden
                        type="file"
                        accept="image/*"
                        ref={frontRef}
                        onChange={handleImage} />
                </IdBox>
            </Div>
            <Div>
                <H4 $mb="1rem" $noTranform $bold $textSpacing="0" >Back ID:</H4>
                <IdBox onClick={() => backRef.current.click()} >
                    {back ? <img alt="" src={getImage(back)} /> : <PlusCircleIcon />}
                    <input hidden
                        type="file"
                        accept="image/*"
                        ref={backRef}
                        onChange={e => handleImage(e, true)} />
                </IdBox>
            </Div>
        </Div>
        <Disclaimer>Please provide the front and back side of your identification document in order to verify your account. <br />
            Make sure that both sides of ID are visible and image is clear.</Disclaimer>
        <Button $round $w="36rem" onClick={handleSubmit} >SUBMIT</Button>
    </>
}
import { RestaurantButton } from "components/dropdownButton/restaurantButton";
import { useEffect, useState } from "react";
import { ContentWrapper, DateSelectsContainer, Disclaimer, Div, H2, H3, H5, P, PageDateContainer, } from "styles";
import { statuses } from "./data";
import {
    AreaTooltip,
    DashboardMainContent,
    DashboardStatusButton,
    DashboardWrapper,
    GraphTopWrapper,
    GraphWrapper,
    LogoCircle,
    PDDatesWrapper,
    PDSelectsWrapper,
    StatisticsCard,
    StatisticsCardsContainer,
    StatisticsIconCircle,
    StatusButtonsWrapper,
} from "./partnerDashboard.styled";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import DoorDashLogo from "../../assets/images/logos/DoorDashLogo.png";
import UberEatsLogo from "../../assets/images/logos/UberEatsLogo.png";
import GrubHubLogo from "../../assets/images/logos/GrubHubLogo.png";
import { CirlceCheckIcon, MessageIcon } from "assets";
import { AdminDatePicker } from "components/DatePicker/AdminDatePicker";
import { displayDateRange, getGraphAmount, getGraphStatusName, getGraphTotal, isAdmin, monthStart, setDocumentTitle, setTimeTo } from "helpers";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { useDashboard } from "store/dashboard";
import { BrandsButton } from "components/dropdownButton/brandsButton";
import { formatNumber } from "helpers";


export const PartnerDashboard = () => {
    const [dates, setDates] = useState([setTimeTo(monthStart(), 'start'), setTimeTo(new Date(), 'end')]);
    const [status, setStatus] = useState<IStatuses>("recovered");
    const { statistics, loading, getStatistics, getDashboardGraph, dashboardGraph } = useDashboard(state => state)
    const [selectedRestaurants, setSelectedRestaurants] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])

    useEffect(() => {
        getStatistics()
    }, []);


    useEffect(() => {
        getDashboardGraph(status, dates, selectedRestaurants.map(r => r.id), selectedBrands)
    }, [status, dates, selectedRestaurants, selectedBrands]);

    setDocumentTitle(isAdmin ? "Grub Concierge Admin" : "Grub Concierge Partner");

    useEffect(() => {
        setSelectedBrands([])
    }, [selectedRestaurants])


    const renderButtons = () => {
        return statuses.map((s) => (
            <DashboardStatusButton
                active={status === s.value}
                //@ts-ignore
                onClick={() => setStatus(s.value)}
            >
                <p>{s.label}</p>
            </DashboardStatusButton>
        ));
    };

    const CustomYAxisTick = ({ x, y, payload }: any) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={-5} y={-13} dy={16} fontSize='1.5rem' textAnchor="end" fill="#666">
                    $ {payload.value}
                </text>
            </g>
        );
    };


    return (
        <ContentWrapper >
            <SpinnerFullscreen loading={loading} />
            <PageDateContainer $noPadding >
                <H5 $desktop $noWrap $mb="0">{displayDateRange(dates)}</H5>
                <DateSelectsContainer>
                    <PDSelectsWrapper>
                        <RestaurantButton setSelectedRestaurants={setSelectedRestaurants} selectedRestaurants={selectedRestaurants} />
                        <BrandsButton selectedRestaurants={selectedRestaurants} selectedBrands={selectedBrands} setSelectedBrands={setSelectedBrands} />
                    </PDSelectsWrapper>

                    <PDDatesWrapper>
                        <H5 $mobile $noWrap $mb="0">{displayDateRange(dates)}</H5>
                        <AdminDatePicker
                            range={true}
                            dt={dates}
                            handleApply={(d: Date[]) => setDates(d)}
                            dateFrom={new Date()}
                            dateTo={new Date()}
                        />
                    </PDDatesWrapper>
                </DateSelectsContainer>
            </PageDateContainer>
            <StatusButtonsWrapper $desktop >
                {renderButtons()}
            </StatusButtonsWrapper>
            <DashboardWrapper>
                <DashboardMainContent width="75%">
                    <Div $flex $gap="2rem" $center $mb="2rem" $mobile>
                        <LogoCircle>
                            <img alt="" src={DoorDashLogo} />
                        </LogoCircle>
                        <LogoCircle>
                            <img alt="" src={UberEatsLogo} />
                        </LogoCircle>
                        <LogoCircle>
                            <img alt="" src={GrubHubLogo} />
                        </LogoCircle>
                    </Div>
                    <GraphTopWrapper>
                        <Div $flex $alignCenter $gap="2rem">
                            <H2 className="rest-graph" $mb="0" color="primary">
                                {getGraphTotal(dashboardGraph)}
                            </H2>
                            <Disclaimer $mb="0">Total<br /> {getGraphStatusName(status)}</Disclaimer>
                        </Div>
                        <Div $flex $gap="2rem" $desktop>
                            <LogoCircle>
                                <img alt="" src={DoorDashLogo} />
                            </LogoCircle>
                            <LogoCircle>
                                <img alt="" src={UberEatsLogo} />
                            </LogoCircle>
                            <LogoCircle>
                                <img alt="" src={GrubHubLogo} />
                            </LogoCircle>
                        </Div>
                        <Div $flex $gap="2rem" $center>
                            <Disclaimer $mb="0" $right>
                                Total Amount <br /> {getGraphStatusName(status)}:
                            </Disclaimer>
                            <H2 className="rest-graph" $mb="0" color="green">
                                ${getGraphAmount(dashboardGraph)}
                            </H2>
                        </Div>
                    </GraphTopWrapper>
                    <GraphWrapper>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                data={dashboardGraph}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: window.innerWidth < 800 ? -30 : 0,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="uberEats" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#05C637" stopOpacity={1} />
                                        <stop offset="95%" stopColor="#05C637" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="doorDash" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#FF2F09" stopOpacity={1} />
                                        <stop offset="95%" stopColor="#FF2F09" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="grubHub" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#FC8800" stopOpacity={1} />
                                        <stop offset="95%" stopColor="#FC8800" stopOpacity={0} />
                                    </linearGradient>
                                </defs>

                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis dataKey="name" fontSize="1.5rem" interval='equidistantPreserveStart' />
                                <YAxis tickFormatter={(value) => `$${value}`} tick={<CustomYAxisTick />} fontSize='1.5rem' />
                                <Tooltip
                                    wrapperStyle={{ outline: "none" }}
                                    content={(props) => {
                                        const uberEats = props.payload?.find(
                                            (p) => p.name === "uberEats"
                                        );
                                        const doorDash = props.payload?.find(
                                            (p) => p.name === "doorDash"
                                        );
                                        const grubHub = props.payload?.find(
                                            (p) => p.name === "grubHub"
                                        );
                                        return (
                                            <AreaTooltip>
                                                <div className="time">
                                                    <p>{props?.label}</p>
                                                </div>
                                                <Div $flex $alignCenter $gap="1rem">
                                                    <P $m="0">Door Dash: </P>
                                                    <P $m="0" style={{ color: "#FF2F09" }}>
                                                        ${doorDash?.value}
                                                    </P>
                                                </Div>
                                                <Div $flex $alignCenter $gap="1rem">
                                                    <P $m="0">Grub Hub: </P>
                                                    <P $m="0" style={{ color: "#FC8800" }}>
                                                        ${grubHub?.value}
                                                    </P>
                                                </Div>
                                                <Div $mb="2.5rem" $flex $alignCenter $gap="1rem">
                                                    <P $m="0">Uber Eats :</P>
                                                    <P $m="0" style={{ color: "#05C637" }}>
                                                        ${uberEats?.value}
                                                    </P>
                                                </Div>
                                            </AreaTooltip>
                                        );
                                    }}
                                />

                                <Area
                                    type="monotone"
                                    dataKey="doorDash"
                                    stroke="#fff"
                                    strokeWidth={2}
                                    strokeOpacity={0.9}
                                    fill="url(#doorDash)"
                                    fillOpacity="1"
                                />
                                <Area
                                    type="monotone"
                                    dataKey="uberEats"
                                    stroke="#fff"
                                    strokeWidth={2}
                                    strokeOpacity={0.9}
                                    fill="url(#uberEats)"
                                    fillOpacity=".8"
                                />
                                <Area
                                    type="monotone"
                                    dataKey="grubHub"
                                    stroke="#fff"
                                    strokeWidth={2}
                                    strokeOpacity={0.9}
                                    fill="url(#grubHub)"
                                    fillOpacity=".8"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </GraphWrapper>
                </DashboardMainContent>
                <StatusButtonsWrapper $mobile >
                    {renderButtons()}
                </StatusButtonsWrapper>
                <DashboardMainContent width="25%">
                    <Div $mb="2rem">
                        <H3 color="primary" $mb="0">
                            Statistics
                        </H3>
                        <Disclaimer $mb="0">Last 30 Days</Disclaimer>
                    </Div>
                    <StatisticsCardsContainer >
                        <StatisticsCard>
                            <StatisticsIconCircle>
                                <MessageIcon />
                            </StatisticsIconCircle>
                            <Div>
                                <P $mb="0">Total Recovered</P>
                                <H2 color="primary" $mb="0">
                                    {statistics.count}
                                </H2>
                            </Div>
                        </StatisticsCard>
                        <StatisticsCard>
                            <StatisticsIconCircle green>
                                <CirlceCheckIcon />
                            </StatisticsIconCircle>
                            <Div>
                                <P $mb="0">Total Amount Recovered</P>
                                <H2 color="green" $mb="0">
                                    ${formatNumber(statistics.amount)}
                                </H2>
                            </Div>
                        </StatisticsCard>
                    </StatisticsCardsContainer>
                </DashboardMainContent>
            </DashboardWrapper>
        </ContentWrapper>
    );
};

export type IStatuses =
    | "recovered"
    | "adjustment"
    | "pending"
    | "notEligible"
    | "evidenceNeeded";

export interface IDashboardResponse {
    groupBy: number | string;
    items: {
        amount: number;
        count: number
        provider: string;
    }[];
}

export interface IGraphData {
    name: number | string;
    doorDash: number;
    uberEats: number;
    grubHub: number;
    count: number
}
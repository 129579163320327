import { SpinnerFullscreen } from "components/spinner/spinner"
import { BigLogoCircle } from "pagesPartner/Restaurants/restaurants.styled"
import { useNavigate, useParams } from "react-router"
import { useUsers } from "store/users"
import { Button, Div, H3, P } from "styles"
import GrubConLogoBig from '../../assets/images/logos/GrubConLogoBig.png'

export const DeleteUser = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const { loading, deleteUser } = useUsers(state => state)

    const handleDelete = async () => {

        await deleteUser(id)
        navigate('/users')

    }
    return <Div $flex $column $height="100vh" $width="100%" $alignCenter $center >
        <SpinnerFullscreen loading={loading} />
        <BigLogoCircle>
            <img src={GrubConLogoBig} alt="" />
        </BigLogoCircle>
        <H3 $mt="5rem" $mb="4rem" $center >Delete User</H3>
        <P $mb="4rem" $center >Deleting this user is permanent, erases <br /> everything and can't be undone.<br />Are you sure? </P>
        <Div $column $flex $gap='2rem' >
            <Button $round $w="35rem" onClick={handleDelete}>DELETE</Button>
            <Button $round $secondary $w="35rem" onClick={() => navigate(-1)}>CANCEL</Button>

        </Div>

    </Div>
}
import { CloseIcon } from "assets"
import { AnimatePresence } from "framer-motion"
import { Div, H3 } from "styles"
import { ProvidersModalContainer } from "./restaurants.styled"

export const ImageModal = ({ handleClose, image }: { handleClose: any, image: any }) => {



    return <AnimatePresence>
        <ProvidersModalContainer $width="max-content" $image >
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    Voided Check
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <Div className="image-container" >
                <img src={image} alt='' />
            </Div>
        </ProvidersModalContainer>
    </AnimatePresence>
}

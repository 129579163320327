import { Input } from "components/input/Input"
import { BlueLink, Button, ContentWrapper, Div, H4, P } from "styles"
import { BankWrapper, BankInputs, PlaidWrapper, PlaidConnectBox, BlueButton } from "./home.styled"
import PlaidLogo from '../../assets/images/logos/PlaidLogo.png'

export const ConnectBank = () => {
    return <ContentWrapper>
        <BankWrapper>
            <BankInputs  >
                <Input placeholder="Enter here" label="First name" width='100%' />
                <Input placeholder="Enter here" label="Last name" width='100%' />
                <Input placeholder="Enter here" label="Your email" type="email" width='100%' />
                <Input placeholder="Enter here" label="Business Name" width='100%' />
            </BankInputs>
            <PlaidWrapper>
                <PlaidConnectBox>
                    <Div $flex $between ><img src={PlaidLogo} alt="" /> <BlueButton $blue ><H4 $mb="0" $textSpacing="0" $bold $noTranform color="white">Connect With Plaid</H4></BlueButton> </Div>
                    <Div $flex $between >
                        <P $m="0" >Use Plaid to link you bank account</P>
                        <BlueLink>Learn more about Plaid.</BlueLink>
                    </Div>
                </PlaidConnectBox>
                <Button>Connect Bank</Button>
            </PlaidWrapper>
        </BankWrapper>
    </ContentWrapper>
}
import { AnimatedDiv, FrameWrapper, Separator } from 'components';
import { WIDGET_BOOKING_URL, pricingBookingId } from 'helpers';
import { Div, H1, H4, P, PageWrapper } from 'styles';
import { PricingComponent } from './pricingComponent';

export const PricingStart = () => {
  return (
    <PageWrapper>
      <AnimatedDiv>
        <H4>pricing</H4>
        <H1>
          <span>Ready to start</span> with <span>GRUB</span>CON?
        </H1>
        <P>
          Set up a time to speak with a Grubcon representative and filling out
          the form below.
        </P>
      </AnimatedDiv>
      <Div $flex $gap='50px' $gapSM='0' $columnSM>
        <Div $width={'40%'} $widthMD={'50%'} $widthSM={'100%'} $flex>
          <AnimatedDiv>
            <PricingComponent type={2} />
          </AnimatedDiv>
        </Div>
        <Div $width={'60%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <FrameWrapper
              height={850}
              title='contact-dorm'
              src={`${WIDGET_BOOKING_URL}${pricingBookingId}`}
              id={pricingBookingId}
            ></FrameWrapper>
          </AnimatedDiv>
        </Div>
      </Div>
      <Separator />
    </PageWrapper>
  );
};

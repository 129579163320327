import { AnimatePresence } from "framer-motion"
import React, { SetStateAction } from "react"
import { Button, H3, HR, P } from "styles"
import { DoorDashExplain, GrubHubExplain, UberEatsExplain } from "./dataText"
import { ExplainModalContainer } from "./restaurants.styled"


export const ExplainModal = ({ type, closeModal }: { type: string, closeModal: React.Dispatch<SetStateAction<void>> }) => {


    const renderText = () => {
        if (type === 'GrubHub') {
            return <GrubHubExplain />
        } else if (type === 'DoorDash') {
            return <DoorDashExplain />
        }

        return <UberEatsExplain />
    }


    return <AnimatePresence>
        <ExplainModalContainer>
            <H3>{type}</H3>
            <HR />
            <P>{renderText()}</P>
            <Button $round $w="100%" $selected onClick={() => closeModal()} >OK</Button>
        </ExplainModalContainer>
    </AnimatePresence>
}
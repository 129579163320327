import api from "api";
import { requestForToken } from "firebase.config";
import axios from "axios";
import { API_URL } from "./helpers";

export const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "content-type": "application/json",
    "app-version": "web-version-1.0.0",
  },
});

export const getFCMTokenAndUpdate = async () => {
  try {
    const token = await requestForToken();
    console.log("TOKEN LOGIN", token);

    await api.put("/v1/users/fcm-token", {
      token,
      osType: "web",
    });
  } catch (error) {
    console.log(error);
  }
};

import Modal from 'components/modal/modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CMS_LOGIN, routesCMS } from 'routes/routeConstantsCMS';
import styled from 'styled-components';
import { DashboardIcon, LogoutIcon, logo } from '../../../assets';
import { Button, Disclaimer, Div, H3, Img, P } from '../../../styles';
import { isPartner, isSales } from 'helpers';
import { IRoute } from 'routes';
import { routesPartner } from 'routes/routeConstantsPartner';
import { routesSales } from 'routes/routeConstantsSales';
import { useAuth } from 'store/auth';

const AdminSidebarStyled = styled.div<{ $sidebarIsOpen: boolean }>`
  width: 30rem;
  flex: none;
  height: calc(100vh - 4.8rem);
  background-color: ${({ theme }) => theme.white};
  padding: 3.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 2.4rem;
  top: 2.4rem;
  bottom: 2.4rem;
  z-index: 9;

  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 1450px) {
    width: 27rem;
  }

  @media (max-width: 800px) {
    height: auto;
    width: 30rem;
    transition: ${({ theme }) => theme.transitions.ease300ms};
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    left: ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? '0px' : '-31rem')};
    top: 0;
    bottom: 0;
    padding-top: 10rem;
  }
`;

const AdminNavigationStyled = styled.nav`
  width: 100%;
`;

const NavLinkCircle = styled.nav<{ selected?: boolean }>`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  transition: all 3s ease;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
`;

const NavLink = styled.nav<{ selected?: boolean }>`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${({ theme, selected }) =>
    selected ? theme.primary : theme.secondary};
`;

export const MobileSidebarOverlay = styled.div<{ $sidebarIsOpen: boolean }>`
  background-color: ${({ theme }) => theme.white}4D;
  opacity: ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? '1' : '0')};
  visibility: ${({ $sidebarIsOpen }) =>
    $sidebarIsOpen ? 'visible' : 'hidden'};
  transition: ${({ theme }) => theme.transitions.ease300ms};
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
`;

interface AdminSidebarType {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AdminSidebar = ({
  sidebarIsOpen,
  setSidebarIsOpen,
}: AdminSidebarType) => {
  const pathname = useLocation().pathname;
  const { agent } = useAuth((state) => state);

  const selected = (path: string) => {
    return pathname.startsWith(path);
  };

  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);

  // const role = useAuth((state) => state?.user?.role);
  const role = isPartner ? 'partner' : isSales ? 'salesAgent' : 'admin'

  const renderRoutes = (routes: IRoute[]) => {
    return routes.map(({ path, name, Icon, permissions }: IRoute, i: number) => {
      if (name && permissions.includes(role)) {
        return (
          <Link to={path} key={i}>
            <Div $cursor='pointer' $flex $alignCenter $mb='2.4rem'>
              <NavLinkCircle selected={selected(path)}>
                {Icon ? <Icon /> : <DashboardIcon />}
              </NavLinkCircle>
              <NavLink selected={selected(path)}>{name}</NavLink>
            </Div>
          </Link>
        );
      } else return null;
    })
  }

  const verifySection = () => {
    if (isSales) {
      if (agent?.verified) {
        return
      }
      if (!agent?.verificationSent && !agent?.verified) {
        return <Div $mobile ><Button onClick={() => navigate('verify-account')} $selected > VERIFY ACCOUNT</Button></Div>
      } else if (agent?.verificationSent && !agent?.verified) {
        return <Div $mobile ><Disclaimer $center $mb='2rem' >Account is under review</Disclaimer></Div>
      }
    }
  }

  return (
    <>
      <AdminSidebarStyled $sidebarIsOpen={sidebarIsOpen}>
        <Div $width='100%' $flex $column $center>
          <Div $flex $center $mb='3.2rem'>
            <Img src={logo} alt='grubcon' $width='100%' />
          </Div>
          <AdminNavigationStyled>
            {renderRoutes(isPartner ? routesPartner : isSales ? routesSales : routesCMS)}

          </AdminNavigationStyled>
        </Div>
        <Div $width='100%' $widthSM='100%'>
          {verifySection()}
          <Div
            $width='100%'
            $pointer
            $flex
            $alignCenter
            onClick={() => setLogoutModal(true)}
          >
            <NavLinkCircle>
              <LogoutIcon />
            </NavLinkCircle>
            <NavLink>Logout</NavLink>
          </Div>
        </Div>
      </AdminSidebarStyled>

      {/* SIDEBAR MODAL OVERLAY */}
      <MobileSidebarOverlay
        $sidebarIsOpen={sidebarIsOpen}
        onClick={() => setSidebarIsOpen(false)}
      />

      {/* LOGOUT MODAL */}
      <Modal isOpen={logoutModal} setIsOpen={setLogoutModal} exitButton={false}>
        <H3 $mb='1.2rem' $center>
          Logout
        </H3>

        <P $mb='2.4rem' $center>
          Are you sure you want to logout?
        </P>

        <Div $flex $gap='1.6rem'>
          <Button onClick={() => navigate(CMS_LOGIN)} $w='100%'>
            Yes
          </Button>
          <Button $w='100%' onClick={() => setLogoutModal(false)} $secondary>
            Cancel
          </Button>
        </Div>
      </Modal>
    </>
  );
};


import { CirlceCheckIcon, LocationIcon, StoreIcon } from "assets"
import { ErrorToast } from "components/Toasts/Toasts"
import { AnimatePresence } from "framer-motion"
import { DropdownButtonWrapper } from "pagesPartner/Dashboard/partnerDashboard.styled"
import { MiniCircle } from "pagesPartner/FindRestaurant/findRestaurants.styled"
import { OptionsWrapper } from "pagesPartner/Orders/orders.styled"
import React, { SetStateAction, useEffect, useRef, useState } from "react"
import { Button, Div, H3, H4, Overlay, overlayAnimationVariant, variants } from "styles"
import { DropDownButton } from "./dropDownButton"
import { RestaurantSearchBox, SingleRestaurantOption } from "./dropdownButton.styled"
import Restaurant from '../../assets/images/restaurant.png'
import { NoSelectItemsContainer } from "pagesPartner/Restaurants/restaurants.styled"

export const BrandsButton = ({ selectedRestaurants, selectedBrands, setSelectedBrands }: IRestaurantButton) => {

    const ref = useRef(null)
    const [open, setOpen] = useState(false)
    const [localSelected, setLocalSelected] = useState(selectedBrands)


    const handleSelect = (id: number) => {
        setLocalSelected((prev) => {
            let copy = [...prev]
            if (copy.includes(id)) {
                copy = copy.filter(r => r !== id)
            } else {
                copy.push(id)
            }
            return copy
        })
    }


    const handleConfirm = () => {
        setOpen(false)
        setSelectedBrands(localSelected)
    }

    const handleReset = () => {
        setLocalSelected([])
        setOpen(false)
        setSelectedBrands([])
    }


    const getAllBrands = () => {
        let ar: any = []
        for (let r of selectedRestaurants) {
            ar = [...ar, ...r.brands]
        }
        return ar
    }

    const handleOpen = () => {
        if (selectedRestaurants.length !== 1) {
            ErrorToast('One restaurant must be selected to chose brand')
            return
        }
        setOpen(true)
    }



    const getTitle = () => {
        if (!selectedBrands.length) {
            return 'All brands'
        } else if (selectedBrands.length == 1) {
            return '1 Brand selected'
        } else {
            return selectedBrands.length + ' brands selected'
        }
    }

    useEffect(() => {
        setLocalSelected([])
        setSelectedBrands([])
    }, [selectedRestaurants])

    return (
        <AnimatePresence>
            <DropdownButtonWrapper ref={ref} onClick={handleOpen} >
                <DropDownButton title={getTitle()} width="35rem" icon={<StoreIcon />} />
                {open &&
                    <RestaurantSearchBox onClick={e => e.stopPropagation()} key="DropdownMenu"
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{
                            type: 'spring',
                            bounce: 0.4,
                            duration: 0.8,
                        }}
                    >
                        <Div $mt="-2rem" $flex $width="100%" $column $gap="1rem" $center>
                            <OptionsWrapper>
                                {getAllBrands().length ? getAllBrands().map((r: any) => <SingleRestaurantOption onClick={() => handleSelect(r.id)} >
                                    <Div $height="100%" $flex $column $between >
                                        <H4 $bold $noTranform $textSpacing="0" $mb="0" >{r.name}</H4>
                                    </Div>
                                    {localSelected.includes(r.id) ? <CirlceCheckIcon stroke="#C28831" /> : <MiniCircle />}
                                </SingleRestaurantOption>) : <NoSelectItemsContainer > <img src={Restaurant} alt="" /> <H3 $bold $noTranform $center >No Brands</H3></NoSelectItemsContainer>}
                            </OptionsWrapper>


                            <Div $flex $width="36rem" $gap="2rem" $mt="2rem">
                                <Button $w="100%" $round onClick={handleReset} >RESET SELECTION</Button>
                                <Button $w="100%" $selected $round onClick={handleConfirm} >CONFIRM</Button>
                            </Div>
                        </Div>
                    </RestaurantSearchBox>
                }
            </DropdownButtonWrapper>
            {open && (
                <Overlay
                    key="modalOverlay"
                    variants={overlayAnimationVariant}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    onClick={() => setOpen(false)}
                />
            )}
        </AnimatePresence>
    )

}
interface IRestaurantButton {
    selectedRestaurants: { id: number, brands: { id: number, name: string }[] }[]
    selectedBrands: number[]
    setSelectedBrands: React.Dispatch<SetStateAction<number[]>>
}
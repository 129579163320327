import { Disclaimer, Div, H3 } from 'styles';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Dispatch, SetStateAction } from 'react';
import { INPUT_OPTIONS, StripeInputStyled } from './payment.styled';

export const StripeForm = ({
  setName,
  name,
  email,
  setEmail,
}: {
  setName: Dispatch<SetStateAction<string>>;
  name: string;
  setEmail: Dispatch<SetStateAction<string>>;
  email: string;
}) => {
  return (
    <Div $width='40%' $widthMD='50%' $widthSM='100%'>
      <H3 $mb='2rem'>
        <span>Card Details</span>
      </H3>

      <StripeInputStyled>
        <input
          type='text'
          placeholder='Full Name'
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoCapitalize='words'
        />
      </StripeInputStyled>
      <StripeInputStyled>
        <input
          type='email'
          placeholder='Email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          autoCapitalize='words'
        />
      </StripeInputStyled>
      <StripeInputStyled>
        <CardNumberElement options={INPUT_OPTIONS} />
      </StripeInputStyled>
      <Div $flex $gap='1.6rem'>
        <Div $width='50%'>
          <StripeInputStyled>
            <CardExpiryElement options={INPUT_OPTIONS} />
          </StripeInputStyled>
        </Div>
        <Div $width='50%'>
          <StripeInputStyled>
            <CardCvcElement options={INPUT_OPTIONS} />
          </StripeInputStyled>
        </Div>
      </Div>
      <Disclaimer $mb='0'>*All fields required.</Disclaimer>
    </Div>
  );
};

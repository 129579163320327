import api from "api"
import { ArrowLeft } from "assets"
import { Input } from "components/input/Input"
import { InputLabel } from "components/input/input.styled"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { ErrorToast } from "components/Toasts/Toasts"
import { changeState, handleError } from "helpers"
import { standardFieldValidation } from "helpers/validationHelpers"
import { AdminInput } from "pagesCMS/Login/login.cms"
import { useEffect, useState } from "react"
import PhoneInput from "react-phone-number-input"
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "store/auth"
import { Div, SeparatorBorderCMS, H4, H3, Button } from "styles"
import { confirmInitialState, confirmInvalidFields, IConfrimState, IFindRestaurantTab } from "./data"
import { E164Number, isValidPhoneNumber } from 'libphonenumber-js'
import { read, remove } from "helpers/localstorage-helper"
//@ts-ignore
import isValidCoordinates from 'is-valid-coordinates'

export const ConfirmRestaurant = ({ state, setState, setActive }: IFindRestaurantTab) => {
    // @ts-ignore
    const [invalidFields, setInvalidFields] = useState(confirmInvalidFields)
    const [localState, setLocalState] = useState<IConfrimState>(confirmInitialState)
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const { user } = useAuth((state) => state);
    const navigate = useNavigate()

    const incompleteLocation = JSON.parse(read('incompleteLocation'))
    useEffect(() => {

        if ((location.search.includes('add') || incompleteLocation) && !state.manualInput) {
            const confirmData = {
                location: incompleteLocation.name,
                address: incompleteLocation.address,
                city: incompleteLocation.city,
                country: incompleteLocation.country,
                state: incompleteLocation.state,
                zip: incompleteLocation.zipCode,
                phone: incompleteLocation.phone,
                streetNumber: incompleteLocation.streetNumber,
                latitude: incompleteLocation.coordinates.latitude,
                longitude: incompleteLocation.coordinates.longitude,
            }
            setLocalState(confirmData)
            //@ts-ignore
            setInvalidFields(Object.keys(confirmData).filter(k => !confirmData[k] && k !== 'phone'))
            return
        }

        state.confirmData && setLocalState(state.confirmData)
        // @ts-ignore
        state.confirmData && setInvalidFields(Object.keys(state.confirmData).filter(k => !state.confirmData[k] && k !== 'phone'))
    }, [])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target
        standardFieldValidation(e, setInvalidFields)
        changeState(setLocalState, name, value)
    }


    const handleConfirm = async () => {

        if (invalidFields.length > 0) {
            if (!(invalidFields.length === 1 && state.manualInput && invalidFields[0] === 'location')) {
                ErrorToast('Please fill all the fields.')
                return
            }

        } else if (!localState.phone || !isValidPhoneNumber(localState.phone)) {
            ErrorToast('Phone number must be valid.')
            return
        }

        if (state.manualInput) {
            if (!localState.latitude || !isValidCoordinates.latitude(parseFloat(localState.latitude))) {
                ErrorToast('Latitude must be valid.')
                return
            } else if (!localState.longitude || !isValidCoordinates.longitude(parseFloat(localState.longitude))) {
                ErrorToast('Longitude must be valid.')
                return
            }
        }
        setLoading(true)

        const forDispatch: any = structuredClone(localState)
        delete forDispatch.phone
        delete forDispatch.zip
        delete forDispatch.location

        forDispatch.phoneNumber = localState.phone
        forDispatch.zipCode = localState?.zip || ''
        forDispatch.name = localState?.location || ''
        if (state.manualInput) {
            forDispatch.coordinates = {
                latitude: localState?.latitude,
                longitude: localState?.longitude
            }
            delete forDispatch.location
        }
        delete forDispatch.longitude
        delete forDispatch.latitude
        try {
            if (state.manualInput) {

                if (incompleteLocation) {
                    await api.put('v1/locations/' + incompleteLocation.id + '/complete')
                }
                const resp = await api.post('v1/locations/create-manual', forDispatch)
                await api.put('v1/locations/' + resp.data.data.id + '/complete')
            } else {
                await api.put('v1/locations/' + incompleteLocation.id + '/confirm', { ...forDispatch })
                await api.put('v1/locations/' + incompleteLocation.id + '/complete')
            }
            navigate('/standalone-waiting-verification')
            setLoading(false)
            remove('referralId')
            remove('referralPercentage')
            remove('incompleteLocation')

        } catch (error) {
            handleError(error)
            setLoading(false)
        }

    }

    const arrowAction = async () => {
        if (incompleteLocation) {
            try {
                setLoading(true)
                await api.delete('v1/locations/' + incompleteLocation.id + '/cancel-creation')
                setLoading(false)
                remove('incompleteLocation')
                setActive('search')
            } catch (error) {
                handleError(error)
                setLoading(false)
                return

            }
        } else {
            setActive('search')
        }
    }

    const handlePhoneNumber = (v: E164Number) => {
        changeState(setLocalState, 'phone', v)
    }

    return <><Div $mb="3.4rem" $flex $gap="2rem" $alignCenter>
        <SpinnerFullscreen loading={loading} />
        {<Div $flex $center $justifyCenter $cursor="pointer" onClick={arrowAction} >
            <ArrowLeft />
        </Div>}
        <H3 $mb="0" color="primary">
            Confirm Your Restaurant
        </H3>
    </Div>
        <SeparatorBorderCMS />
        <H4 $mt="2.3rem" $mb="1.5rem" $noTranform $bold $textSpacing="0">
            Step 3/3
        </H4>
        <Div $flex $column $gap="1rem" >
            <Input label="Location name" $textCenter defaultValue={localState.location} name='location' onChange={onChange} placeholder="Enter here" />
            <Div $flex $gap="1rem" >
                <Input label="Street Number" $textCenter defaultValue={localState.streetNumber} name='streetNumber' onChange={onChange} placeholder="Enter here" />
                <Input label="Street Name" $textCenter defaultValue={localState.address} name='address' onChange={onChange} placeholder="Enter here" />
            </Div>
            <Div $flex $gap="1rem" >
                <Input label="City" $textCenter defaultValue={localState.city} name='city' onChange={onChange} placeholder="Enter here" />
                <Input label="Country" $textCenter defaultValue={localState.country} name='country' onChange={onChange} placeholder="Enter here" />
            </Div>
            <Div $flex $gap="1rem" >
                <Input label="State" $textCenter defaultValue={localState.state} name='state' onChange={onChange} placeholder="Enter here" />
                <Input label="ZIP Code" $textCenter defaultValue={localState.zip} name='zip' onChange={onChange} placeholder="Enter here" />
            </Div>
            {(state.manualInput && !incompleteLocation) && <Div $mb="1rem" $flex $gap="2rem" >
                <Input label="Latitude" $textCenter defaultValue={localState.latitude} name='latitude' onChange={onChange} placeholder="Enter here" />
                <Input label="Longitude" $textCenter defaultValue={localState.longitude} name='longitude' onChange={onChange} placeholder="Enter here" />
            </Div>}
            <InputLabel $mb='0'>Business Phone Number</InputLabel>
            <AdminInput $round $noMarg={state.manualInput} >
                <PhoneInput
                    placeholder='Enter here'
                    value={localState.phone}
                    onChange={v => handlePhoneNumber(v)}
                    defaultCountry='US'
                    addInternationalOption={false}
                    international
                    focusInputOnCountrySelection={true}
                />
            </AdminInput>

            <Button $round $w="100%" $selected onClick={handleConfirm} >CONFIRM</Button>
        </Div>
    </>
}
import { H3, NoItemsContainer, P } from "styles"
import FoodBox from '../../assets/images/FoodBox.png'

export const NoItems = ({ img, title, subtitle }: INoItems) => {
    return <NoItemsContainer>
        <img src={img ?? FoodBox} alt="" />
        <H3 color="primary" $mt="-3rem" $mb="0" >{title}</H3>
        <P>{subtitle}</P>
    </NoItemsContainer>
}

interface INoItems {
    img?: any
    title: string
    subtitle: string
}
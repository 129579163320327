import { AnimatedDiv } from 'components';
import { H1, H4, P, PageWrapper } from 'styles';

export const Terms = () => {
  return (
    <PageWrapper>
      <AnimatedDiv>
        <H4>v grubs</H4>
        <H1>
          <span>Terms</span> & Conditions
        </H1>
        <P>
          Terms and Conditions for GRUBCON Telephone Support and Order
          Assistance Services Effective Date: [Jun 18, 2023] Please read these
          terms and conditions carefully before using GRUBCON's telephone
          support and order assistance services. By accessing or using our
          services, you agree to be bound by the terms and conditions outlined
          below. If you do not agree with any part of these terms and
          conditions, you may not use our services. Introduction <br />
          <br />
          1.1 Overview GRUBCON provides telephone support and order assistance
          services to restaurant partners ("Partners") and restaurant service
          providers ("Providers") for their restaurant clients. Our services
          assist with inquiries, technical issues, and recovering cancellations
          from third-party providers. However, the success of recovery is
          subject to certain conditions and limitations.
          <br />
          <br />
          1.2 Agreement By using our services, you acknowledge that you have
          read, understood, and agreed to comply with these terms and
          conditions.
          <br />
          <br />
          Telephone Support and Order Assistance Services
          <br />
          <br />
          2.1 Eligibility Our telephone support and order assistance services
          are available to both Partners and Providers. By accessing our
          services, you confirm that you are authorized to represent the
          restaurant or service provider and act on their behalf.
          <br />
          <br />
          2.2 Availability We strive to provide uninterrupted services, but we
          do not guarantee continuous availability. Services may be subject to
          scheduled maintenance or interruption due to unforeseen circumstances.
          <br />
          <br />
          2.3 Service Limitations While we will make reasonable efforts to
          assist in recovering lost revenue from third-party provider
          cancellations, we cannot guarantee success. The success of recovery
          depends on factors beyond our control, including the policies and
          limitations set by third-party providers.
          <br />
          <br />
          2.4 Avoidable Cancellations We cannot recover lost revenue if the
          cancellation was avoidable or a result of the Partner's or Provider's
          actions or omissions. We will not engage in any activities that
          violate the policies or terms set by third-party providers. 2.5
          Information Security During the telephone support session or order
          assistance, we may collect and process certain information related to
          inquiries or orders. We will handle this information in accordance
          with our Privacy Policy [include link to privacy policy]. Obligations
          and Responsibilities
          <br />
          <br />
          3.1 Partner and Provider Responsibilities As a Partner or Provider,
          you agree to: <br /> a) Provide accurate and up-to-date information
          during the telephone support session or order assistance. <br /> b)
          Cooperate with our support representatives and provide necessary
          access to systems or information required for troubleshooting or order
          assistance. <br /> c) Comply with applicable laws and regulations
          while using our services.
          <br />
          <br />
          3.2 Prohibited Activities You are prohibited from:
          <br />
          <br />
          a) Misrepresenting your identity or relationship with the restaurant
          or service provider.
          <br /> b) Engaging in fraudulent or illegal activities. <br />
          c) Attempting to disrupt or compromise the security or integrity of
          our services.
          <br />
          <br />
          Intellectual Property
          <br />
          <br />
          4.1 Ownership All intellectual property rights, including but not
          limited to trademarks, logos, and content, related to GRUBCON's
          services are owned by GRUBCON or its licensors.
          <br />
          <br />
          4.2 Usage Restrictions You may not reproduce, distribute, modify, or
          create derivative works based on any part of our services without
          obtaining prior written permission from GRUBCON.
          <br />
          <br />
          Limitation of Liability
          <br />
          <br />
          5.1 Disclaimer GRUBCON's services are provided on an "as-is" and "as
          available" basis. We make no warranties or representations, express or
          implied, regarding the accuracy, reliability, or suitability of the
          services.
          <br />
          <br />
          5.2 Limitation of Liability To the extent permitted by law, GRUBCON
          and its affiliates, officers, directors, employees, or agents shall
          not be liable for any direct, indirect, incidental, consequential, or
          punitive damages arising out of or related to the use of our services
          or any lost revenue resulting from third-party delivery cancellations.
          <br />
          <br />
          Termination
          <br />
          <br />
          6.1 Termination by GRUBCON GRUBCON reserves the right to suspend or
          terminate your access to our services, with or without cause, at any
          -me and without prior notice.
          <br />
          <br />
          6.2 Termination by Partner or Provider Partners or Providers may
          discontinue using our services at any time by providing written notice
          to GRUBCON. Prohibited Activities and Misuse
          <br />
          <br />
          7.1 Misuse of Services GRUBCON expects all restaurant partners to use
          our services responsibly and in compliance with applicable laws,
          regulations, and industry standards. Misuse of our services is
          strictly prohibited.
          <br />
          <br />
          7.2 Bad Actor Behaviors Restaurant partners must not abuse GRUBCON
          services or utilize them as a conduit to engage in any form of bad
          actor behaviors. This includes, but is not limited to:
          <br />
          <br />
          a) Engaging in fraudulent activities or misrepresenting information.
          <br /> b) Attempting to deceive or manipulate the system for personal
          gain.
          <br /> c) Violating any laws, regulations, or the terms and conditions
          set forth by GRUBCON or third-party providers.
          <br /> d) Engaging in activities that harm the reputation or integrity
          of GRUBCON or its partners.
          <br />
          <br />
          7.3 Termination for Misrepresentation or Abuse GRUBCON reserves the
          right to immediately terminate the services provided to a restaurant
          partner if GRUBCON determines, at its sole discretion, that the
          partner has misrepresented information or abused the system of
          third-party delivery companies. Such termination may occur without
          prior notice and may result in the partner's loss of access to GRUBCON
          services.
          <br />
          <br />
          Financial Loss of Revenue and Recovery Attempts
          <br />
          <br />
          8.1 Limitation of Liability GRUBCON shall not be held responsible for
          any financial loss of revenue incurred by restaurant partners due to
          the declination of recovery attempts by third-party delivery
          companies. While we will make reasonable efforts to assist in
          recovering lost revenue, the success of recovery is subject to the
          policies and decisions of the third-party delivery companies.
          <br />
          <br />
          8.2 No Guarantee of Recovery GRUBCON does not guarantee the successful
          recovery of lost revenue from third-party delivery cancellations. We
          will make our best efforts to assist restaurant partners, but the
          outcome of recovery attempts depends on factors beyond our control,
          including the policies and decisions of third-party delivery
          companies.
          <br />
          <br />
          8.3 Avoidable Cancellations GRUBCON shall not be responsible for
          recovering lost revenue resulting from avoidable cancellations. It is
          the responsibility of the restaurant partner to minimize avoidable
          cancellations and take appropriate actions to ensure the accuracy and
          timely fulfillment of orders.
          <br />
          <br />
          Communication with Restaurant Customers
          <br />
          <br />
          9.1 Order Communication GRUBCON reserves the right to communicate with
          restaurant customers on behalf of the restaurant partner. This
          includes facilitating order-related communication, such as order
          status updates, delivery information, and addressing customer
          inquiries or concerns.
          <br />
          <br />
          9.2 Future Communication GRUBCON may also contact restaurant customers
          for any reason in the future, including but not limited to promotions,
          surveys, feedback requests, or important service updates.
          <br />
          <br />
          9.3 Customer Privacy GRUBCON respects the privacy of restaurant
          customers and handles their personal information in accordance with
          our Privacy Policy [include link to privacy policy]. We will only use
          customer information for the purposes outlined in this section and as
          permitted by applicable laws and regulations.
          <br />
          <br />
          Governing Law and Jurisdiction
          <br />
          <br />
          10.1 Governing Law These terms and conditions shall be governed by and
          construed in accordance with the laws of the State of New York, United
          States, without regard to its conflict of laws principles.
          <br />
          <br />
          10.2 Jurisdiction Any dispute arising out of or relating to these
          terms and conditions shall be subject to the exclusive jurisdiction of
          the state and federal courts located in the State of New York, United
          States.
          <br />
          <br />
          10.3 New York Corporation GRUBCON is a New York corporation, and any
          legal references or implications in these terms and conditions
          regarding GRUBCON shall pertain to its status as a New York
          corpora-on.
        </P>
      </AnimatedDiv>
    </PageWrapper>
  );
};

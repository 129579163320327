import styled from "styled-components"

export const HomeSection = styled.div<{$pointer?:boolean, $bigSvg?:boolean, $svgGray?:boolean}>`

    width: 100%;
    min-height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding:  0 3rem;
    cursor: pointer;
    ${({ $pointer }) => $pointer && 'cursor:pointer;'}
    ${({ $bigSvg }) => $bigSvg && 'svg{width:32px;height:32px}'}
    ${({ $svgGray }) => $svgGray && 'svg{path{stroke:#737373}};'}
`

export const BankCard = styled.div`

    min-height: 25rem;
    height: 25rem;
    min-width: 46rem;
    width: 46rem;
    background-color: ${({theme})=>theme.lightBlue} ;
    color: white;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding: 2.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;

`

export const BlueButton = styled.div<{$blue?:boolean}>`

        cursor: pointer;
        height: 5rem;
        color: ${({theme})=> theme.blue};
        background-color: ${({theme, $blue})=> $blue ? theme.blue : theme.white};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        padding: 0 3rem;
`


export const IdBox = styled.div`

    overflow: hidden;
    width: 36rem;
    height: 23rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    position: relative;
    cursor: pointer;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

`

export const BankWrapper = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

`

export const BankInputs = styled.div`

   display: flex;
   flex-direction: column;
   gap: 2rem;
width: 40%;

`

export const PlaidWrapper = styled.div`

    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 40%;
    align-items: flex-end;

`

export const PlaidConnectBox = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    gap: 2rem;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: 20px;

`

export const BankContainer = styled.div`
display: flex;
margin-top: 3rem;
gap: 4rem;
justify-content: space-between;
align-items: center;

@media (max-width: 1100px) {
  flex-direction: column;
  }
`
import {
    Disclaimer,
    Div,
    H3,
    H4,
    P,
} from "styles";
import {
    BrandIcon,
    BrandsExpand,
    BrandsHead,
    BrandsList,
    BrandsListItem,
    RestaurandCardContainer,
    RestaurantCardBottom,
    RestaurantImage,
    VerticalSeparator,
} from "./restaurants.styled";
import GrubConLogo from "../../assets/images/logos/GrubConLogo.png";
import { ArrowLeft, ChevronDown, PendingIcon, StoreIcon } from "assets";
import { useNavigate } from "react-router";
import { IRestaurant } from "./restaurants";
import { useState } from "react";
import { formatNumber } from "helpers";


export const RestaurantCard = ({ data }: { data: IRestaurant }) => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleExpand = (e: any) => {
        e.stopPropagation()
        data.brands.length && setOpen(prev => !prev)
    }


    return <RestaurandCardContainer onClick={() => navigate(String(data.id))}>
        <Div $width="100%" $minWidth="100%" >
            <Div $flex $alignCenter $gap="3rem" >
                <RestaurantImage>
                    <img src={GrubConLogo} alt="" />
                </RestaurantImage>
                <Div $height="100%" $flex $column $gap="2rem" $width="100%">
                    <Div>
                        <H3 className="resp-center" $mb="0">
                            {data.name}
                        </H3>
                        <P className="resp-center" $mb="0">
                            {data.address}
                        </P>
                    </Div>

                </Div>
            </Div>
            {!data.verified ? (
                <Div $flex $gap="1.5rem">
                    <PendingIcon />
                    <H4
                        $mb="0"
                        $textSpacing="0"
                        $bold
                        $noTranform
                        color="primary"
                    >
                        Pending Review
                    </H4>
                </Div>
            ) : (
                <RestaurantCardBottom>
                    <Div>
                        <H4
                            $mb="0"
                            $textSpacing="0"
                            $bold
                            $noTranform
                            color="primary"
                        >
                            {data.orders}
                        </H4>
                        <Disclaimer $mb="0">
                            Last 30 days Recovery Interactions
                        </Disclaimer>
                    </Div>
                    <VerticalSeparator />
                    <Div>
                        <H4
                            $right
                            $mb="0"
                            $textSpacing="0"
                            $bold
                            $noTranform
                            color="primary"
                        >
                            ${formatNumber(data.amount)}
                        </H4>
                        <Disclaimer $right $mb="0">
                            Last 30 days Recovered Amount
                        </Disclaimer>
                    </Div>
                </RestaurantCardBottom>
            )}
            <BrandsExpand onClick={handleExpand} open={open} >
                <BrandsHead $active={open}  >
                    <Div $flex $gap="1rem" >
                        <BrandIcon>
                            <Div>
                                <StoreIcon />
                            </Div>
                        </BrandIcon>
                        <Div $flex $alignCenter $gap="1rem" >
                            {data?.brands?.length ? <><H4 $mb="0 !important" $noTranform $textSpacing="0" $bold >View Brands</H4>
                                <Disclaimer $mb="0" > ({data.brands.length}) </Disclaimer></> : <H4 $mb="0 !important" $noTranform $textSpacing="0" $bold >No Brands</H4>}

                        </Div>
                    </Div>
                    {data?.brands?.length ? <ChevronDown /> : null}
                </BrandsHead>
                <BrandsList >
                    {data?.brands?.map(b => <BrandsListItem onClick={(e: any) => {
                        e.stopPropagation()
                        navigate(data.id + '/brand/' + b.id)
                    }} >
                        <P>{b.name}</P>
                        <Div>
                            <ArrowLeft />
                        </Div>
                    </BrandsListItem>)}
                </BrandsList>
            </BrandsExpand>
        </Div>
    </RestaurandCardContainer>
}
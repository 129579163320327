import { CloseIcon } from "assets"
import { Input } from "components/input/Input"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { ErrorToast } from "components/Toasts/Toasts"
import { AnimatePresence } from "framer-motion"
import { changeState } from "helpers"
import React, { useState } from "react"
import { useParams } from "react-router"
import { useRestaurants } from "store/restaurants"
import { Button, Div, H3, SeparatorBorderCMS } from "styles"
import { ProvidersModalContainer } from "./restaurants.styled"

export const ConfigModal = ({ handleClose }: { handleClose: any }) => {

    const { config, updateLast4Digits, loading } = useRestaurants(state => state)
    const [localState, setLocalState] = useState(config ?? { einLast4: '', bankAccountLast4: '', })
    const { id } = useParams()
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        changeState(setLocalState, name, value);
    };

    const handleConfirm = async () => {

        if (!localState.einLast4 || !localState.bankAccountLast4) {
            ErrorToast('Both fields must be valid.')
            return
        }

        await updateLast4Digits(localState, id)
        handleClose()
    }


    return <AnimatePresence>
        <ProvidersModalContainer>
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    Configuration
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <SeparatorBorderCMS />
            <Div $mt="2rem" $mb="2rem" $flex $column $gap="2rem" $width="100%">
                <Input
                    label="Last 4 digits of EIN"
                    defaultValue={localState.einLast4}
                    onChange={onChange}
                    name="einLast4"
                    placeholder="Enter here"
                    maxLength={4}
                />
                <Input
                    label="Last 4 digits of Bank Account"
                    defaultValue={localState.bankAccountLast4}
                    onChange={onChange}
                    name="bankAccountLast4"
                    placeholder="Enter here"
                    maxLength={4}
                />
            </Div>
            <Button $mt="2rem" $round $selected $w="100% " onClick={handleConfirm}>
                CONFIRM
            </Button>
        </ProvidersModalContainer>
    </AnimatePresence>
}

import { CirlceCheckIcon, LocationIcon, StoreIcon } from "assets"
import { SingleRestaurantOption } from "components/dropdownButton/dropdownButton.styled"
import { Input } from "components/input/Input"
import { useOnClickOutside } from "helpers"
import { MiniCircle } from "pagesPartner/FindRestaurant/findRestaurants.styled"
import { useEffect, useRef, useState } from "react"
import { Button, Disclaimer, Div, H3, H4, variants } from "styles"
import { OptionsWrapper, OrdersOptionsContainer, OrdersSectionHead } from "./orders.styled"
import Restaurant from '../../assets/images/restaurant.png'
import { NoSelectItemsContainer } from "pagesPartner/Restaurants/restaurants.styled"
export const OrdersButton = ({ disabled, name, handleQuery, results, selectedRestaurant, setSelectedRestaurant, setParams, selectedBrands, setSelectedBrands, brands }: { disabled?: boolean, name: string, handleQuery: (e: React.ChangeEvent<HTMLInputElement>) => void, results: any, selectedRestaurant?: any, setSelectedRestaurant?: any, selectedBrands?: any, setSelectedBrands?: any, setParams: any, brands?: boolean }) => {

    const [open, setOpen] = useState(false)
    const [tempSelected, setTempSelected] = useState<{
        name: string;
        id: number;
        brands: {
            name: string
            id: number
        }[]
    }>(selectedRestaurant)
    const [tempSelectedBrands, setTempSelectedBrands] = useState<{
        name: string;
        id: number;
    }[]>(selectedBrands)
    const ref = useRef()


    useEffect(() => {
        if (brands) {
            setTempSelectedBrands([])
            setSelectedBrands([])

        }
    }, [selectedRestaurant])

    const handleConfirm = (e: any) => {
        e.stopPropagation()
        if (brands) {
            setSelectedBrands(tempSelectedBrands)
        } else {
            setSelectedRestaurant(tempSelected)
        }
        setOpen(false)
        setParams((prev: any) => ({ ...prev, page: 1 }))
    }

    const handleSelect = (e: any, r: any) => {
        e.stopPropagation();
        if (brands) {
            setTempSelectedBrands(prev => {
                let copy = [...prev]
                if (copy.some(b => b.id == r.id)) {
                    copy = copy.filter(b => b.id !== r.id)
                } else {
                    copy.push(r)
                }
                return copy
            })
        } else {
            setTempSelected({ id: r.id, name: r.name, brands: r.brands });
            setTempSelectedBrands([])
        }
    };

    const handleOpen = () => {
        if (disabled) {
            return
        }
        setOpen(true)
    }

    const getIcon = (r: any) => {

        let check = false
        if (brands) {
            if (tempSelectedBrands.some(b => b.id == r.id)) {
                check = true
            }
        } else if (tempSelected?.id === (r.id)) {
            check = true
        }

        return check ? <CirlceCheckIcon stroke="#C28831" /> : <MiniCircle />

    }

    useOnClickOutside(ref, () => setOpen(false))

    return <>
        <OrdersSectionHead ref={ref} onClick={handleOpen} >
            <H4 $mb="0" color="primary" $noTranform $bold $textSpacing="0">
                {name}
            </H4>
            {brands ? <StoreIcon /> : <LocationIcon />}
            {open && (
                <OrdersOptionsContainer
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{
                        type: 'spring',
                        bounce: 0.4,
                        duration: 0.8,
                    }}
                >
                    {!brands && <Input $width='36rem' onChange={handleQuery} $search placeholder="Search Restaurants" />}
                    <OptionsWrapper>
                        {!results.length ? <NoSelectItemsContainer > <img src={Restaurant} alt="" /> <H3 $center >No Brands</H3></NoSelectItemsContainer> : results?.map((r: any) => (
                            <SingleRestaurantOption key={r.id} onClick={(e) => handleSelect(e, r)}>
                                <Div $width="100%" $flex $between $alignCenter >
                                    <Div $height="100%" $flex $column $between>
                                        <H4 $bold $noTranform $textSpacing="0" $mb="0">
                                            {r.name}
                                        </H4>
                                        {r.address && <Disclaimer $mb="0">
                                            {r.address}
                                            <br />
                                            {r.phoneNumber}
                                        </Disclaimer>}
                                    </Div>
                                    {getIcon(r)}
                                </Div>

                            </SingleRestaurantOption>
                        ))}
                    </OptionsWrapper>
                    <Button $w="36rem" $selected $round onClick={handleConfirm}>CONFIRM</Button>
                </OrdersOptionsContainer>
            )}
        </OrdersSectionHead>

    </>
}
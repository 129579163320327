import { AnimatedDiv, Separator } from 'components';
import { Radio } from 'components/radio/radio';
import { formatter } from 'helpers';
import { apiInstance } from 'helpers/axiosHelper';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PRICING_START, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'routes';
import { A, Button, Disclaimer, Div, H1, H3, H4, P, PageWrapper } from 'styles';
import { StripeInputStyled } from './payment.styled';
import { PricingComponent } from './pricingComponent';
import { StripeForm } from './stripeForm';

import {
  loadStripe,
  StripeCardNumberElement,
  Token,
  TokenResult,
} from '@stripe/stripe-js';

import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { SpinnerFullscreen } from 'components/spinner/spinner';
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

export const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentPage />
    </Elements>
  );
};

export const PaymentPage = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [promoCodeDiscount, setPromoCodeDiscount] = useState<any>(null);
  const [applied, setApplied] = useState(false);
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');

  const [agree, setAgree] = useState(false);

  function percentage(num: number, per: number) {
    return (num / 100) * per;
  }

  const subscriptionPrice = 99;
  const amountOff = promoCodeDiscount?.amountOff / 100;
  const percentOff = promoCodeDiscount?.percentOff;
  const totalWithoutDiscount = subscriptionPrice;

  const percentageOffDiscount = percentage(subscriptionPrice, percentOff);

  const totalWithAmountOffDiscount = totalWithoutDiscount - amountOff;

  const totalWithPercentageOffDiscount =
    totalWithoutDiscount - percentageOffDiscount;

  const handlePromoCodeSubmit = async (e: any) => {
    e.preventDefault();
    if (!promoCode) {
      ErrorToast('Please enter Promo Code');
      return;
    }
    try {
      setLoading(true);
      const data = await apiInstance.get(`/v1/stripe/promo-code`, {
        params: {
          code: promoCode,
        },
      });

      setPromoCodeDiscount(data.data.data);
      setApplied(true);
      SuccessToast(data.data.message);
    } catch (e: any) {
      setPromoCodeDiscount(null);
      ErrorToast(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSubscribe = async (token: Token) => {
    const tokenId = token.id;

    if (!tokenId) return;

    try {
      const data = await apiInstance.post(`/v1/stripe/subscribe`, {
        token: tokenId,
        name,
        email,
        promotionCode: applied && promoCode ? promoCode : null,
      });

      SuccessToast(data.data.message);

      navigate(PRICING_START);
    } catch (e: any) {
      ErrorToast(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitStripe = async () => {
    if (!agree) {
      ErrorToast(
        'You must agree with Terms & Conditions and Privacy Policy to subscribe'
      );
      return;
    }

    if (!name || countWords(name) < 2) {
      ErrorToast('Please check your Full Name');
      return;
    }

    if (!email) {
      ErrorToast('Please check your Email');
      return;
    }

    setLoading(true);

    const cardNumberElement: StripeCardNumberElement | null | undefined =
      elements?.getElement('cardNumber');

    if (stripe && cardNumberElement) {
      const { token, error }: TokenResult = await stripe.createToken(
        cardNumberElement
      );

      if (error) {
        ErrorToast(error.message || '');
        setLoading(false);
      }

      if (token) {
        handlePaymentSubscribe(token);
      }
    }
  };

  function countWords(str1: string) {
    str1 = name;
    //exclude  start and end white-space
    str1 = str1.replace(/(^\s*)|(\s*$)/gi, '');
    //convert 2 or more spaces to 1
    str1 = str1.replace(/[ ]{2,}/gi, ' ');
    // exclude newline with a start spacing
    str1 = str1.replace(/\n /, '\n');

    return str1.split(' ').length;
  }

  return (
    <PageWrapper>
      <SpinnerFullscreen loading={loading} />

      <H4>get started</H4>
      <H1>
        <span> Payment </span>
      </H1>
      <Div $maxWidth='60rem'>
        <P>
          Discover the power of the Unlimited plan. To complete your purchase,
          please provide the following payment details.
        </P>
      </Div>
      <AnimatedDiv>
        <Div
          $flex
          $gap='6rem'
          $gapSM='4rem'
          $columnSM
          $alignCenter
          $mb='4rem'
          style={{ alignItems: 'stretch' }}
        >
          <PricingComponent />
          <StripeForm
            setName={setName}
            name={name}
            email={email}
            setEmail={setEmail}
          />
        </Div>
      </AnimatedDiv>

      <AnimatedDiv>
        <Div $justifyEnd $flex>
          <form onSubmit={handlePromoCodeSubmit}>
            <Div $flex $gap='2rem' $maxWidth='40rem' $maxWidthSM='100%'>
              <StripeInputStyled>
                <input
                  type='text'
                  placeholder='Promo Code'
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
              </StripeInputStyled>
              <Button
                $maxWidth='15rem'
                $maxWidthSM='100%'
                $selected={applied}
                type='submit'
              >
                {applied ? 'Applied' : 'Apply'}
              </Button>
            </Div>
          </form>
        </Div>
      </AnimatedDiv>

      <AnimatedDiv>
        <P $right $mb='2rem'>
          Subtotal: {formatter.format(subscriptionPrice)}
          <br />
          {promoCodeDiscount ? (
            <>
              Promo Code Discount:{' '}
              {amountOff ? (
                formatter.format(amountOff)
              ) : (
                <>
                  {formatter.format(percentageOffDiscount)}{' '}
                  <Disclaimer $mb='2rem'>
                    <span>
                      <b>({percentOff}% OFF)</b>
                    </span>
                  </Disclaimer>
                </>
              )}
            </>
          ) : null}
        </P>
      </AnimatedDiv>
      <AnimatedDiv>
        <H3 $right $mb='3.2rem'>
          Total:{' '}
          {promoCodeDiscount ? (
            <>
              {amountOff ? (
                <span>
                  {totalWithAmountOffDiscount > 0
                    ? formatter.format(totalWithAmountOffDiscount)
                    : formatter.format(0)}
                </span>
              ) : (
                <span>
                  {totalWithPercentageOffDiscount > 0
                    ? formatter.format(totalWithPercentageOffDiscount)
                    : formatter.format(0)}
                </span>
              )}
            </>
          ) : (
            <span>{formatter.format(totalWithoutDiscount)}</span>
          )}
        </H3>
      </AnimatedDiv>
      <AnimatedDiv>
        <Div $flex $alignCenter $justifyEnd $gap='16px' $mb='3.2rem'>
          <Radio checked={agree} setChecked={() => setAgree(!agree)} />
          <Disclaimer $mb='0' $right>
            I agree with
            <A to={TERMS_AND_CONDITIONS}>
              <span> Terms & Conditions </span>
            </A>
            and
            <A to={PRIVACY_POLICY}>
              <span> Privacy Policy. </span>
            </A>
          </Disclaimer>
        </Div>
      </AnimatedDiv>
      <AnimatedDiv>
        <Div $flex $alignCenter $justifyEnd>
          <Button
            $w='100%'
            $maxWidth='30rem'
            $maxWidthSM='100%'
            onClick={handleSubmitStripe}
          >
            Subscribe & Schedule a Call
          </Button>
        </Div>
      </AnimatedDiv>
      <Separator />
    </PageWrapper>
  );
};

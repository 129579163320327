import DoorDashLogo from '../../assets/images//logos/DoorDashLogo.png'
import GrubHubLogo from '../../assets/images//logos/GrubHubLogo.png'
import UberEatsLogo from '../../assets/images//logos/UberEatsLogo.png'



export const providersInitialState:IProvider[]  = [
    {
        provider:'ubereats',
        name:'UberEats',
        isConnected:false,
        logo:UberEatsLogo,
        config:null
    },
    {
    provider:'doordash',
    name:'DoorDash',
    isConnected:false,
    logo:DoorDashLogo,
    config:null
},
{
    provider:'grubhub',
    name:'GrubHub',
    isConnected:false,
    logo:GrubHubLogo,
    config:null
}
]

export interface IProvider{
    provider: 'doordash' | 'grubhub' | 'ubereats',
    name: 'DoorDash' | 'UberEats' | 'GrubHub',
    isConnected:boolean,
    logo:any
    config:{
        username:string
        password:string
        storeId?:string
    }
}
import { statuses } from "pagesPartner/Dashboard/data";
import {
    AreaTooltip,
    DashboardStatusButton,
    GraphTopWrapper,
    LogoCircleBig,
    StatusButtonsWrapper,
} from "pagesPartner/Dashboard/partnerDashboard.styled";
import { useEffect, useRef, useState } from "react";
import { Div, H3, P, Disclaimer, H2, Button, HR, H5, ContentWrapper, Overlay, overlayAnimationVariant, H1, H4 } from "styles";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    BrandCard,
    BrandHeader,
    BrandPictureContainer,
    BrandsCardContainer,
    RestaurantDetailsWrapper,
    RestaurantGraphContainer,
    RestaurantGraphWrapper,
    RestaurantMain,
    ViewRestaurantWrapper,
    VoidedCheckContainer,
} from "./restaurants.styled";
import GrubConLogo from "../../assets/images/logos/GrubConLogo.png";
import { useNavigate, useParams } from "react-router";
import { AdminDatePicker } from "components/DatePicker/AdminDatePicker";
import { EditIcon } from "assets";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { displayDateRange, getGraphAmount, getGraphStatusName, getGraphTotal, setTimeTo, getImage, monthStart } from "helpers";
import { IStatuses } from "pagesPartner/Dashboard/PartnerDashboard";
import { ConfigModal } from "./configModal";
import { useBrands } from "store/brands";

export const ViewBrand = () => {
    const [status, setStatus] = useState<IStatuses>("recovered");
    const [dates, setDates] = useState([setTimeTo(monthStart(), 'start'), setTimeTo(new Date(), 'end')]);
    const { singleBrand, loading, getSingleBrand, getBrandGraph, brandGraph, clearSingleBrand } = useBrands(state => state)
    const [modal, setModal] = useState(false)
    const { brandId, id } = useParams();
    const navigate = useNavigate();

    const renderButtons = () => {
        return statuses.map((s) => (
            <DashboardStatusButton
                active={status === s.value}
                //@ts-ignore
                onClick={() => setStatus(s.value)}
            >
                <p>{s.label}</p>
            </DashboardStatusButton>
        ));
    };

    // useEffect(() => {
    //     getSingleRestaurant(id)
    //     return () => {
    //         clearSingleRestaurant()
    //     }
    // }, [])

    useEffect(() => {
        getSingleBrand(brandId)
    }, [status, dates]);

    useEffect(() => {
        clearSingleBrand()
    }, [])


    useEffect(() => {
        getBrandGraph(id, brandId, status, dates)

    }, [status, dates]);

    const CustomYAxisTick = ({ x, y, payload }: any) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={-5} y={-13} dy={16} fontSize='1.5rem' textAnchor="end" fill="#666">
                    $ {payload.value}
                </text>
            </g>
        );
    };

    return (
        <ContentWrapper>
            <BrandHeader>
                <Div $flex $gap="2rem" $alignCenter >
                    <LogoCircleBig>
                        <img src={GrubConLogo} alt="" />
                    </LogoCircleBig>
                    <H3 $m="0" >{singleBrand?.name}</H3>
                </Div>

                <Button $w="25rem" onClick={() => navigate('providers?name=' + singleBrand?.name)} >MANAGE PROVIDERS</Button>
            </BrandHeader>
            <ViewRestaurantWrapper >
                <SpinnerFullscreen loading={loading} />

                <RestaurantDetailsWrapper $width="100%" >
                    <Div $flex $alignCenter $between>
                        <H3 $mb="0">Overview</H3>
                        <Div $flex $between $gap="1rem" $alignCenter>
                            <P $mb="0">{displayDateRange(dates)} </P>
                            <AdminDatePicker
                                range={true}
                                handleApply={(d: Date[]) => setDates(d)}
                                dt={dates}
                                click={() => 1}
                                dateFrom={new Date()}
                                dateTo={new Date()}
                            />
                        </Div>
                    </Div>
                    <StatusButtonsWrapper>
                        {renderButtons()}
                    </StatusButtonsWrapper>
                    <RestaurantGraphContainer>
                        <GraphTopWrapper>
                            <Div $flex $alignCenter $gap="2rem">
                                <H2 className="rest-graph" $mb="0" color="primary">
                                    {getGraphTotal(brandGraph)}
                                </H2>
                                <Disclaimer $mb="0">Total {getGraphStatusName(status)} </Disclaimer>
                            </Div>
                            <Div></Div>
                            <Div $flex $gap="2rem" $center>
                                <Disclaimer $mb="0" $right>
                                    Total Amount <br /> {getGraphStatusName(status)}:
                                </Disclaimer>
                                <H2 className="rest-graph" $mb="0" color="green">
                                    ${getGraphAmount(brandGraph)}
                                </H2>
                            </Div>
                        </GraphTopWrapper>
                        <RestaurantGraphWrapper>
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    data={brandGraph}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: window.innerWidth < 800 ? -30 : 0,
                                        bottom: 0,
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="uberEats" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#05C637" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#05C637" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="doorDash" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#FF2F09" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#FF2F09" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="grubHub" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#FC8800" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#FC8800" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>

                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" fontSize="1.5rem" interval='equidistantPreserveStart' />
                                    <YAxis tickFormatter={(value) => `$${value}`} tick={<CustomYAxisTick />} fontSize='1.5rem' />
                                    <Tooltip
                                        formatter={() => ""}
                                        wrapperStyle={{ outline: "none" }}
                                        content={(props) => {
                                            const uberEats = props.payload?.find(
                                                (p) => p.name === "uberEats"
                                            );
                                            const doorDash = props.payload?.find(
                                                (p) => p.name === "doorDash"
                                            );
                                            const grubHub = props.payload?.find(
                                                (p) => p.name === "grubHub"
                                            );
                                            return (
                                                <AreaTooltip>
                                                    <div className="time">
                                                        <p>{props?.label}</p>
                                                    </div>
                                                    <Div $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Door Dash: </P>
                                                        <P $m="0" style={{ color: "#FF2F09" }}>
                                                            ${doorDash?.value}
                                                        </P>
                                                    </Div>
                                                    <Div $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Grub Hub: </P>
                                                        <P $m="0" style={{ color: "#FC8800" }}>
                                                            ${grubHub?.value}
                                                        </P>
                                                    </Div>
                                                    <Div $mb="2.5rem" $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Uber Eats :</P>
                                                        <P $m="0" style={{ color: "#05C637" }}>
                                                            ${uberEats?.value}
                                                        </P>
                                                    </Div>
                                                </AreaTooltip>
                                            );
                                        }}
                                    />

                                    <Area
                                        type="monotone"
                                        dataKey="doorDash"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#doorDash)"
                                        fillOpacity="1"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="uberEats"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#uberEats)"
                                        fillOpacity=".8"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="grubHub"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#grubHub)"
                                        fillOpacity=".8"
                                    />
                                </AreaChart>

                            </ResponsiveContainer>
                        </RestaurantGraphWrapper>
                    </RestaurantGraphContainer>
                </RestaurantDetailsWrapper>
                {modal && <>
                    <ConfigModal handleClose={() => setModal(false)} />
                    <Overlay
                        key="modalOverlay"
                        variants={overlayAnimationVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    /></>}
            </ViewRestaurantWrapper>
        </ContentWrapper>
    );
};

import styled from "styled-components"

export const QRBox = styled.div`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    display: flex;
    align-items: center;
    justify-content: center;
    height:25rem;
    width: 25rem;
    padding: 2rem;


`
import api from "api";
import { SuccessToast } from "components/Toasts/Toasts";
import { handleError } from "helpers";
import { IRestaurant } from "pagesCMS/adminRestaurants";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface UsersState {
    loading: boolean;
    loadedUsers:boolean,
    getUsers:(params:{ page: number; limit: number,deactivated?:boolean, query?:string }, renew?:boolean)=>void
    users:IGetUser[]
    singleUser:ISingleUser
    getSingleUser:(id:string)=>void
    getLoggedUserLocations:()=>void
    createUser:(user:ISingleUser)=>Promise<void>
    updateUser:(user:ISingleUser, id:string)=>Promise<void>
    deleteUser:(id:string)=>Promise<void>
    clearSingleUser:()=>void
    clearUsers:()=>void
    setFetchedUserLocations:(ids:number[])=>void
    loggedUserLocations:{name:string,id:number,address:string}[]
    fetchedUserLocations:number[]

}

export const useUsers = create<UsersState>()(
  devtools(
      (set) => ({
        users:[],
        singleUser:null,
        loading:false,
        loadedUsers:false,
        getUsers:async(params:{ page: number; limit: number,deactivated?:boolean, query?:string }, renew?:boolean) =>{
            try {
                set({ loading: true });
                if(!params.query){
                  delete params.query
                }
                const {data} = await api.get("v1/users",{params});
                set(state=>({loading: false, users: renew ? data.data : [...state.users, ...data.data], loadedUsers:true}));
              } catch (error) {
                handleError(error);
                set({ loading: false });
              }
        },
        createUser:async(user:ISingleUser) =>{
            try {
                set({ loading: true });
                const {data} = await api.post("v1/users", user);
                set(state=>({loading:false,users:[...state.users, {user:data.data, locationIds:user.locationIds}]}))
                SuccessToast('User successfully added.')

              } catch (error) {
                handleError(error);
                set({ loading: false });
              }
        },
        updateUser:async(user:ISingleUser,id:string) =>{
          try {
              delete user.id
              set({ loading: true });
              const {data} = await api.patch("v1/users/"+id, user);
              set({loading: false, singleUser:data.data, fetchedUserLocations:user.locationIds});
              SuccessToast('User successfully updated.')

            } catch (error) {
              handleError(error);
              set({ loading: false });
            }
      },
        getSingleUser:async(id:string)=>{
            try {
                set({ loading: true });
                const {data}:{data:{data:IGetSingleUser}} = await api.get("v1/users/"+id);
                set({loading: false, singleUser:{
                  firstName:data.data.firstName,
                  lastName:data.data.lastName,
                  phoneNumber:data.data.phoneNumber,
                  email:data.data.email,
                  role:data.data.role,
                  locationIds:[]
                }});
              } catch (error) {
                handleError(error);
                set({ loading: false });
              }
        },
        getLoggedUserLocations:async()=>{
          try {
            set({ loading: true });
            const {data}:{data:{data:any}} = await api.get("v1/locations",{params:{verified:true, page:1,limit:99999}});
            set({loading: false, loggedUserLocations:data.data.map(({location}:{location:IRestaurant})=>({name:location.name, id:location.id, address:location.address}))});
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
      },
        deleteUser:async(id:string)=>{
          try {
              set({ loading: true });
              await api.delete("v1/users/"+id);
              set({loading: false});
              SuccessToast('User successfully deleted.')
            } catch (error) {
              handleError(error);
              set({ loading: false });
            }
      },
      fetchedUserLocations:[],
        clearSingleUser:()=>set({singleUser:null, fetchedUserLocations:[], loadedUsers:false}),
        clearUsers:()=>set({users:[]}),
        setFetchedUserLocations:(ids:number[])=>set({fetchedUserLocations:ids}),
        loggedUserLocations:[],
      }),
      { name: "grubcon-users" }
    )
);


export interface ISingleUser{
  id?:number
    firstName:string
    lastName:string
    phoneNumber:string
    role:string
    email:string
    locationIds:number[]
}

export interface IGetUser{
    user: ISingleUser
locationIds: number[]
}

export interface IGetSingleUser{
    id: number,
    phoneNumber:string
    email: string
    firstName: string
    lastName: string
    name: string
    deleted: boolean
    role: string
    completed: boolean
    termsAccepted: boolean
    notificationsEnabled: boolean
    createdAt: string
    updatedAt: string
}
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { CMS_DASHBOARD, CMS_ROOT } from 'routes/routeConstantsCMS';
import { PARTNER_DASHBOARD, PARTNER_ROOT } from 'routes/routeConstantsPartner';
import { SALES_HOME, SALES_ROOT } from 'routes/routeConstantsSales';
import styled from 'styled-components';
import { isAdmin, isPartner, isSales } from '../../../helpers';
import { AdminHeader } from './AdminHeader';
import { AdminSidebar } from './AdminSidebar';

const AdminLayoutStyled = styled.div`
  display: flex;
  padding: 12.4rem 2.4rem 2.4rem 34.8rem;

  gap: 4rem;
  min-height: 100vh;
  /* max-height: 100vh; */

  @media (max-width: 1450px) {
    padding: 12.4rem 2.4rem 2.4rem 31.8rem;
  }

  @media (max-width: 800px) {
    padding: 11.4rem 2.4rem 2.4rem 2.4rem;
    min-height: 100vh;
    max-height: auto;
  }
`;

const ContentWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const ContentStyled = styled.div`
  width: 100%;
  /* height: calc(100vh - 16.8rem); */
  /* max-height: calc(100vh - 16.8rem); */
  /* overflow: auto; */
  padding: 3.2rem;
  -webkit-overflow-scrolling: touch;

  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 800px) {
    padding: 2.4rem;
    /*   position: fixed;
    top: 11.4rem;
    bottom: 2.4rem;
    right: 2.4rem;
    left: 2.4rem; */
    /* height: auto; */
    /* width: calc(100% - 4.8rem); */
  }
`;

const ContentStyledPartner = styled.div`
  width: 100%;
  /* height: calc(100vh - 16.8rem); */
  /* max-height: calc(100vh - 16.8rem); */
  /* overflow: auto; */
  -webkit-overflow-scrolling: touch;

  @media (max-width: 800px) {
    padding: 0rem;
    /*   position: fixed;
    top: 11.4rem;
    bottom: 2.4rem;
    right: 2.4rem;
    left: 2.4rem; */
    /* height: auto; */
    /* width: calc(100% - 4.8rem); */
  }
`;

export const AdminLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === CMS_ROOT && isAdmin) navigate(CMS_DASHBOARD);
    if (location.pathname === PARTNER_ROOT && isPartner) navigate(PARTNER_DASHBOARD);
    if (location.pathname === SALES_ROOT && isSales) navigate(SALES_HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AdminLayoutStyled>
      <AdminSidebar
        sidebarIsOpen={sidebarIsOpen}
        setSidebarIsOpen={setSidebarIsOpen}
      />
      <ContentWrapperStyled>
        <AdminHeader
          sidebarIsOpen={sidebarIsOpen}
          setSidebarIsOpen={setSidebarIsOpen}
        />
        {isAdmin ? <ContentStyled>{children}</ContentStyled> : <ContentStyledPartner>{children}</ContentStyledPartner>}
      </ContentWrapperStyled>
    </AdminLayoutStyled>
  );
};

import { ArrowLeft, CirlceCheckIcon } from "assets"
import { useEffect, useState } from "react"
import { Button, Div, H3, H4, P, SeparatorBorderCMS, TextDisplay } from "styles"
import { IFindRestaurantTab } from "./data"
import { MiniCircle } from "./findRestaurants.styled"
import { useScript } from "@uidotdev/usehooks"
import { changeState } from "helpers"
import { ErrorToast } from "components/Toasts/Toasts"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { useAuth } from "store/auth"
import { useRestaurants } from "store/restaurants"
import { store } from "helpers/localstorage-helper"

export const SelectRestaurant = ({ state, setState, setActive }: IFindRestaurantTab) => {

    const scriptStatus = useScript(
        `https://maps.googleapis.com/maps/api/js?language=en&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
    )
    const [restaurants, setRestaurants] = useState<any>([])
    const { loading } = useAuth(state => state)
    const loadingRestaurants = useRestaurants(state => state.loading)
    const { createRestaurant } = useRestaurants(state => state)
    const [predictionsLoading, setPredictionsLoading] = useState(true)


    const handleNext = async () => {
        if (!state.selectedRestaurant) {
            ErrorToast("Please select a restaurant first.")
            return
        }
        try {
            const incompleteLocation = await createRestaurant(state.selectedRestaurant.place_id)
            if (incompleteLocation) {
                store('incompleteLocation', JSON.stringify(incompleteLocation))
            }
            setActive('confirm')
        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        if (scriptStatus === 'ready') {
            setPredictionsLoading(true)
            try {
                const service = new window.google.maps.places.AutocompleteService();
                service.getQueryPredictions({ input: state.searchQuery }, (predictions, status) => {
                    if (status === 'OK' && predictions) {
                        //@ts-ignore
                        setRestaurants(predictions.filter(p => p.place_id).map(p => ({ name: p.structured_formatting.main_text, address: p.structured_formatting.secondary_text, place_id: p.place_id })
                        ))
                        setPredictionsLoading(false)
                    }
                    setPredictionsLoading(false)
                });

            } catch (error) {
                console.log(error)
                setPredictionsLoading(false)

            }

        }

    }, [scriptStatus])

    console.log(loading)
    console.log(loadingRestaurants)
    console.log(predictionsLoading)

    const handleSelect = (restaurant: any) => {
        changeState(setState, 'selectedRestaurant', restaurant)
    }


    return <>
        <SpinnerFullscreen loading={loading || loadingRestaurants || predictionsLoading} />
        <Div $mb="3.4rem" $flex $gap="2rem" $alignCenter>
            <Div $flex $center $justifyCenter $cursor="pointer" onClick={() => setActive('search')} >
                <ArrowLeft />
            </Div>
            <H3 $mb="0" color="primary">
                Select Your Restaurant
            </H3>
        </Div>
        <SeparatorBorderCMS />
        <H4 $mt="2.3rem" $mb="1.5rem" $noTranform $bold $textSpacing="0">
            Step 2/3
        </H4>
        <TextDisplay> {state.searchQuery} </TextDisplay>
        <SeparatorBorderCMS $m="2rem 0" />
        {restaurants.map((r: any) => <Div $cursor="pointer" key={r.placeId} onClick={() => handleSelect(r)}>
            <Div $flex $width="100%" $between $alignCenter>
                <H4 $mb="0" $noTranform $bold $textSpacing="0">
                    {r.name}
                </H4>
                {state.selectedRestaurant?.name === r.name && state.selectedRestaurant?.address === r.address ? <CirlceCheckIcon stroke="#C28831" /> : <MiniCircle />}
            </Div>
            <P $mt="1rem" $mb="0" >{r.address}</P>
            <SeparatorBorderCMS $m="1rem 0" />
        </Div>)}
        <Button $mt="2rem" $w="100%" $round $selected onClick={handleNext}>NEXT</Button>
    </>
}
import { ArrowLeft } from 'assets';
import { convertPathToOriginalRouteWithId, getNameFromRoute, isAdmin, isPartner, isSales, pathname } from 'helpers';
import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CMS_GRUBBY, routesCMS } from 'routes/routeConstantsCMS';
import { routesPartner } from 'routes/routeConstantsPartner';
import { routesSales } from 'routes/routeConstantsSales';
import styled from 'styled-components';
import { MenuIcon } from '../../../components/navigation/menuIcon';
import { useAuth } from '../../../store/auth';
import { Button, Disclaimer, Div, H3, H5, P } from '../../../styles';

const AdminHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 8rem;
  align-items: center;
  gap: 5rem;
  border-bottom: ${({ theme }) => theme.borderRadius.br1};
  padding: 0 3.2rem;

  position: fixed;
  left: 34.8rem;
  top: 2.4rem;
  right: 2.4rem;
  z-index: 9;

  border-radius: ${({ theme }) => theme.borderRadius.br1};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 1450px) {
    left: 31.8rem;
  }

  @media (max-width: 800px) {
    padding: 0 3rem;
    position: fixed;
    top: 2.4rem;
    right: 2.4rem;
    left: 2.4rem;
    background-color: ${({ theme }) => theme.white};
    height: 6rem;
  }
`;

interface AdminHeaderType {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AdminHeader = ({
  sidebarIsOpen,
  setSidebarIsOpen,
}: AdminHeaderType) => {
  const location = useLocation();

  const { user, agent } = useAuth((state) => state);
  const navigate = useNavigate()
  const { id } = useParams()


  const getTitle = () => {

    const pathnameStartWith = (path: string) => location.pathname.startsWith(path) ? location.pathname : null;
    let title = { text: '', type: 'main' }
    let back = -1
    const routes = isPartner ? routesPartner : isSales ? routesSales : routesCMS
    if (getNameFromRoute(location.pathname).includes(' ') || getNameFromRoute(location.pathname).includes('-')) {
      if (location.pathname.includes('connect')) {
        if (location.pathname.includes('bank')) {
          title = { text: 'Connect Bank', type: 'sub' }
        } else {
          title = { text: 'Connect Provider', type: 'sub' }
        }
      } else {
        const foundRoute = routes.find(r => !r.name && convertPathToOriginalRouteWithId(location.pathname) === r.path)
        //@ts-ignore
        if (foundRoute?.backLink) {
          //@ts-ignore
          back = foundRoute.backLink + (foundRoute.backLink.includes('/') ? id : '')
        }
        //@ts-ignore
        title = { text: foundRoute?.subName, type: 'sub' }
      }
    } else {
      switch (location.pathname) {
        case pathnameStartWith(CMS_GRUBBY):
          title = { text: 'Grubby', type: 'main' };
          break
        default:
          title = { text: getNameFromRoute(location.pathname), type: 'main' }
      }
    }

    const handleBack = () => navigate(back)

    return <>
      <Div $desktop>
        {title.type === 'main' ? <H3 $m='0' color='primary'>{title.text}</H3> : <Div $pointer onClick={handleBack} $svgGray $flex $alignCenter $gap='2rem' ><ArrowLeft /><H3 $mb='0' >{title.text}</H3></Div>}
      </Div>
      <Div $mobile $mt='5'>
        {title.type === 'main' ? <H5 $m='0' color='primary' >{title.text}</H5> : <Div $pointer onClick={handleBack} $svgGray $flex $alignCenter $gap='2rem' ><ArrowLeft /><H5 $mb='0' >{title.text}</H5></Div>}

      </Div></>
  }

  const verifySection = () => {
    if (isSales) {
      if (!agent?.verificationSent && !agent?.verified) {
        return <Div $desktop ><Button onClick={() => navigate('verify-account')} $selected > VERIFY ACCOUNT</Button></Div>
      } else if (agent?.verificationSent && !agent?.verified) {
        return <Div $desktop ><Disclaimer $center $m='0 1rem' >Account is <br /> under review</Disclaimer></Div>
      }
    }
  }

  return (
    <AdminHeaderStyled>
      <Div $flex $gap='16px' $alignCenter>
        <Div $mobile>
          <MenuIcon
            isOpen={sidebarIsOpen}
            onClick={() => setSidebarIsOpen((prev) => !prev)}
          />
        </Div>
        {getTitle()}

      </Div>

      <Div $flex $gap='16px' $alignCenter >
        {verifySection()}
        <Div $flex $column $justifyEnd $gap='1rem' $gapSM='.5rem'>
          <Disclaimer $right $mb='0' $lineHeight='1'>
            {user.phoneNumber}
          </Disclaimer>
          <H5 $m='0' $right $lineHeight='1'>
            <span>
              {user?.firstName || ''} {user?.lastName || ''}
            </span>
          </H5>
        </Div>
      </Div>
    </AdminHeaderStyled>
  );
};

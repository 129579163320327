
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles';
import Global from './styles/global';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './styles/global';
import { App } from './App';
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the `BrowserTracing` integration
  // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: process.env.REACT_APP_IS_PRODUCTION ? 0.2 : 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
});



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <Global />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);

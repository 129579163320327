import { AnimatedDiv, Separator } from 'components';
import { Div, H1, H3, H4, P, PageWrapper } from 'styles';
import { PricingComponent } from './pricingComponent';

export const Pricing = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $gapSM='0' $columnSM $alignCenter>
        <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>pricing</H4>
            <H1>
              Try GRUBCON
              <span> Free </span> for <br /> the First 15 Days
            </H1>
            <Div $maxWidth='50rem'>
              <P>
                Our Help Desk and Revenue Recovery services are all included in
                this plan. To request any of these services à la carte, please
                mention this during your onboarding call.
              </P>
            </Div>
            <H3>Help Desk</H3>
            <P>
              Our Help Desk Team is available 24/7 over text to edit menus,
              opening hours, or anything else you need to make managing your
              delivery platforms as convenient and streamlined as possible.
            </P>
            <H3>Revenue Recovery</H3>
            <P>
              Our Revenue Recovery Team is trained on delivery platform policy
              to ensure that disputes and cancellations, which would have
              otherwise been lost revenue, end up being automatically resolved
              in your favor.
            </P>
          </AnimatedDiv>
        </Div>
        <Div
          $width={'50%'}
          $widthMD={'50%'}
          $widthSM={'100%'}
          $justifyEnd
          $flex
        >
          <AnimatedDiv>
            <PricingComponent type={1} />
          </AnimatedDiv>
        </Div>
      </Div>
      <Separator />
    </PageWrapper>
  );
};

import api from 'api';
import {  formatDataForGraph, getGraphType, handleError, setTimeTo } from 'helpers';
import { IGraphData } from 'pagesPartner/Dashboard/PartnerDashboard';
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface DashboardState {
 dashboardGraph:IGraphData[]
 getDashboardGraph:(status:string, dates:Date[],locationIds:number[], brandIds:number[]) => void,
 statistics:{amount:number,count:number},
 getStatistics:() => void
 loading:boolean
 setLoading: (data:boolean) =>void
}

export const useDashboard = create<DashboardState>()(
  devtools(
    persist(
      (set) => ({
        loading:false,
        dashboardGraph:[],
        getDashboardGraph:async(status:string,dates:Date[],locationIds:number[], brandIds:number[]) =>{
            try {
            set({loading:true})
                let params: any = {
                    status,
                    from: setTimeTo(dates[0],'start'),
                    to: setTimeTo(dates[1], 'end'),
                    groupBy: getGraphType(dates),
                    providers: ["grubhub", "doordash", "ubereats", "grubhubPos"],
                }

                if (locationIds.length) {
                    params.locationIds = locationIds
                }

                if (brandIds.length) {
                    params.brandIds = brandIds
                }

                const resp = await api.get("v1/orders/grubcon-dashboard", { params });
                set({loading:false,dashboardGraph:formatDataForGraph(resp.data.data, getGraphType(dates), dates[0])})
            } catch (error) {
                handleError(error);
                set({loading:false})
                console.log(error)
            }
        },
        statistics:{amount:0,count:0},
        getStatistics:async() =>{
            set({loading:true})
            try {
                const resp = await api.get("v1/orders/grubcon-statistics", {
                    params: { providers: ["grubhub", "doordash", "ubereats", "grubhubPos"], timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone },
                });

                set({loading:false,statistics:{
                    count: resp.data.data.count,
                    amount: Number((resp.data.data.amount / 100).toFixed(2)),
                }})

            } catch (error) {
                handleError(error);
                set({loading:false})
            }
        },
        setLoading:(data:boolean)=>{
            set({loading:data})
        }
      }),
      { name: 'grubcon-dashboard' }
    )
  )
);

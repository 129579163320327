import { LoginAdmin } from '../pagesCMS/Login/login.cms';
import { CartIcon, DashboardIcon, LocationIcon, SettingsIcon, UserIcon } from 'assets';
import { PartnerDashboard } from 'pagesPartner/Dashboard/PartnerDashboard';
import { Restaurants } from 'pagesPartner/Restaurants/restaurants';
import { ViewRestaurant } from 'pagesPartner/Restaurants/viewRestaurant';
import { Providers } from 'pagesPartner/Restaurants/providers';
import { ConnectProvider } from 'pagesPartner/Restaurants/connectProvider';
import { Orders } from 'pagesPartner/Orders/orders';
import { Settings } from 'pagesPartner/Settings/settings';
import { OtpAdmin } from 'pagesCMS/Otp/otp.cms';
import { FindRestaurant } from 'pagesPartner/FindRestaurant/findRestaurant';
import { WaitingVerification } from 'pagesPartner/FindRestaurant/waitingVerification';
import { UserInfo } from 'pagesPartner/Auth/userInfo';
import { DisconnectProvider } from 'pagesPartner/Restaurants/disconnectProvider';
import { TermsAndConditions } from 'pagesPartner/TermsAndConditions/termsAndConditions';
import { PrivacyPolicy } from 'pagesPartner/TermsAndConditions/privacyPolicy';
import { ViewBrand } from 'pagesPartner/Restaurants/viewBrand';
import { BrandProviders } from 'pagesPartner/Restaurants/brandProviders';
import { RestaurantOrders } from 'pagesPartner/Restaurants/restaurantOrders';
import { Users } from 'pagesPartner/Users/users';
import { SingleUser } from 'pagesPartner/Users/singleUser';
import { DeleteUser } from 'pagesPartner/Users/deleteUser';

export const PARTNER_ROOT = '/';
export const PARTNER_LOGIN = `${PARTNER_ROOT}login`;
export const PARTNER_OTP = `${PARTNER_ROOT}otp`;
export const PARTNER_USER_INFO = `${PARTNER_ROOT}user-info`;
export const PARTNER_DASHBOARD = `${PARTNER_ROOT}dashboard`;
export const PARTNER_FIND_RESTAURANT = `${PARTNER_ROOT}standalone-find-restaurant`;
export const PARTNER_WAIT_VERIFICATION = `${PARTNER_ROOT}standalone-waiting-verification`;
export const PARTNER_PRIVACY_POLICY = `${PARTNER_ROOT}portal-privacy-policy`;
export const PARTNER_TERMS = `${PARTNER_ROOT}portal-terms-and-conditions`;
export const PARTNER_RESTAURANTS = `${PARTNER_ROOT}restaurants`;
export const PARTNER_ADD_RESTAURANT = `${PARTNER_ROOT}restaurants/add/:id`;
export const PARTNER_VIEW_RESTAURANT = `${PARTNER_ROOT}restaurants/:id`;
export const PARTNER_RESTAURANTS_ORDERS = `${PARTNER_ROOT}restaurants/:id/orders`;
export const PARTNER_PROVIDERS = `${PARTNER_ROOT}restaurants/:id/providers`;
export const PARTNER_CONNECT_PROVIDER = `${PARTNER_ROOT}restaurants/:id/providers/connect/:providerName`;
export const PARTNER_DISCONNECT_PROVIDER = `${PARTNER_ROOT}restaurants/:id/providers/disconnect/:providerName`;
export const PARTNER_VIEW_BRAND = `${PARTNER_ROOT}restaurants/:id/brand/:brandId`;
export const PARTNER_BRAND_PROVIDERS = `${PARTNER_ROOT}restaurants/:id/brand/:brandId/providers`;
export const PARTNER_BRAND_CONNECT_PROVIDER = `${PARTNER_ROOT}restaurants/:id/brand/:brandId/providers/connect/:providerName`;
export const PARTNER_BRAND_DISCONNECT_PROVIDER = `${PARTNER_ROOT}restaurants/:id/brand/:brandId/providers/disconnect/:providerName`;
export const PARTNER_ORDERS = `${PARTNER_ROOT}orders`;
export const PARTNER_SETTINGS = `${PARTNER_ROOT}settings`;
export const PARTNER_USERS = `${PARTNER_ROOT}users`;
export const PARTNER_SINGLE_USER = `${PARTNER_ROOT}users/:id`;
export const PARTNER_DELETE_USER = `${PARTNER_ROOT}portal-delete-user/:id`;

export const authRoutesPartner = [
  {
    path: PARTNER_LOGIN,
    Page: LoginAdmin,
  },
  {
    path: PARTNER_OTP,
    Page: OtpAdmin,
  },
  {
    path: PARTNER_USER_INFO,
    Page: UserInfo,
  },
];

export const routesPartner = [
  {
    path: PARTNER_WAIT_VERIFICATION,
    Page: WaitingVerification,
    name: '',
    permissions: ['partner'],
    Icon: LocationIcon,
  },
  {
    path: PARTNER_DASHBOARD,
    Page: PartnerDashboard,
    name: 'Dashboard',
    permissions: ['partner','admin'],
    Icon: DashboardIcon,
  },
  {
    path: PARTNER_RESTAURANTS,
    Page: Restaurants,
    name: 'Restaurants',
    permissions: ['partner'],
    Icon: LocationIcon,
  },
  {
    path: PARTNER_FIND_RESTAURANT,
    Page: FindRestaurant,
    name: '',
    permissions: ['partner'],
    Icon: LocationIcon,
  },
  {
    path: PARTNER_VIEW_RESTAURANT,
    Page: ViewRestaurant,
    permissions: ['partner'],
    name:'',
    subName:'View Restaurant',
    Icon:LocationIcon,
    backLink:'restaurants'
  },
  {
    path: PARTNER_RESTAURANTS_ORDERS,
    Page: RestaurantOrders,
    permissions: ['partner'],
    name:'',
    subName:'Orders',
    Icon:LocationIcon,
  },
  {
    path: PARTNER_PROVIDERS,
    Page: Providers,
    permissions: ['partner'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
    backLink:'restaurants/'

  },
  {
    path: PARTNER_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['partner'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: PARTNER_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['partner'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: PARTNER_VIEW_BRAND,
    Page: ViewBrand,
    permissions: ['partner'],
    name:'',
    subName:'Brand Details',
    Icon:LocationIcon,
  },
  {
    path: PARTNER_BRAND_PROVIDERS,
    Page: Providers,
    permissions: ['partner'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
  },
  {
    path: PARTNER_BRAND_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['partner'],
    name:'',
    subName:'Connect Provider',
    Icon:LocationIcon,
  },
  {
    path: PARTNER_BRAND_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['partner'],
    name:'',
    subName:'Disonnect Provider',
    Icon:LocationIcon,
  },
  {
    path: PARTNER_ORDERS,
    Page: Orders,
    name: 'Orders',
    permissions: ['partner'],
    Icon: CartIcon,
  },
  {
    path: PARTNER_USERS,
    Page: Users,
    name: 'Users & Access',
    permissions: ['partner'],
    Icon: UserIcon,
  },
  {
    path: PARTNER_SINGLE_USER,
    Page: SingleUser,
    name: '',
    permissions: ['partner'],
    Icon: UserIcon,
  },
  {
    path: PARTNER_DELETE_USER,
    Page: DeleteUser,
    name: '',
    permissions: ['partner'],
    Icon: UserIcon,
  },
  {
    path: PARTNER_SETTINGS,
    Page: Settings,
    name: 'Settings',
    permissions: ['partner'],
    Icon: SettingsIcon,
  },
  {
    path: PARTNER_PRIVACY_POLICY,
    Page: PrivacyPolicy,
    name: '',
    permissions: ['partner'],
    Icon: SettingsIcon,
  },
  {
    path: PARTNER_TERMS,
    Page: TermsAndConditions,
    name: '',
    permissions: ['partner'],
    Icon: SettingsIcon,
  },
 
];

import React, { SetStateAction } from "react";

export const standardFieldValidation = (e:React.ChangeEvent<HTMLInputElement>, setInvalidFields:React.Dispatch<SetStateAction<string[]>>) =>{
    
    setInvalidFields(prev=>{
        let copy = [...prev]
        const {value,name} = e.target
        if(value.length<2){
            copy.push(name)
        }else{
            copy = copy.filter(f=>f!==name)
        }
        return copy
    })
   

}
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Disclaimer } from 'styles';


export const IconCircle = styled.div<{ $color?: string }>`

    min-width: 4.8rem;
    min-height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    cursor: pointer;
    ${({ $color, theme }) => 'background: ' + theme[$color] + ';'}
`


export const OrdersSectionHead = styled.div<{$active?:boolean,$noBorder?:boolean, $noShadow?:boolean}>`
    width: 100%;
    height: 5.5rem;
    padding: 0 3rem;
    ${({$noShadow,theme})=> !$noShadow && 'box-shadow:'+theme.shadows.shadow1+';'}
    ${({$noBorder,theme })=>!$noBorder && 'border: 1px solid'+ theme.secondary + 33 + ';' }
    background-color: ${({ theme,$active  }) => $active ? theme.primary : theme.white};  
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    cursor: pointer;
    transition: .2s ease-out;
    position: relative;

    *{
        margin: 0 !important;
    }

    .header-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        svg{
            fill: none;
            path{
                fill:none
            }
        }

    }

    svg{
        transition: .2s ease-out;
        width: 2rem;
        path{
            stroke:  ${({theme})=> theme.secondary}

            ${({$active})=>$active && 'fill:#fff; stroke:none'}
        }

        ${({$active})=>$active && 'transform:rotate(180deg);'}
    }

`
export const OrderSelectContainer = styled.div`
    width: 100%;
    max-height:150rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap:1rem;
    overflow: scroll;
    position: absolute;
    left:0;
    top:105%;


`

export const DateOrdersContainer = styled.div<{ $active: boolean }>`

    width: 100%;
    height: ${({ $active }) => $active ? 'max-content' : '5.5rem'};
    transition: .3s ease-out;

`


export const SingleDateOrder = styled.div<{ $red?: boolean }>`
    height: 14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.3rem;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    background-color: ${({ theme, $red }) => $red ? theme.paleRed : theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};


    .smallest{
        display: none;
    }

    @media (max-width: 800px) {
    /* width: calc(100vw - 60px); */
    height: 20rem;
  }
  @media (max-width: 410px) {
    height: 23rem;

    .smallest{
        display: block;
    }

    .normal{
        display: none;
    }
  }

`
export const LogoContainer = styled.div<{ $border?: boolean }>`
    width: 10rem;
    height: 100%;
    background-color: white;
    ${({ $border, theme }) => $border && 'border-right: 0.1px solid ' + theme.gray + ";"}
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $border, theme }) => !$border && 'border-radius: ' + theme.borderRadius.br1 + ';'}
    img{
        min-width: 6rem;
        object-fit: contain;
    }
`

export const StatusLabel = styled(Disclaimer) <{ $color: string, $desktop?: boolean, $mobile?: boolean }>`

    color:white;
    background-color: ${({ $color, theme }) => theme[$color]};
    padding: 0.5rem 1.5rem;
    border-radius:50px;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 800px) {
    width: max-content;
  }

    ${({ $desktop }) =>
        $desktop &&
        `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ $mobile }) =>
        $mobile &&
        `
    @media (min-width: 800px) {
    display: none;
  }
  
 

  `};

`

export const OrdersSelectsContainer = styled(motion.div)`

  display: flex;
  gap: 2rem;

  @media (max-width: 450px) {
    flex-direction: column;     
  }

`


export const OrdersOptionsContainer = styled(motion.div)`

    position: absolute;
    top: 105%;
    left: 0;
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    border-radius: 20px;
    z-index: 9999;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
     transform-origin: top left;
     padding: 2rem 0;
     align-items: center;

     @media (max-width: 800px) {
    /* width: calc(100vw - 60px); */
    position: fixed;
    top: 50% !important;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%) scale(1) !important;
  }
`

export const ResellerOptionsContainer = styled(OrdersOptionsContainer)`

  top: 50%;
  left:50%;
  transform: unset !important;
  transform: translate(-50%, -50%) !important;

`

export const OptionsWrapper = styled.div`

    width: 100%;
    max-height: 35rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 2rem 1rem;
    padding-bottom: 4rem;

`
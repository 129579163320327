import styled from "styled-components";

export const MiniCircle = styled.div`
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 50px;
    border: .1px solid ${({theme})=>theme.secondary};

    @media (max-width: 800px) {
        min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }


`
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ModalOverlay, ModalStyled } from './modal.styled';
import { theme } from 'styles';

interface ModalType {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  exitButton?: boolean;
}

const Modal = ({
  children,
  isOpen,
  setIsOpen,
  exitButton = true,
}: ModalType) => {
  const variants = {
    initial: {
      transform: 'translate(-50%, -40%)',
      opacity: 0,
      transition: {
        type: 'ease',
        duration: 0.1,
      },
    },
    animate: {
      transform: 'translate(-50%, -50%)',
      opacity: 1,
    },

    exit: {
      opacity: 0,
      transition: {
        type: 'ease',
        duration: 0.1,
      },
    },
  };

  const variants2 = {
    initial: {
      opacity: 0,
      transition: {
        type: 'ease',
        duration: 0.1,
      },
    },
    animate: {
      opacity: 1,
    },

    exit: {
      opacity: 0,
      transition: {
        type: 'ease',
        duration: 0.1,
      },
    },
  };

  return (
    <AnimatePresence mode='wait'>
      {isOpen && (
        <ModalStyled
          key='modal'
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {children}

          {exitButton && (
            <div
              onClick={() => setIsOpen(false)}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
              }}
            >
              <svg
                width='18'
                height='19'
                viewBox='0 0 18 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='redSvgHover'
              >
                <path
                  d='M17.4716 3.6638C18.1747 2.94469 18.1747 1.77687 17.4716 1.05776C16.7684 0.338656 15.6266 0.338656 14.9234 1.05776L9.00031 7.12126L3.07156 1.06351C2.36844 0.344409 1.22656 0.344409 0.523437 1.06351C-0.179687 1.78262 -0.179687 2.95045 0.523437 3.66955L6.45219 9.72729L0.529062 15.7908C-0.174063 16.5099 -0.174063 17.6777 0.529062 18.3968C1.23219 19.1159 2.37406 19.1159 3.07719 18.3968L9.00031 12.3333L14.9291 18.3911C15.6322 19.1102 16.7741 19.1102 17.4772 18.3911C18.1803 17.672 18.1803 16.5041 17.4772 15.785L11.5484 9.72729L17.4716 3.6638Z'
                  fill={theme.secondary}
                />
              </svg>
            </div>
          )}
        </ModalStyled>
      )}
      {isOpen && (
        <ModalOverlay
          key='modalOverlay'
          variants={variants2}
          initial='initial'
          animate='animate'
          exit='exit'
          onClick={() => setIsOpen(false)}
        />
      )}
    </AnimatePresence>
  );
};

export default Modal;

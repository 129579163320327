import { CloseIcon } from "assets"
import { Range } from "components/Range"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { AnimatePresence } from "framer-motion"
import { isAdmin } from "helpers"
import React, { useState } from "react"
import { useParams } from "react-router"
import { useAgents } from "store/agents"
import { useRestaurants } from "store/restaurants"
import { Button, Div, H1, H3, SeparatorBorderCMS } from "styles"
import { ProvidersModalContainer } from "./restaurants.styled"

export const PercentageModal = ({ handleClose, percentage, agentId }: { handleClose: any, percentage: number, agentId?: number }) => {

    const { updatePercentage, singleRestaurant, loading, updateSingleRestaurant } = useRestaurants(state => state)
    const { updateAgentPercentage } = useAgents(state => state)
    const [value, setValue] = useState(percentage || (agentId ? 5 : 35))
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number(e.target.value))
    };
    const { id } = useParams()

    const handleConfirm = async () => {


        if (agentId) {
            await updateAgentPercentage(agentId, value)
            handleClose()
            return
        }

        if (isAdmin) {
            await updateSingleRestaurant(Number(id), null, value)
        } else {
            await updatePercentage(singleRestaurant.id, value)
        }
        handleClose()
    }

    return <AnimatePresence>
        <ProvidersModalContainer $maxWidth="90vw" $width="50rem" >
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    {agentId ? 'Add Deal Percentage' : 'Update Retail Price'}
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <SeparatorBorderCMS />
            <Div $mt="3rem" $mb="3rem" $flex $column $justifyCenter $alignCenter $gap="3rem" $width="100%">
                <H1 $mb="0" $color="primary" >{value} %</H1>
                <Range max={!agentId ? 35 : 10} value={value} min={agentId ? 0 : 20} onChange={onChange} defaultValue={value} />
            </Div>
            <Div $flex $gap="1rem" >
                <Button $mt="2rem" $round $selected $w="100% " onClick={handleConfirm}>
                    CONFIRM
                </Button>

                <Button $mt="2rem" $round $secondary $w="100% " onClick={handleClose}>
                    CANCEL
                </Button>
            </Div>

        </ProvidersModalContainer>
    </AnimatePresence>
}

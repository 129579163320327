import { Button, ContentWrapper, H3 } from "styles";
import { BigLogoCircle } from "./restaurants.styled";
import DoorDashLogo from "../../assets/images//logos/DoorDashLogo.png";
import GrubHubLogo from "../../assets/images//logos/GrubHubLogo.png";
import UberEatsLogo from "../../assets/images//logos/UberEatsLogo.png";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { useRestaurants } from "store/restaurants";

export const DisconnectProvider = () => {
    const [provider, setProvider] = useState({
        name: "UberEats",
        logo: UberEatsLogo,
    });
    const { providerName, id } = useParams();
    const { disconnectProvider, loading } = useRestaurants(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        let obj = { ...provider };
        switch (providerName) {
            case "grubhub":
                obj = { name: "GrubHub", logo: GrubHubLogo };
                break;
            case "doordash":
                obj = { name: "Doordash", logo: DoorDashLogo };
                break;
            default:
                obj = { name: "UberEats", logo: UberEatsLogo };
        }
        setProvider(obj);
    }, []);


    const handleConnect = async () => {
        await disconnectProvider(providerName, id)
        navigate(-1)
    };



    return (
        <ContentWrapper $relative $p="0" $flex $column $alignCenter $center>
            <SpinnerFullscreen loading={loading} />
            <BigLogoCircle>
                <img src={provider.logo} alt="" />
            </BigLogoCircle>
            <H3 $mt="5rem" $mb="4rem" $center>
                Click on the button to disconnect <br /> {provider.name} from our app
            </H3>
            <Button $round $w="30rem" onClick={handleConnect}>
                DISCONNECT
            </Button>
        </ContentWrapper>
    );
};

import { SpinnerFullscreen } from "components/spinner/spinner"
import { HomeSection } from "pagesSales/Home/home.styled"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useUsers } from "store/users"
import { Button, ContentWrapper, Div, H3, H4, HR, P, PageDateContainer } from "styles"
import { UsersModal } from "./usersModal"

export const SingleUser = () => {

    const [modal, setModal] = useState(false)
    const { id } = useParams()
    const { loading, getSingleUser, singleUser, clearSingleUser, getLoggedUserLocations, loggedUserLocations, fetchedUserLocations } = useUsers(state => state)

    const locations = loggedUserLocations.filter(l => fetchedUserLocations.includes(l.id))
    const navigate = useNavigate()

    useEffect(() => {
        getSingleUser(id)
        getLoggedUserLocations()

        return (() =>
            clearSingleUser()
        )
    }, [])

    return <ContentWrapper>
        <SpinnerFullscreen loading={loading} />
        <PageDateContainer $noPadding >
            <H3 $mb="0" color="primary" >{singleUser?.firstName} {singleUser?.lastName}</H3>
            <Div $flex $gap="2rem" >
                <Button onClick={() => setModal(true)} >EDIT USER</Button>
                <Button $secondary onClick={() => navigate('/portal-delete-user/' + id)} >DELETE USER</Button>
            </Div>
        </PageDateContainer>
        <Div $mt="3rem">
            <HR />
            <H4 $mt="1rem" $mb="0" $noTranform $textSpacing="0" $bold >Email:</H4>
            <P $mb="1rem">{singleUser?.email}</P>
            <HR />
            <H4 $mt="1rem" $mb="0" $noTranform $textSpacing="0" $bold >Phone:</H4>
            <P $mb="1rem">{singleUser?.phoneNumber}</P>
            <HR />
            <H4 $mt="1rem" $mb="0" $noTranform $textSpacing="0" $bold >Role:</H4>
            <P $mb="1rem">{singleUser?.role == 'partner' ? 'Partner' : 'Manager'}</P>
            <HR />
        </Div>
        <Div $mt="2rem">
            <H4 $textSpacing="0" $bold color="primary" $noTranform>Locations Permmisions</H4>
            <Div $flex $column $gap="1rem" >
                {locations.length ?
                    locations.map(l => <HomeSection onClick={() => navigate('/restaurants/' + l.id)} key={l.id} >
                        <H4 $noTranform $textSpacing="0" $bold $mb="0">
                            {l.name}
                        </H4>
                        <P $mb="0" >{l.address}</P>
                    </HomeSection>) : <H3>No Locations</H3>
                }
            </Div>
        </Div>
        {modal && <UsersModal handleClose={() => setModal(false)} />}
    </ContentWrapper>
}
import { read } from "helpers/localstorage-helper"
import { useEffect, useState } from "react"
import { useAuth } from "store/auth"
import { ConfirmRestaurant } from "./confirmRestaurant"
import { IConfrimState } from "./data"
import { SearchRestaurant } from "./searchRestaurant"
import { SelectRestaurant } from "./selectRestaurant"

export const FindRestaurant = () => {
    const incompleteLocation = JSON.parse(read('incompleteLocation'))

    const [active, setActive] = useState<RestaurantTabs>(incompleteLocation ? 'confirm' : 'search')
    const [state, setState] = useState<IFindRestaurantState>({
        confirmData: null,
        selectedRestaurant: null,
        searchQuery: '',
        manualInput: false,
    })

    useEffect(() => {

        if (incompleteLocation) {
            setActive('confirm')
        }
    }, [])


    const renderContent = () => {
        switch (active) {
            case 'search':
                return <SearchRestaurant state={state} setActive={setActive} setState={setState} />
            case 'select':
                return <SelectRestaurant state={state} setActive={setActive} setState={setState} />
            case 'confirm':
                return <ConfirmRestaurant state={state} setActive={setActive} setState={setState} />
        }
    }

    return <>

        {renderContent()}

    </>
}

export interface IFindRestaurantState {
    confirmData: IConfrimState | null
    selectedRestaurant: {
        name: string
        address: number
        place_id: number
    } | null
    searchQuery: string
    manualInput: boolean
}

export type RestaurantTabs = 'select' | 'search' | 'confirm' 
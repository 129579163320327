import { SpinnerFullscreen } from "components/spinner/spinner";
import { DashboardStatusButton } from "pagesPartner/Dashboard/partnerDashboard.styled";
import { BigLogoCircle } from "pagesPartner/Restaurants/restaurants.styled";
import { HomeSection } from "pagesSales/Home/home.styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { SALES_INVITE } from "routes/routeConstantsSales";
import { useAgents } from "store/agents";
import { Button, ContentWrapper, Disclaimer, Div, H3, H4, P } from "styles";
import GrubConLogoBig from "../../assets/images/logos/GrubConLogoBig.png";
import { NoAgentsContainer } from "./agents.styled";

export const Agents = () => {
    const [status, setStatus] = useState("ACTIVE");
    const { loading, agents, getAgents, clearAgents, loadedAgents } = useAgents((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        getAgents({ page: 1, limit: 10, deactivated: status !== 'ACTIVE' });
    }, [status]);

    useEffect(() => {
        return (() => {
            clearAgents()
        })
    }, [])

    const handleStatus = (status: string) => {
        clearAgents()
        setStatus(status)
    }

    return (
        <ContentWrapper>
            <Div $mb="2rem" $flex $gap="1rem" $width="100%" $justifyEnd >
                <DashboardStatusButton
                    active={status === "ACTIVE"}
                    onClick={() => handleStatus("ACTIVE")}
                >
                    <p>ACTIVE</p>
                </DashboardStatusButton>
                <DashboardStatusButton
                    active={status === "INACTIVE"}
                    onClick={() => handleStatus("INACTIVE")}
                >
                    <p>INACTIVE</p>
                </DashboardStatusButton>
            </Div>
            <SpinnerFullscreen loading={loading} />
            {(loadedAgents && agents.length < 1) ? <NoAgentsContainer >
                <BigLogoCircle>
                    <img src={GrubConLogoBig} alt="" />
                </BigLogoCircle>
                <H3 $mb="0" $center>
                    You don’t have any
                    <br />  {status !== 'ACTIVE' ? 'inactive' : 'active'} sales agents yet.
                </H3>
                {
                    status == 'ACTIVE' && <>
                        {/* <P $mb="0" $center>
                        You get a bounus out of your direct agents<br /> signups. Become a sales manager by having <br /> 10 qualified agents.
                    </P> */}
                        <Button
                            $round
                            $w="35rem"
                            onClick={() => navigate(SALES_INVITE + '?type=agents')}
                        >
                            INVITE AGENTS
                        </Button></>}
            </NoAgentsContainer> : <><Div $flex $column $gap="1rem">
                {agents.map((a) => (
                    <HomeSection key={a.id} onClick={() => navigate('/agent-details/' + a.id)}>
                        <H4 $right $noTranform $textSpacing="0" $bold $mb="0">
                            {a.firstName} {a.lastName}
                        </H4>
                        <Div $flex $column $height="100%" $justifyCenter $alignEnd>
                            <H4 $noTranform $textSpacing="0" $bold $mb="0" color="primary">
                                {a.signedLocationsCount}
                            </H4>
                            <Disclaimer $mb="0">Signups</Disclaimer>
                        </Div>
                    </HomeSection>
                ))}
            </Div>
                {agents.length > 0 && <Div $mt="3.5rem" $flex $alignCenter $justifyEnd $width="100%">
                    <Button
                        $round
                        $selected
                        $w="30rem"
                        onClick={() => navigate(SALES_INVITE + '?type=agents')}
                    >
                        INVITE AGENTS
                    </Button>
                </Div>}</>}


        </ContentWrapper>
    );
};

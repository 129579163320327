import api from "api";
import { Input } from "components/input/Input";
import { Radio } from "components/radio/radio";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { ErrorToast } from "components/Toasts/Toasts";
import { changeState, handleError } from "helpers";
import { userInfoInitialState } from "pagesPartner/FindRestaurant/data";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { PARTNER_PRIVACY_POLICY, PARTNER_TERMS } from "routes/routeConstantsPartner";
import { SALES_ACC_CREATED } from "routes/routeConstantsSales";
import { useAuth } from "store/auth";
import { Button, Disclaimer, Div, H3, H4, SeparatorBorderCMS } from "styles";

export const UserInfoSales = () => {
    const [localState, setLocalState] = useState(
        userInfoInitialState
    );
    const { updateAuthState } = useAuth(state => state)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        changeState(setLocalState, name, value);
    };

    const handleNext = async () => {
        if (!localState.confirmed) {
            ErrorToast("You must agree to terms and conditions");
            return;
        } else if (
            !localState.firstName ||
            !localState.lastName ||
            !localState.email
        ) {
            ErrorToast("You must fill all fields.");
            return;
        }
        const forDispatch = { ...localState }
        delete forDispatch.confirmed
        setLoading(true);
        try {
            const resp = await api.put('v1/users/complete', { ...forDispatch })
            if (resp.status === 200) {
                setLoading(false);
                navigate(SALES_ACC_CREATED)
                updateAuthState({ user: resp.data.data }, true)
            }
        } catch (error) {
            setLoading(false);
            handleError(error)
        }

    };

    return (
        <>
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3.4rem" $flex $gap="2rem" $alignCenter>
                <H3 $mb="0" color="primary">
                    User Info
                </H3>
            </Div>
            <SeparatorBorderCMS />
            <H4 $mt="2.3rem" $mb="1.5rem" $noTranform $bold $textSpacing="0">
                Complete Profile
            </H4>
            <Div $mb="2rem" $flex $column $gap="2rem" $width="100%  ">
                <Input
                    label="First name"
                    defaultValue={localState.firstName}
                    onChange={onChange}
                    name="firstName"
                    placeholder="Enter here"
                />
                <Input
                    label="Last name"
                    defaultValue={localState.lastName}
                    onChange={onChange}
                    name="lastName"
                    placeholder="Enter here"
                />
                <Input
                    label="Email"
                    defaultValue={localState.email}
                    type="email"
                    onChange={onChange}
                    name="email"
                    placeholder="Enter here"
                />
            </Div>
            <Div $flex $gap="2rem">
                <Div $height="100%">
                    <Radio
                        checked={localState.confirmed}
                        setChecked={() =>
                            changeState(setLocalState, "confirmed", !localState.confirmed)
                        }
                    />
                </Div>
                <Disclaimer $spanPointer >
                    By checking this you agree to our
                    <span onClick={() => navigate(PARTNER_TERMS)} > Terms & Conditions </span>
                    and
                    <span onClick={() => navigate(PARTNER_PRIVACY_POLICY)} > Privacy Policy </span>
                    {/* as well as our partner
                    <a href="https://www.dwolla.com/legal/dwolla-account-terms-of-service" >
                        <span  > Dwolla’s Terms & Conditions </span>
                    </a>
                    and <br />
                    <a href="https://www.dwolla.com/legal/privacy" >
                        <span> Privacy Policy </span>
                    </a> */}
                    .
                </Disclaimer>
            </Div>
            <Button $round $selected $w="100% " onClick={handleNext}>
                NEXT
            </Button>
        </>
    );
};

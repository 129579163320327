import styled from "styled-components";

export const InputIconContainer = styled.div`

    position: absolute;
    top:50%;
    right: 2rem;
    transform: translateY(-50%);

    svg{
        height: 16px;
    }

`

export const InputLabel = styled.p<{$mb?:string}>`

    font-size: 1.2rem;
    color:${({theme})=>theme.secondary};
    margin-bottom: ${({$mb})=>$mb ?? '1rem'};
    margin-top: 0;
    width: 100%;
    padding-left: 2rem;
`
export const InputContainer = styled.div<{$responsive?:boolean}>`

position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
 
${({$responsive})=> $responsive && ' @media (max-width: 800px) { width:100% !important;}'}

`
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { variants } from 'styles';



export const DropdownStyled = styled(motion.div)`
  /* transform-origin: top left; */
  /* width: 350px; */
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: ${({ theme }) => theme.white};
  border: 1px solid #f0f0f0;
  position: absolute;
  ${({ $toLeft }) => ($toLeft ? 'right: 0px;' : 'left: 0px;')}
  transform-origin: ${({ $toLeft }) => ($toLeft ? 'top right' : 'top left')};
  top: 60px;
  overflow: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 3500px) {
    width: 700px;
    top: 120px;
  }

  @media (max-width: 1450px) {
    /* padding: 15px 25px; */
  }

  @media (max-width: 800px) {
    /* width: calc(100vw - 60px); */
    position: fixed;
    top: 50% !important;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%) scale(1) !important;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    width: 350px;
  }

  max-height: calc(100vh - 100px);
  ${({ $maxHeight }) => `max-height: ${$maxHeight}`};
  ${({ $padding }) => `padding: ${$padding}`};
  ${({ $fullWidth }) => $fullWidth && `right: 0;`};

  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DropdownMenuComponent = ({
  children,
  isOpen,
  maxHeight,
  padding,
  fullWidth,
  toLeft,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key="DropdownMenu"
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: 'spring',
            bounce: 0.4,
            duration: 0.8,
          }}
          $maxHeight={maxHeight}
          $padding={padding}
          $fullWidth={fullWidth}
          $toLeft={toLeft}
        >
          {children}
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};

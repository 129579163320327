import { ToastOptions, cssTransition, toast } from 'react-toastify';
import { theme } from 'styles';
import { ToastError, ToastSuccess, X } from '../../assets';
import { IconMessageWrap, IconWrap } from './ToastsStyled';

const move = cssTransition({
  enter: 'move-in',
  exit: 'move-out',
});

const toastConfig: ToastOptions<{}> = {
  icon: false,
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 5000,
  position: 'top-center',
  draggable: true,
  closeOnClick: true,
  closeButton: <X fill={theme.secondary} />,
  transition: move,
};

export const SuccessToast = (successMessage: string) => {
  toast.success(
    <SuccessToastComponent successMessage={successMessage ?? 'Success'} />,
    toastConfig
  );
};

export const ErrorToast = (errorMessage: string) => {
  toast.error(
    <ErrorToastComponent errorMessage={errorMessage ?? 'Error'} />,
    toastConfig
  );
};

const SuccessToastComponent = ({
  successMessage,
}: {
  successMessage: string;
}) => (
  <>
    <IconMessageWrap>
      <IconWrap>
        <ToastSuccess />
      </IconWrap>
      <div>
        <h5>Success</h5>
        <p>{successMessage}</p>
      </div>
    </IconMessageWrap>
  </>
);

const ErrorToastComponent = ({ errorMessage }: { errorMessage: string }) => (
  <>
    <IconMessageWrap>
      <IconWrap>
        <ToastError />
      </IconWrap>
      <div>
        <h5>Error</h5>
        <p>{errorMessage}</p>
      </div>
    </IconMessageWrap>
  </>
);

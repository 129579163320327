import { motion } from 'framer-motion';
import { LogoCircle } from 'pagesPartner/Dashboard/partnerDashboard.styled';
import styled from 'styled-components';
import { Div } from 'styles';

export const RestaurantCardsGrid = styled.div`

    gap:2rem;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */

    @media (max-width: 800px) {
        
        display: flex;
        flex-direction: column;
    
    }

    >div{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

`


export const RestaurandCardContainer = styled.div`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    height: min-content;
    width: 100%;
    min-width: 100%;
    padding:2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2rem;

    *{
        cursor: pointer;
    }

    @media (max-width: 450px) {
        
        flex-direction: column;
        .resp-center{
            text-align: center;
        }
    
    }
`

export const BrandHeader = styled.div`


display: flex;
align-items: center;
gap: 2rem;
margin-bottom: 2rem;
width: 100%;
justify-content: space-between;

@media (max-width: 600px) {
        gap: 3rem;
        flex-direction: column;
    
    }

`

export const NoSelectItemsContainer = styled.div`

    width:100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    overflow: hidden;
    img{
        width: 60%;
        object-fit: contain;
    }

`

export const NoBrandsIcon = styled.div`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 10rem;
    max-height: 10rem;

    svg{
        color:red;
        scale: 2;
        stroke:red !important;
        path{
            stroke:${({ theme }) => theme.primary};
        }
    }

`

export const RestaurantImage = styled.div`

    min-width: 10rem;
    min-height: 10rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;

    @media (max-width: 450px) {
        width: 7rem;
        height: 7rem;
        img{
            width: 100%;
            object-fit: contain;
        }
  }

`


export const RestaurantCardBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: .5px solid ${({theme})=>theme.gray};
    padding-top: 1.6rem;
    margin-top: 2.3rem;

`

export const VerticalSeparator = styled.div`

    width: 0.5px;
    height: 3.5rem;
    background-color: ${({theme})=>theme.gray};

`


export const HorizontalSeparator = styled.div`

    margin: 2rem 0;
    width: 100%;
    height: 0.5px;
    background-color: ${({theme})=>theme.gray};

`

export const RestaurantGraphContainer = styled.div`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding: 3rem;
    margin-top: 2.4rem;
`

export const ListItem = styled.div`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    background-color: ${({ theme }) => theme.white};   
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    width: 100%;
    height: 9rem;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    div{
        img{
            width: 5rem;
            object-fit: contain;
        }
    }

    svg{
        height: 1.5rem;
    }

    .check{
        height: auto;
    }

`


export const CredentialsCard = styled.div<{$pointer?:boolean}>`
    height: 13rem;
    width: 32rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: white;
    ${({ $pointer }) => $pointer && 'cursor:pointer;'}

    @media (max-width: 900px) {
                width: 100%;
                justify-content: un;
            }

`

export const BigLogoCircle = styled(LogoCircle)`

    min-width: 20rem;
    width: 20rem;
    min-height: 20rem;
    height: 20rem;

    img{
        min-width:6rem;
        object-fit: contain;
    }

    @media (max-width: 800px) {
  
  margin-top: 7rem;
  min-width: 15rem;
    width: 15rem;
    min-height: 15rem;
    height: 15rem;

  
 }

`

export const VoidedCheckContainer = styled.div`
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding:3rem;
    height: 25rem;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:2rem;

    &>.image-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:rgba(115, 115, 115, 0.1);;
        border-radius: ${({ theme }) => theme.borderRadius.br1};
        cursor: pointer;
        position:relative;
        overflow: hidden;

        img{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .button{
            height: 4rem;
            width: 27rem;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            color:${({ theme }) => theme.secondary};
            z-index: 99999;
            @media (max-width: 1000px) {
                width: 90%;
                font-size: 10px;
            }
        }
    }

`


export const ProvidersModalContainer = styled.div<{$width?:string, $image?:boolean, $maxWidth?:string}>`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding:3rem;
    z-index: 99;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: ${({$width})=>$width ?? "42rem"};
     ${({$maxWidth})=>$maxWidth && 'max-width: '+$maxWidth};
    z-index: 99999;
    ${({$image})=>$image && 'max-width:80vw; max-height:90vh; min-width:50vw; .image-container{ display:flex;align-items:center;justify-content:center;  margin-top:1rem; width:100%; img {width:100%; min-height:30rem; height:100%; border-radius:15px; max-height:70vh} }'}
 

`

export const GeneralCheck = styled.div<{$true?:boolean}>`

    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    border: 1px solid ${({theme})=>theme.secondary};




`

export const BrandsHead = styled.div<{$active?:boolean}>`

display: flex;
align-items: center;
justify-content: space-between;
width: 100%;

>svg{
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    ${({$active})=>$active && 'transform: rotate(180deg)'}
}

`


export const BrandImageCircle = styled.div<{$width?:string}>`

    
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    padding:1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    svg{
        width: 4.5rem;
        height: 4.5rem;
    }
    img{
        height:100%;
        object-fit: cover;
    }


`


export const ExplainModalContainer = styled(motion.div)`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding:3rem;
    z-index: 9999999;
    width: 70rem;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);

    @media (max-width: 770px) {
        width: 90%;
  }

`

export const ViewRestaurantWrapper = styled.div`

display: flex;
gap: 2rem;

@media (max-width: 1000px) {
   flex-direction: column;
  }

`


export const RestaurantDetailsWrapper = styled.div<{$width?:string}>`

  width: 70%;
  ${({$width})=>('width: '+ $width ?? '70%') + ';' }
  padding: 1rem;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

export const RestaurantMain = styled.div`

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }

`

export const RestaurantDetails = styled(Div)<{edit?:boolean}>`

  display: flex;
  flex-direction: ${({edit})=>edit ? 'row' : 'column'};
  ${({edit})=>edit && 'width:100%; align-items:center;justify-content:space-between;'}
  @media (max-width: 1000px) {
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
  }
`

export const ProviderCredentialsWrapper = styled.div`

  display: flex;
  gap: 1rem;
  @media (max-width: 1140px) {
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
  }

`

export const RestaurantGraphWrapper = styled.div`

  display: flex;
  height: 49rem;
  width: 100%;

  @media (max-width: 500px) {
    height: 30rem;
  }

`

export const BrandsCardContainer= styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:2rem;

    @media (max-width: 800px) {
        flex-wrap: nowrap;
        overflow: auto;
    }

`

export const BrandCard = styled.div`

    position: relative;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding:1.5rem;
    height: 20rem;
    width: 21rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 8rem;

    h4{
        margin-bottom: 2rem;
        width: 100%;
        font-weight: bold;
        text-align: center;
        text-transform: none;
        letter-spacing: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }


`

export const BrandIcon = styled.div`

    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.secondary}33;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    div{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.primary};
    }


`

export const BrandsExpand = styled.div<{open?:boolean}>`

    border-top: 0.5px solid #A9A9A9;
    margin-top: 2rem;
    display: flex;
    width: 100%;
    padding-top: 2rem;
    flex-direction: column;
    transition: 2s ease-in;
    overflow: hidden;
    ${({open})=>!open && 'height:6rem;'}

`

export const BrandsList = styled.div`

    display: flex;
    flex-direction: column;
    border-top: .5px solid ${({theme})=>theme.gray};
    gap: 1rem;
    padding: 1rem 0;
    margin-top: 2rem;
    transition: .2s ease-in;
    transform-origin: top;
    
`

export const BrandsListItem = styled.div`

    height: 4.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: 50px;
    padding: 0 2rem;
    transition: .2s ease;

    &:hover{
        background-color: ${({ theme }) => theme.secondary}33;
    }

/*     
    :hover{
        background-color: red !important;
    } */


    p{
        margin-bottom: 0;
    }

    svg{
        transform: rotate(180deg);
    }

  
   

`


export const BrandPictureContainer = styled.div`
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.secondary}33;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top:-25%;
    background-color: white;
    padding: 1rem;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

`
import { clientImage } from 'assets';
import { AnimatedDiv, FrameWrapper, Separator } from 'components';
import { WIDGET_BOOKING_URL, clientBookingId } from 'helpers';
import { Div, H1, H4, Img, P, PageWrapper } from 'styles';

export const Client = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $columnSM $alignCenter>
        <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>Client</H4>
            <H1>
              <span>Make restaurateur life </span>
              <br />a whole lot easier
            </H1>
            <Div $maxWidth='50rem'>
              <P>
                <b>
                  Are you a restaurant owner looking to take the hassle and
                  headache out of management?
                  <br />
                  <br />
                  <span>We’ve got you covered.</span>
                </b>
                <br />
                <br />
                Set up a time to speak with a GRUBCON representative by filling
                out the form below.
              </P>
            </Div>
          </AnimatedDiv>
        </Div>
        <Div $desktop $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <Img src={clientImage} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
      </Div>
      <AnimatedDiv>
        <FrameWrapper
          height={850}
          title='contact-dorm'
          src={`${WIDGET_BOOKING_URL}${clientBookingId}`}
          id={clientBookingId}
        ></FrameWrapper>
      </AnimatedDiv>
      <Separator />
    </PageWrapper>
  );
};

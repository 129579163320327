import { pricingComponentImage } from 'assets';
import { styled } from 'styled-components';
import { theme } from 'styles';

export const StripeInputStyled = styled.div`
  height: 4rem;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.secondary}4D;
  border: none;
  font-size: 14px;
  line-height: 1.8rem;
  padding: 1.25px 2.4rem;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  input {
    border: none;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: normal;
    color: ${({ theme }) => theme.secondary};
    background: none;
    width: 100%;
  }

  input::placeholder {
    color: ${({ theme }) => theme.secondary}4D;
  }

  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  > div {
    width: 100%;
  }

  :focus {
    outline: none !important;
    /* border: 1px solid ${({ theme }) => theme.primary}; */
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const INPUT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: theme.secondary,
      '::placeholder': { color: `${theme.secondary}4D` },
      fontSize: '14px',
      fontWeight: 'normal',
    },
    complete: { color: theme.secondary },
    invalid: { color: 'rgb(230, 30, 41)' },
  },
};

export const PricingComponentStyled = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.secondary}33;
  box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  width: 60%;

  @media (max-width: 800px) {
    width: 100%;
  }

  /* img {
    height: 300px;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  } */

  > div {
    padding: 0 3rem 3rem 3rem;
    /* > div:nth-child(1) {
      border-bottom: 0.1rem solid ${({ theme }) => theme.secondary}33;
    } */
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border: 0.1rem solid ${({ theme }) => theme.secondary}33;
    background: ${({ theme }) => theme.white};
    padding: 1.6rem;
    position: absolute;
    left: 4rem;
    top: 0%;
    transform: translate(0, -50%);
  }

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const PricingTextBig = styled.div`
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 4.8rem;
  margin-right: 1.8rem;
  color: ${({ theme }) => theme.primary};
`;
export const PricingTextMedium = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 0.7rem;
  color: ${({ theme }) => theme.primary};
  span {
    color: ${({ theme }) => theme.secondary};
  }
`;
export const PricingTextSmall = styled.div`
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.secondary};
`;

export const PaymentComponentImage = styled.div`
  width: 30rem;
  background: ${`url(${pricingComponentImage})`};
  background-size: cover;
  background-position: right;

  @media (max-width: 800px) {
    height: 40rem;
    width: 100%;
  }
`;

import { BigLogoCircle } from 'pagesPartner/Restaurants/restaurants.styled'
import { useNavigate } from 'react-router'
import { SALES_HOME } from 'routes/routeConstantsSales'
import { Button, Div, H3, P } from 'styles'
import GrubConLogoBig from '../../assets/images/logos/GrubConLogoBig.png'

export const UnderReview = () => {

    const navigate = useNavigate()

    return <Div $flex $column $height="100vh" $width="100%" $alignCenter $center >
        <BigLogoCircle>
            <img src={GrubConLogoBig} alt="" />
        </BigLogoCircle>
        <H3 $mt="5rem" $mb="4rem" $center >Your profile is under review!</H3>
        <P $mb="4rem" $center >Thanks for providing us the information.<br />
            We will let you know when verification will be<br /> approved and active through the SMS.</P>
        <Button $round $w="35rem" onClick={() => navigate(SALES_HOME)}>OKAY</Button>
    </Div>
}
import { Button, ContentWrapper, H3, P } from "styles";
import { BigLogoCircle } from "./restaurants.styled";
import DoorDashLogo from "../../assets/images//logos/DoorDashLogo.png";
import GrubHubLogo from "../../assets/images//logos/GrubHubLogo.png";
import UberEatsLogo from "../../assets/images//logos/UberEatsLogo.png";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { useRestaurants } from "store/restaurants";

export const ConnectProvider = () => {
    const [provider, setProvider] = useState({
        name: "UberEats",
        logo: UberEatsLogo,
    });
    const { providerName, id } = useParams();
    const { connectProvider, loading } = useRestaurants(state => state)
    const searchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        let obj = { ...provider };
        switch (providerName) {
            case "grubhub":
                obj = { name: "GrubHub", logo: GrubHubLogo };
                break;
            case "doordash":
                obj = { name: "Doordash", logo: DoorDashLogo };
                break;
            default:
                obj = { name: "UberEats", logo: UberEatsLogo };
        }
        setProvider(obj);
    }, []);


    const handleConnect = async () => {
        const link = await connectProvider(providerName, searchParams.get('store'), id)
        window.location.replace(link);
    };

    return (
        <ContentWrapper $relative $p="0" $flex $column $alignCenter $center>
            <SpinnerFullscreen loading={loading} />
            <BigLogoCircle>
                <img src={provider.logo} alt="" />
            </BigLogoCircle>
            <H3 $mt="5rem" $mb="4rem" $center>
                Click on the button to connect <br /> {provider.name} with our app
            </H3>
            <P $mb="4rem" $center>
                You will be redirected to an <br /> authorization page
            </P>
            <Button $round $w="30rem" onClick={handleConnect}>
                CONNECT
            </Button>
        </ContentWrapper>
    );
};

import { motion } from "framer-motion";
import styled from "styled-components";

export const CalendarContainer = styled.div<{$open?:boolean}>`

    position: absolute;
    top:105%;
    right: 0;
    display: ${({$open})=>$open ? 'block' : 'none'};

`


export const FocusedModal = styled.div`

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(115, 115, 115, 0.4);
    z-index: 10;
`

export const RestaurantSearchBox = styled(motion.div)<{$center?:boolean}>`
    position: absolute;
    height: max-content;
    width: 40rem;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    transform-origin: top right;
    top:105%;
    right: 0;
    z-index: 99;
    padding: 2rem 0;
    display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;

    ${({$center})=> $center && `position: fixed;
    top: 50% !important;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%) scale(1) !important;`}
    

    @media (max-width: 800px) {
    /* width: calc(100vw - 60px); */
    position: fixed;
    top: 50% !important;
    left: 50%;
    right: unset;
    transform: translate(-50%, -50%) scale(1) !important;
  }
`

export const SingleRestaurantOption = styled.div`

    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
    background-color: ${({ theme }) => theme.white};  
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    width: 100%;
    height: max-content;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    svg{
      min-width: 24px;
      min-height: 24px;
      path{
        fill: none !important ;
      }
    }

    *{
      cursor: pointer;
    }

    &:hover{
      *{
        color: ${({ theme }) => theme.primary};
      }
    }

`
import { section5Image } from 'assets';
import { AnimatedDiv } from 'components';
import { GetStartedButton } from 'components/getStartedButton/getStartedButton';
import { Separator } from 'components/separator/separator';
import { Div, Img, WrapperCenterSimpler } from 'styles';
import { H2, H4, P } from 'styles/text';

export const Section5 = () => {
  return (
    <>
      <WrapperCenterSimpler $gap='5rem' $gapSM='3rem' $reverse>
        <AnimatedDiv $mobile>
          <GetStartedButton />
        </AnimatedDiv>
        <Div $width={'55%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv $flexSM $centerSM>
            <Img src={section5Image} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
        <Div $width={'45%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>our dashboard</H4>
          </AnimatedDiv>
          <AnimatedDiv>
            <H2>
              <span>Track</span> our <br /> impact
            </H2>
          </AnimatedDiv>
          <AnimatedDiv>
            <P>
              GRUBCON comes with an intuitive dashboard where you can see our
              impact on your business. Take note of recovered revenue, total
              interactions and much more - all accessible from your device.
            </P>
          </AnimatedDiv>

          <AnimatedDiv $desktop $mt='4rem'>
            <GetStartedButton />
          </AnimatedDiv>
        </Div>
      </WrapperCenterSimpler>
      <Separator borderRight />
    </>
  );
};

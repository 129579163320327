import { ErrorToast } from 'components/Toasts/Toasts';
import { IDashboardResponse, IGraphData } from 'pagesPartner/Dashboard/PartnerDashboard';
import React, { SetStateAction, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const ScrollToTop = ({ children }: { children: any }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [location.pathname]);
  return children;
};

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const contactPhone = '(855) GRUBCON';
export const contactEmail = 'info@grubcon.com';

export const contactFormId = '199e0PqCCXHjcKxhBgL8';
export const partnerBookingId = 'quR9VlJ1s5KcDE09VmEJ';
export const clientBookingId = 'n4Wei7UQfs3hDtb3xgi8';
export const pricingBookingId = 'EHlB9C4VaDRRNpfp6x8s';

export const WIDGET_FORM_URL = 'https://api.leadconnectorhq.com/widget/form/';
export const WIDGET_BOOKING_URL =
  'https://api.leadconnectorhq.com/widget/booking/';

export const pathname = window.location.pathname;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_GRUBBY = process.env.REACT_APP_API_URL_GRUBBY;

export const setDocumentTitle = (pageTitle: string) =>
  (document.title = `${pageTitle}`);

export const isAdmin = process.env.REACT_APP_ADMIN === 'true';
export const isWebsite = process.env.REACT_APP_WEBSITE === 'true';
export const isPartner = process.env.REACT_APP_PARTNER === 'true';
export const isSales = process.env.REACT_APP_SALES === 'true';


export const changeState= (setState:React.Dispatch<SetStateAction<any>>,propName:string,value:any) =>{
  setState((prev:any)=>{
    const copy = {...prev}
    copy[propName] = value
    return copy
  })
}

export function convertToDateObject(dateObj:any) {
  return new Date(dateObj.year, dateObj.month - 1, dateObj.day);
}

export function convertFromDateObject(date:Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  return { day, month, year };
}

export const formatDate = (date:Date) =>{
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${months[monthIndex]} ${day} , ${year}`;
}

export const handleError = (error:any) =>{
  if(!error.response?.data?.message?.toLowerCase().includes('unauthorized')){
    console.log(error)
    ErrorToast(error.response?.data?.message || 'An error occured')
  }
}


export const getNameFromRoute = (route:string) =>{
    // Split the route by slashes
    const segments = route.split('/').filter((segment:string) => segment.trim() !== '');
    
    // Capitalize each segment and join them with a space
    const formattedName = segments.map((segment:string) => segment.charAt(0).toUpperCase() + segment.slice(1)).join(' ');

    return formattedName;
}

export const formatDataForGraph = (data: IDashboardResponse[],type:'HOUR' | 'DAY' | 'MONTH', date?:Date) => {
  let ar:IGraphData[] = []
  const month = date.getMonth()
  const year = date.getFullYear()

  interface IObj extends IDashboardResponse{
    originalDay:number | string
  }
  if(type === 'DAY'){

    const obj:IObj[] = Array.from(
      // proveriti sta se desava sa februarom i da li fali dan
      { length: leapyear(year) && month === 1  ? 29 : months[month].days+1 },
      (_, index) => ({groupBy:index+' .'+months[month].label, index})
  ).map((o,k) => {

    const found = data.find(k=>k.groupBy === o.index)
    return{
      groupBy:o.index,
      items:[
        {
          provider:'doordash',
          count:found ? found.items.find(i=>i.provider==='doordash')?.count : 0,
          amount: found ? found.items.find(i=>i.provider==='doordash')?.amount : 0,
        },
        {
          provider:'grubhub',
          count:found ? (found.items.find(i=>i.provider==='grubhub')?.count +  (found.items.find(i=>i.provider==='grubhubPos')?.count || 0)) : 0,
          amount:  found ? found.items.find(i=>i.provider==='grubhub')?.amount : 0,
        },
        {
          provider:'ubereats',
          count:found ? found.items.find(i=>i.provider==='ubereats')?.count : 0,
          amount: found ? found.items.find(i=>i.provider==='ubereats')?.amount : 0,
        },
      ],
      originalDay:(k<9? (' '+ k) : (k)) + ' ' +months[month].label
    }

  })

    for (let i = 1; i <= obj.length; i++) {
      const found = obj.find(d => d.groupBy === i)
      if(found){
        ar.push({
          name: found.originalDay,
          doorDash: Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash')?.amount/100)?.toFixed(2) )|| 0,
          uberEats: Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats')?.amount/100 )?.toFixed(2)) || 0 ,
          grubHub: (Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub')?.amount/100)?.toFixed(2))  || 0) +  (Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos')?.amount/100)?.toFixed(2))  || 0),
          count:(((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash'))?.count || 0) + ((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub'))?.count || 0) + ((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats'))?.count || 0)) + ((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos'))?.count || 0)
      })
      }

  }

  }else if(type === 'HOUR') {
    for (let i = 0; i <= 24; i++) {
      ar.push({
          name: i ,
          doorDash: Number((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash')?.amount/100)?.toFixed(2))  || 0,
          uberEats: Number((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats')?.amount/100)?.toFixed(2))  || 0 ,
          grubHub: (Number((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub')?.amount/100)?.toFixed(2))  || 0 ) + Number((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos')?.amount/100)?.toFixed(2))  || 0  ,
          count:(((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash'))?.count || 0) + ((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub'))?.count || 0) + ((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats'))?.count || 0)) + ((data.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos'))?.count || 0)

      })
  }
  }else if(type === 'MONTH') {
    const obj:IObj[] = Array.from(
      { length: 11 },
      (_, index) => ({groupBy:months[index].label, index})
  ).map((o,k) => {

    const found = data.find(k=>k.groupBy === o.index)
    return{
      groupBy:o.index,
      items:[
        {
          provider:'doordash',
          count:found ? found.items.find(i=>i.provider==='doordash')?.count : 0,
          amount: found ? found.items.find(i=>i.provider==='doordash')?.amount : 0,
        },
        {
          provider:'grubhub',
          count:found ? found.items.find(i=>i.provider==='grubhub')?.count + (found.items.find(i=>i.provider==='grubhubPos')?.count || 0) : 0,
          amount:  found ? found.items.find(i=>i.provider==='grubhub')?.amount + (found.items.find(i=>i.provider==='grubhubPos')?.amount || 0) : 0,
        },
        {
          provider:'ubereats',
          count:found ? found.items.find(i=>i.provider==='ubereats')?.count : 0,
          amount: found ? found.items.find(i=>i.provider==='ubereats')?.amount : 0,
        },
      ],
      originalDay:k
    }

  })



  for (let i = 0; i <= 12; i++) {
    if(months[i-1]){
      ar.push({
        name: months[i-1]?.label ,
        doorDash: Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash')?.amount/100)?.toFixed(2))  || 0,
        uberEats: Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats')?.amount/100)?.toFixed(2))  || 0 ,
        grubHub: Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub')?.amount/100)?.toFixed(2))  + (Number((obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos')?.amount/100)?.toFixed(2)) || 0) || 0 ,
        count:Number(obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'doordash')?.count || 0) + Number(obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhub')?.count || 0) + Number(obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'ubereats')?.count || 0)  + Number(obj.find(d => d.groupBy === i)?.items.find(i => i.provider === 'grubhubPos')?.count || 0) 
    })
    }
}

}


  if(type === 'HOUR'){
    ar = ar.map(d=>({...d,name:getAmPm((d.name as number ))}))
  }

  return ar
}

export const getGraphAmount = (arr:any) =>{
  let sum = 0
 arr?.forEach((i:any)=> {
    sum = sum + i.doorDash + i.uberEats + i.grubHub
  }) 
  return formatNumber(sum)
}

export const getGraphTotal = (arr:any) =>{
  let sum = 0
  arr?.forEach((i:any)=> {
    sum += i.count
  }) 
  return sum
}

export const  isToday =(date: Date) =>{
  if(!date){
    return true
  }
  const today = new Date();
  return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
  );
}


export const renderDateLabel = (date:Date) => {
  if (isToday(date)) {
      return "Today";
  } else return formatDate(date)
};


export const convertPathToOriginalRouteWithId = (path:string) =>{
  return path.replace(/\d+/g, ':id');
}

export const areSameDay = (date1:Date, date2:Date) => {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}

export const getGraphStatusName = (status:string) =>{
    switch (status) {
        case 'recovered':
            return 'Recovered'
        case 'pending':
            return 'Pending'
        case 'adjustment':
            return 'Adjustment'
        case 'notEligible':
            return 'Not eligible'
        case 'evidenceNeeded':
        return 'Evidence needed'
    }
}

export const displayDateRange = (dates:Date[]) => areSameDay(dates[0],dates[1]) ? renderDateLabel(dates[0]) : !(isToday(dates[0]) && isToday(dates[1])) ? renderDateLabel(dates[0]) + ' - ' + renderDateLabel(dates[1]) : 'Today'


export const getAmPm = (hour:number) => {
  if (hour === 0) {
    return '12 AM';
} else if (hour === 12) {
    return '12 PM';
} else if (hour < 12) {
    return hour + ' AM';
} else {
    return (hour - 12) + ' PM';
}
}

export const capitalize = (word:string) =>{
  return word.charAt(0).toUpperCase()
  + word.slice(1)
}


  function leapyear(year: number) {
      // Return true if the year is divisible by 4 but not divisible by 100 unless it's also divisible by 400
      return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

export const months = [
  {
    label: "Jan",
    value: 0,
    days: 31,
  },
  {
    label: "Feb",
    value: 1,
    days: 28,
  },
  {
    label: "Mar", 
    value: 2,
    days: 31,
  },
  {
    label: "Apr",
    value: 3,
    days: 30,
  },
  {
    label: "May",
    value: 4,
    days: 31,
  },
  {
    label: "Jun",
    value: 5,
    days: 30,
  },
  {
    label: "Jul",
    value: 6,
    days: 31,
  },
  {
    label: "Aug",
    value: 7,
    days: 31,
  },
  {
    label: "Sep",
    value: 8,
    days: 30,
  },
  {
    label: "Okt",
    value: 9,
    days: 31,
  },
  {
    label: "Nov",
    value: 10,
    days: 30,
  },
  {
    label: "Dec",
    value: 11,
    days: 31,
  },
];

export const setTimeTo = (date:Date, type:'start' | 'end')=>{
  const dt = new Date(date)
  dt.setHours(type === 'start' ? 0 : 23);
  dt.setMinutes(type === 'start' ? 0 : 59);
  dt.setSeconds(type === 'start' ? 0 : 59);
  dt.setMilliseconds(type === 'start' ? 0 : 59);
  return dt;
}

export const getGraphType = (dates:Date[]) =>{
  if(dates[0].getMonth() !== dates[1].getMonth() ){
    return 'MONTH'
  }
  return areSameDay(dates[0], dates[1]) ? 'HOUR' : 'DAY'
}

export const getImage = (src: any) => {
  try {
      return URL.createObjectURL(src)
  } catch (error) {
      return src
  }
}

export const monthStart = () => new Date(new Date().getFullYear(), new Date().getMonth(), 1); 

export const formatNumber = (num:number) => num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const existsEvenZero = (number:number) =>{
  const string = String(number)
  if(string == 'null' || string == 'undefined'){
    return false
  }else{
    return true
  }
}
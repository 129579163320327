import { Div, H1, H3, H4, H5, Icon, Img, P, PageWrapper } from 'styles';
import { AnimatedDiv, FrameWrapper, Separator } from 'components';
import {
  WIDGET_FORM_URL,
  contactFormId,
  contactPhone,
  contactEmail,
} from 'helpers';
import { IconWrapper, MailIcon, PhoneIcon } from './contact.icons';
import { contactImage } from 'assets';

export const Contact = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $columnSM $alignCenter>
        <Div $width={'60%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>Contact</H4>
            <H1>
              Get in <span>Touch </span>
            </H1>
            <Div $maxWidth='50rem'>
              <P $mb='24'>
                Wishing you didn’t have to waste time on delivery apps, menu
                item pauses, disputes, cancellations, customer complaints, and
                other headaches? Contact us!
              </P>
            </Div>
          </AnimatedDiv>

          <AnimatedDiv>
            <a href={`tel: ${contactPhone}`} target='_blank' rel='noreferrer'>
              <Div $flex $alignCenter $gap='1.6rem' $mb='2.5rem'>
                <IconWrapper>
                  <Icon>
                    <PhoneIcon />
                  </Icon>
                </IconWrapper>
                <H5 $mb='0'>{contactPhone}</H5>
              </Div>
            </a>
            <a
              href={`mailto: ${contactEmail}`}
              target='_blank'
              rel='noreferrer'
            >
              <Div $flex $alignCenter $gap='1.6rem' $mb='2.5rem'>
                <IconWrapper>
                  <Icon>
                    <MailIcon />
                  </Icon>
                </IconWrapper>
                <H5 $mb='0'>{contactEmail}</H5>
              </Div>
            </a>
          </AnimatedDiv>
        </Div>
        <Div $desktop $width={'40%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <Img src={contactImage} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
      </Div>
      <AnimatedDiv>
        <H3 $mb='1rem'>
          <span>Have a</span> question?
        </H3>
        <P $mb='0'>
          Please contact us with this form. We will get in touch with you
          shortly.
        </P>
        <FrameWrapper
          height={520}
          title='contact-dorm'
          src={`${WIDGET_FORM_URL}${contactFormId}`}
          id={contactFormId}
        ></FrameWrapper>
      </AnimatedDiv>
      <Separator />
    </PageWrapper>
  );
};

import { CloseIcon } from "assets"
import { RestaurantButton } from "components/dropdownButton/restaurantButton"
import { Input } from "components/input/Input"
import { InputLabel } from "components/input/input.styled"
import { RadioInput } from "components/input/radioInput"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { ErrorToast, SuccessToast } from "components/Toasts/Toasts"
import { AnimatePresence } from "framer-motion"
import { changeState } from "helpers"
import { AdminInput } from "pagesCMS/Login/login.cms"
import { ProvidersModalContainer } from "pagesPartner/Restaurants/restaurants.styled"
import React, { useState } from "react"
import PhoneInput from "react-phone-number-input"
import { useParams } from "react-router"
import { useUsers } from "store/users"
import { Button, Div, H3, Overlay, overlayAnimationVariant, SeparatorBorderCMS } from "styles"

export const UsersModal = ({ handleClose }: { handleClose: any }) => {

    const { loading, singleUser, createUser, updateUser, fetchedUserLocations, loggedUserLocations } = useUsers(state => state)
    const [localState, setLocalState] = useState({
        firstName: singleUser?.firstName ?? '',
        lastName: singleUser?.lastName ?? '',
        phoneNumber: singleUser?.phoneNumber ?? '',
        role: 'partner',
        email: singleUser?.email ?? '',
    })
    const [selectedRestaurants, setSelectedRestaurants] = useState<any>(loggedUserLocations.filter(l => fetchedUserLocations.includes(l.id)))
    const { id } = useParams()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        changeState(setLocalState, name, value)
    }

    const handleConfirm = async () => {
        if (!localState.email || !localState.lastName || !localState.firstName || !localState.phoneNumber) {
            ErrorToast('Please fill all input fields')
            return
        }
        if (id) {
            await updateUser({ ...localState, locationIds: selectedRestaurants.map((r: any) => r.id) }, id)
        } else {
            await createUser({ ...localState, locationIds: selectedRestaurants.map((r: any) => r.id) })
        }
        handleClose()
    }

    return <AnimatePresence>
        <ProvidersModalContainer>
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    {id ? 'Edit' : 'Add'} User
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <SeparatorBorderCMS />
            <Div $mt="2rem" $flex $column $gap="2rem" >
                <Div $flex $gap="2rem" >
                    <Input value={localState.firstName} onChange={onChange} name='firstName' placeholder="Enter here" label="First Name" />
                    <Input value={localState.lastName} onChange={onChange} name='lastName' placeholder="Enter here" label="Last Name" />
                </Div>
                <Input value={localState.email} onChange={onChange} name='email' placeholder="Enter here" label="Email" />
                <Div>
                    <InputLabel>Phone Number</InputLabel>
                    <AdminInput $noMarg >
                        <PhoneInput
                            placeholder="Enter here"
                            value={localState.phoneNumber}
                            onChange={(v) => changeState(setLocalState, 'phoneNumber', v)}
                            defaultCountry="US"
                            addInternationalOption={false}
                            international
                            focusInputOnCountrySelection={true}
                        />
                    </AdminInput>
                </Div>

                {/* <Div>
                    <InputLabel>Role</InputLabel>
                    <Div $flex $gap="2rem" $width="100%" $between >
                        <RadioInput checked={localState.role == 'partner'} onClick={() => changeState(setLocalState, 'role', 'partner')} name="Partner" width='100%' />
                        <RadioInput checked={localState.role == 'manager'} onClick={() => changeState(setLocalState, 'role', 'manager')} name="Manager" width='100%' />
                    </Div>
                </Div> */}
                <Div>
                    <InputLabel>Location Permissions</InputLabel>
                    <RestaurantButton $center $noIcon $width='100%' selectedRestaurants={selectedRestaurants} setSelectedRestaurants={setSelectedRestaurants} />
                </Div>

                <Div $width="100%" $flex $gap="2rem"  >
                    <Button $secondary onClick={() => handleClose()} >Cancel</Button>
                    <Button $selected onClick={handleConfirm}>Confirm</Button>
                </Div>

            </Div>
        </ProvidersModalContainer>
        <Overlay
            key="modalOverlay"
            variants={overlayAnimationVariant}
            initial="initial"
            animate="animate"
            exit="exit"
        />
    </AnimatePresence>
}

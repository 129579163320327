import { SearchIcon } from "assets"
import React from "react"
import { Div, InputStyled } from "styles"
import { InputContainer, InputIconContainer, InputLabel } from "./input.styled"

export const Input = (props: IInput) => {
    return <InputContainer $responsive={props.$responsive} style={{ width: props.$width }} >
        {props.label && <InputLabel>{props.label}</InputLabel>}
        <InputStyled {...props} />
        {props.$search && <InputIconContainer>
            <SearchIcon />
        </InputIconContainer>}
    </InputContainer>
}

export interface IInput extends React.HTMLProps<HTMLInputElement> {
    $textCenter?: boolean
    $search?: boolean
    $width?: string
    $responsive?: boolean
}
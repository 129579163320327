import { Input } from "components/input/Input"
import { ErrorToast } from "components/Toasts/Toasts"
import { changeState } from "helpers"
import { useState } from "react"
import { Button, Disclaimer, Div, H3, H4, Img, P, SeparatorBorderCMS } from "styles"
import Search from '../../assets/images/Search.png'
import { IFindRestaurantTab } from "./data"

export const SearchRestaurant = ({ state, setState, setActive }: IFindRestaurantTab) => {

    const [query, setQuery] = useState(state.searchQuery || '')

    const handleNext = () => {
        if (!query) {
            ErrorToast('Please enter search query first.')
            return
        }

        changeState(setState, 'searchQuery', query)
        setActive('select')
    }

    const handleManual = () => {
        changeState(setState, 'manualInput', true)
        setActive('confirm')
    }

    return <>
        <Div $mb="3.4rem" $flex $gap="2rem" $alignCenter>
            <H3 $mb="0" color="primary">
                Find Your Restaurant
            </H3>
        </Div>
        <SeparatorBorderCMS />
        <H4 $mt="2.3rem" $mb="1.5rem" $noTranform $bold $textSpacing="0">
            Step 1/3
        </H4>
        <Div $flex $center $alignCenter >
            <Img $width='30rem' src={Search} alt='' ></Img>
        </Div>
        <H3 $mt="-5rem" $center >Search Restaurant</H3>
        <Input defaultValue={query} onChange={e => setQuery((e.target as any).value)} $textCenter placeholder="Enter your restaurant" />
        <Div $flex $column $gap="1.5rem">
            <Button $w="100%" $mt="2rem" $round onClick={handleNext} $selected>SEARCH</Button>
            <Disclaimer $center $mb="0" >Can't find the restaurant?</Disclaimer>
            <Button $w="100%" $round onClick={handleManual} $secondary >ADD MANUALLY</Button>
        </Div>
    </>
}
export const statuses = [
    {
        label:'RECOVERED',
        value:'recovered',
    },
    {
        label:'ADJUSTMENT',
        value:'adjustment',
    },
    {
        label:'PENDING',
        value:'pending',
    },
    {
        label:'NOT ELIGIBLE',
        value:'notEligible',
    },
    {
        label:'EVIDENCE NEEDED',
        value:'evidenceNeeded',
    },

]

export interface IValueLabel{
    value:number,
    label:string
}

export const placeholderData: any = [{
    name: '8AM',
    doorDash: 0,
    uberEats: 0,
    grubHub: 0
},
{
    name: '10AM',
    doorDash: 2,
    uberEats: 5,
    grubHub: 0
},
{
    name: '12AM',
    doorDash: 10,
    uberEats: 22,
    grubHub: 1
},
{
    name: '2PM',
    doorDash: 30,
    uberEats: 62,
    grubHub: 9
},
{
    name: '4PM',
    doorDash: 18,
    uberEats: 38,
    grubHub: 45
},
{
    name: '6PM',
    doorDash: 15,
    uberEats: 35,
    grubHub: 12
},
{
    name: '8PM',
    doorDash: 0,
    uberEats: 0,
    grubHub: 0
},
]

import { Grubby } from 'pagesCMS/Grubby/grubby.cms';
import { LoginAdmin } from '../pagesCMS/Login/login.cms';
import { OtpAdmin } from '../pagesCMS/Otp/otp.cms';
import { CartIcon, DashboardIcon, LocationIcon, SettingsIcon } from 'assets';
import { PartnerDashboard } from 'pagesPartner/Dashboard/PartnerDashboard';
import { ViewRestaurant } from 'pagesPartner/Restaurants/viewRestaurant';
import { RestaurantOrders } from 'pagesPartner/Restaurants/restaurantOrders';
import { Settings } from 'pagesPartner/Settings/settings';
import { Orders } from 'pagesPartner/Orders/orders';
import { PARTNER_ORDERS } from './routeConstantsPartner';
import { DisconnectProvider } from 'pagesPartner/Restaurants/disconnectProvider';
import { ConnectProvider } from 'pagesPartner/Restaurants/connectProvider';
import { Providers } from 'pagesPartner/Restaurants/providers';
import { ViewBrand } from 'pagesPartner/Restaurants/viewBrand';
import { Restaurants } from 'pagesPartner/Restaurants/restaurants';
import { AdminRestaurants } from 'pagesCMS/adminRestaurants';
import { TermsAndConditions } from 'pagesPartner/TermsAndConditions/termsAndConditions';
import { PrivacyPolicy } from 'pagesPartner/TermsAndConditions/privacyPolicy';

export const CMS_ROOT = '/';
export const CMS_LOGIN = `${CMS_ROOT}login`;
export const CMS_OTP = `${CMS_ROOT}otp`;
export const CMS_GRUBBY = `${CMS_ROOT}grubby`;
export const CMS_DASHBOARD = `${CMS_ROOT}dashboard`;
export const CMS_RESTAURANTS = `${CMS_ROOT}restaurants`;
export const CMS_VIEW_RESTAURANT = `${CMS_ROOT}restaurants/:id`;
export const CMS_RESTAURANTS_ORDERS = `${CMS_ROOT}restaurants/:id/orders`;
export const CMS_PROVIDERS = `${CMS_ROOT}restaurants/:id/providers`;
export const CMS_CONNECT_PROVIDER = `${CMS_ROOT}restaurants/:id/providers/connect/:providerName`;
export const CMS_DISCONNECT_PROVIDER = `${CMS_ROOT}restaurants/:id/providers/disconnect/:providerName`;
export const CMS_VIEW_BRAND = `${CMS_ROOT}restaurants/:id/brand/:brandId`;
export const CMS_BRAND_PROVIDERS = `${CMS_ROOT}restaurants/:id/brand/:brandId/providers`;
export const CMS_BRAND_CONNECT_PROVIDER = `${CMS_ROOT}restaurants/:id/brand/:brandId/providers/connect/:providerName`;
export const CMS_BRAND_DISCONNECT_PROVIDER = `${CMS_ROOT}restaurants/:id/brand/:brandId/providers/disconnect/:providerName`;
export const CMS_ORDERS = `${CMS_ROOT}orders`;
export const CMS_SETTINGS = `${CMS_ROOT}settings`;
export const ADMIN_PRIVACY_POLICY = `${CMS_ROOT}portal-privacy-policy`;
export const ADMIN_TERMS = `${CMS_ROOT}portal-terms-and-conditions`;

export const authRoutesCMS = [
  {
    path: CMS_LOGIN,
    Page: LoginAdmin,
  },
  {
    path: CMS_OTP,
    Page: OtpAdmin,
  },
  

];

export const routesCMS = [
  // {
  //   path: CMS_GRUBBY,
  //   Page: Grubby,
  //   name: 'Grubby',
  //   permissions: ['admin'],
  //   Icon: DashboardIcon,
  // },
  {
    path: CMS_DASHBOARD,
    Page: PartnerDashboard,
    name: 'Dashboard',
    permissions: ['admin', 'support'],
    Icon: DashboardIcon  ,
  },
  {
    path: CMS_RESTAURANTS,
    Page: AdminRestaurants,
    name: 'Restaurants',
    permissions: ['admin', 'support'],
    Icon: LocationIcon,
  },
  {
    path: CMS_VIEW_RESTAURANT,
    Page: ViewRestaurant,
    permissions: ['admin', 'support'],
    name:'',
    subName:'View Restaurant',
    Icon:LocationIcon,
    backLink:'restaurants'
  },
  {
    path: CMS_RESTAURANTS_ORDERS,
    Page: RestaurantOrders,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Orders',
    Icon:LocationIcon,
  },
  {
    path: CMS_RESTAURANTS_ORDERS,
    Page: RestaurantOrders,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Orders',
    Icon:LocationIcon,
  },
  {
    path: CMS_PROVIDERS,
    Page: Providers,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
    backLink:'restaurants/'

  },
  {
    path: CMS_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: CMS_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Disconnect Partner',
    Icon:LocationIcon
  },
  {
    path: CMS_VIEW_BRAND,
    Page: ViewBrand,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Brand Details',
    Icon:LocationIcon,
  },
  {
    path: CMS_BRAND_PROVIDERS,
    Page: Providers,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Providers',
    Icon:LocationIcon,
  },
  {
    path: CMS_BRAND_CONNECT_PROVIDER,
    Page: ConnectProvider,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Connect Provider',
    Icon:LocationIcon,
  },
  {
    path: CMS_BRAND_DISCONNECT_PROVIDER,
    Page: DisconnectProvider,
    permissions: ['admin', 'support'],
    name:'',
    subName:'Disonnect Provider',
    Icon:LocationIcon,
  },
  {
    path: CMS_ORDERS,
    Page: Orders,
    name: 'Orders',
    permissions: ['admin', 'support'],
    Icon: CartIcon,
  },
    {
    path: CMS_SETTINGS,
    Page: Settings,
    name: 'Settings',
    permissions: ['admin', 'support'],
    Icon: SettingsIcon,
  },
  {
    path: ADMIN_PRIVACY_POLICY,
    Page: PrivacyPolicy,
    name: '',
    permissions: ['admin', 'support'],
    Icon: SettingsIcon,
  },
  {
    path: ADMIN_TERMS,
    Page: TermsAndConditions,
    name: '',
    permissions: ['admin', 'support'],
    Icon: SettingsIcon,
  },
];

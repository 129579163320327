// import { useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

export const RadioComponent = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid inset ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  -webkit-box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.primary};
  -moz-box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.primary};
  box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.primary};
  cursor: pointer;

  span {
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 50%;
  }
`;

interface RatioType {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

export const Radio = ({ checked, setChecked }: RatioType) => {
  return (
    <RadioComponent onClick={() => setChecked(!checked)}>
      {checked && <span></span>}
    </RadioComponent>
  );
};

import { Input } from "components/input/Input";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { HomeSection } from "pagesSales/Home/home.styled";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useUsers } from "store/users";
import { Button, ContentWrapper, Div, H2, H3, H4, P, PageDateContainer } from "styles";
import { UsersModal } from "./usersModal";
import NoUsers from '../../assets/images/NoUsers.png'
import { useDebounce } from "@uidotdev/usehooks";
import useDidMountEffect from "hooks/useDidMountEffect";

export const Users = () => {
    const [modal, setModal] = useState(false)
    const { loading, getUsers, users, clearUsers, setFetchedUserLocations, loadedUsers } = useUsers(state => state)
    const navigate = useNavigate();
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const containerRef = useRef()
    const [query, setQuery] = useState('')
    const deboundecQuery = useDebounce<string>(query, 200)

    useEffect(() => {
        getUsers(params)
    }, [params])

    useEffect(() => {
        return (() => {
            console.log('okinuo')
            clearUsers()
        })
    }, [])

    useDidMountEffect(() => {
        getUsers({ page: 1, limit: 10, query }, true);
    }, [deboundecQuery]);



    const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }


    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - scrollTop - clientHeight < 100) {
            setParams(prev => {
                const copy = structuredClone(prev)
                copy.page = copy.page + 1
                return copy
            })
        }
    };

    const throttleScroll = () => {
        let timeout: any
        return () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    handleScroll();
                }, 800); // Adjust the throttle time as needed
            }
        };
    };

    const throttledScroll = throttleScroll();

    const handleNavigate = (id: number, ids: number[]) => {
        setFetchedUserLocations(ids)
        navigate('/users/' + id)
    }

    return (
        <ContentWrapper $relative $scroll ref={containerRef} onScroll={throttledScroll}>
            {loadedUsers && !users.length && !query ? <Div $flex $height="calc(100vh - 10rem)" $column $center $gap="2rem">
                <img className="no-items" src={NoUsers} alt="" />
                <H3 $mb="0" >
                    You don’t have any user yet
                </H3>
                <Button onClick={() => setModal(true)} >Add User</Button>
            </Div> : <> <PageDateContainer $noPadding >
                <H3 $noWrap $mb="0">
                    All Users
                </H3>
                <Div $flex $gap="2rem" >
                    <Input
                        $width="36rem"
                        $responsive
                        onChange={handleQuery}
                        $search
                        placeholder="Search Users"
                    />
                    <Button onClick={() => setModal(true)} >ADD USER</Button>
                </Div>

            </PageDateContainer>
                <SpinnerFullscreen loading={loading} />
                {loadedUsers && !users.length ? <H2 $mt="2rem">No Results</H2> : <><Div $flex $column $mt="5rem" $gap="1rem">
                    {users.map(({ user, locationIds }) => <HomeSection onClick={() => handleNavigate(user.id, locationIds)}>
                        <Div $column $between >
                            <H4 $noTranform $textSpacing="0" $bold $mb="0">
                                {user?.firstName} {user?.lastName}
                            </H4>
                            <P $mb="0" >{user?.role == 'partner' ? 'Partner' : 'Manager'}</P>
                        </Div>
                    </HomeSection>)}

                </Div>
                </>}
            </>}
            {modal && <UsersModal handleClose={() => setModal(false)} />}

        </ContentWrapper>
    );
};

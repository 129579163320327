import axios from 'axios';
import { CMS_LOGIN } from 'routes/routeConstantsCMS';
import {
  getAccessToken,
  getRefreshToken,
  setNewAccessToken,
} from 'helpers/localstorage-helper';
import { API_URL, API_URL_GRUBBY } from 'helpers';

const baseUrl = () => API_URL;
const baseUrlGrubby = () => API_URL_GRUBBY;

let token = getAccessToken();

const api = axios.create({
  baseURL: baseUrl(),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
    'grubcon':'true'
  },
});

api.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.config.url === '/v1/auth/verify-sms') {
      token = response.data.data.tokens.accessToken;
    }

    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 417) &&
      originalRequest.url === '/v1/auth/refresh'
    ) {
      window.location.href = CMS_LOGIN;
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // console.log('REFRESH');

      token = getRefreshToken();
      return api.post('/v1/auth/refresh').then((res) => {
        if (res.status === 200) {
          setNewAccessToken(res.data.data.accessToken);
          token = res.data.data.accessToken;

          originalRequest.headers['Authorization'] =
            'Bearer ' + res.data.data.accessToken;

          // console.log(originalRequest);
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export  const apiGrubby = axios.create({
  baseURL: baseUrlGrubby(),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
    'grubcon':'true'
  },
});

apiGrubby.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiGrubby.interceptors.response.use(
  (response) => {
    if (response.config.url === '/v1/auth/verify-sms') {
      token = response.data.data.tokens.accessToken;
    }

    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 417) &&
      originalRequest.url === '/v1/auth/refresh'
    ) {
      window.location.href = CMS_LOGIN;
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // console.log('REFRESH');

      token = getRefreshToken();
      return api.post('/v1/auth/refresh').then((res) => {
        if (res.status === 200) {
          setNewAccessToken(res.data.data.accessToken);
          token = res.data.data.accessToken;

          originalRequest.headers['Authorization'] =
            'Bearer ' + res.data.data.accessToken;

          // console.log(originalRequest);
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export const apiImage = axios.create({
  baseURL: baseUrl(),
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'multipart/form-data',
    'grubcon':'true'
  },
});

apiImage.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiImage.interceptors.response.use(
  (response) => {
    if (response.config.url === '/v1/auth/verify-sms') {
      token = response.data.data.tokens.accessToken;
    }

    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 417) &&
      originalRequest.url === '/v1/auth/refresh'
    ) {
      window.location.href = CMS_LOGIN;
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // console.log('REFRESH');

      token = getRefreshToken();
      return api.post('/v1/auth/refresh').then((res) => {
        if (res.status === 200) {
          setNewAccessToken(res.data.data.accessToken);
          token = res.data.data.accessToken;

          originalRequest.headers['Authorization'] =
            'Bearer ' + res.data.data.accessToken;

          // console.log(originalRequest);
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);


export default api;

import api from 'api';
import { handleError, isAdmin, setTimeTo } from 'helpers';
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface OrdersState {
    loading:boolean
    loadedOrders:boolean
    orders:any[]
    getOrders:(selectedRestaurant:{ name: string, id: number },dates:Date[], params:{page:number,limit:number}, brandIds?:number[])=>void
    clearOrders:()=>void
}

export const useOrders = create<OrdersState>()(
  devtools(
    persist(
      (set) => ({
          loading:false,
          loadedOrders:false,
          orders:[],
          getOrders:async(selectedRestaurant:{ name: string, id: number },dates:Date[],params:{page:number,limit:number}, brandIds?:number[])=>{
            try {
                set({loading:true})
                const lId = isAdmin ? {locationIds:[selectedRestaurant.id]} : {locationId:selectedRestaurant.id}
                const resp = await api.get("v1/orders", {
                    params: { ...params, ...lId, from: setTimeTo(dates[0],'start'), to: setTimeTo(dates[1],'end'), ordersFind: 'history', sortByDate: "desc",  onlyRecovered:true,brandIds  },
                });

                // napraviti interface za response
                const allOrders = resp.data.data.map((o: any) => ({
                    date: o.date,
                    id: o.id,
                    provider: o.provider,
                    status: o.recovery?.status,
                    amount: (o.recovery?.amount / 100).toFixed(2),
                    type: o.type,
                    customerName: o.customerName,
                    number: o.number,
                    total: o.totalPrice / 100
                   
                })).filter((o:any)=>['doordash','ubereats','grubhub','grubhubPos'].includes(o.provider))

                const groupedOrders: any = []

                allOrders.forEach((obj: any) => {
                    const date = new Date(obj.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
                    const existingGroup = groupedOrders.find((group: any) => group.date === date);

                    if (existingGroup) {
                        existingGroup.objects.push(obj);
                    } else {
                        groupedOrders.push({ date, objects: [obj] });
                    }
                });

                set(state=>({loading:false,orders:[...state.orders,...groupedOrders], loadedOrders:true}))
            } catch (error) {
                handleError(error)
                console.log(error)
                set({loading:false})

            }
          },
          clearOrders:()=>set({orders:[], loadedOrders:false})
      }),
      { name: 'grubcon-orders' }
    )
  )
);

import { CLIENTS, CONTACT, FAQ, PARTNERS, PRICING, ROOT } from '../../routes';

export const dropdownMenuRoutes = [
  {
    path: ROOT,
    name: 'Home',
  },
  {
    path: PARTNERS,
    name: 'Partners',
  },
  {
    path: CLIENTS,
    name: 'Clients',
  },
  {
    path: PRICING,
    name: 'Pricing',
  },
  {
    path: FAQ,
    name: 'FAQ',
  },
  {
    path: CONTACT,
    name: 'Contact',
  },
];

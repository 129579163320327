import { BigLogoCircle } from 'pagesPartner/Restaurants/restaurants.styled'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Button, Disclaimer, Div, H3, P } from 'styles'
import GrubConLogoBig from '../../assets/images/logos/GrubConLogoBig.png'

export const WaitingVerification = () => {


    const navigate = useNavigate()

    return <Div $flex $column $height="100vh" $width="100%" $alignCenter $center >
        <BigLogoCircle>
            <img src={GrubConLogoBig} alt="" />
        </BigLogoCircle>
        <H3 $mt="5rem" $mb="4rem" $center >Your restaurant<br /> has been created successfully!</H3>
        <P $mb="4rem" $center >Thank you for all your details.</P>
        <Button $round $w="35rem" onClick={() => navigate('/standalone-find-restaurant')}>ADD ADDITIONAL RESTAURANTS</Button>
        <Disclaimer $m='1.5rem 0' >OR</Disclaimer>
        <Button $round $w="35rem" onClick={() => navigate('/restaurants')} $selected>CONTINUE</Button>
    </Div>
}
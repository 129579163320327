import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';

export const AdminDatePickerStyled = styled(Flatpickr)`
  width: 250px;
  border: none;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 12.5px 24px 12.5px 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  cursor: pointer;

  :focus {
    outline: none !important;
  }
`;

export const FlatpickrStyled = styled.div`
  .flatpickr-calendar.rangeMode.animate.inline {
    margin: 0 !important;
  }

  .flatpickr-day.inRange {
    background: #f7f7f7;
    border-color: #f7f7f7;
    border-radius: 50%;
    box-shadow: none;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #C28831;
    border-color: #C28831;
    color: #fff;
    border-radius: 50%;
    box-shadow: none !important;
  }

  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange {
    border-radius: 50%;
  }

  .flatpickr-day.today {
    border-color: #C28831;
  }

  .flatpickr-calendar {
    border: none;
    border: none;
    box-shadow: none;
  }

  .flatpickr-weekday {
    font-weight: normal !important;
  }

  .flatpickr-weekdays {
    height: 50px;
  }

  .flatpickr-input {
    display: none;
    pointer-events: none;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    -moz-appearance: none !important; /* Firefox */
    -webkit-appearance: none !important; /* Safari and Chrome */
    appearance: none !important;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: none;
    color: #e61e29;
  }

  .numInputWrapper:hover {
    background: none;
  }

  .numInputWrapper span {
    background: none;
  }

  .numInputWrapper span:hover {
    background: none;
  }

  .numInputWrapper span:hover :after {
    border-top-color: red;
  }
`;

export const CalendarInput = styled.div`

  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:50px;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border: 1px solid ${({ theme }) => theme.secondary}33;
  background-color: ${({ theme }) => theme.white};
  padding: 0.5rem;
  cursor: pointer;

  div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 50px;
    svg{  
      path{
        stroke: white;
      }
    }
  }

`
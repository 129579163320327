import { pricingComponentImage } from 'assets';
import { Link } from 'react-router-dom';
import { PAYMENT } from 'routes';
import { Button, Disclaimer, Div } from 'styles';
import {
  PricingComponentStyled,
  PricingTextBig,
  PricingTextMedium,
  PricingTextSmall,
} from './pricing.styled';

export const PricingComponent = ({ type }: { type: number }) => {
  return (
    <PricingComponentStyled>
      <span>
        <svg
          width='92'
          height='91'
          viewBox='0 0 92 91'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M57.7254 35.3634H69.5744C65.7166 26.4536 56.8069 20.2076 46.4275 20.2076C32.4658 20.2076 21.2598 31.5056 21.2598 45.3753C21.2598 59.337 32.5577 70.5431 46.4275 70.5431C56.8069 70.5431 65.6247 64.2971 69.5744 55.3873H57.7254C54.9698 58.5103 50.9283 60.4392 46.4275 60.4392C38.0689 60.4392 31.2717 53.6421 31.2717 45.2835C31.2717 36.9249 38.0689 30.1278 46.4275 30.1278C50.9283 30.2196 54.9698 32.2404 57.7254 35.3634Z'
            fill='#737373'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M60.6644 50.4271H71.0437H81.2394C80.9638 52.1723 80.5964 53.8257 80.1372 55.479C75.8201 70.0836 62.3177 80.6468 46.3353 80.6468C26.8625 80.6468 11.0638 64.8481 11.0638 45.3752C11.0638 25.9024 26.8625 10.1037 46.3353 10.1037C62.3177 10.1037 75.8201 20.7586 80.1372 35.2714H90.5165C85.9239 15.0638 67.9207 -0.00012207 46.3353 -0.00012207C21.2594 -0.00012207 0.959961 20.2994 0.959961 45.3752C0.959961 70.4511 21.2594 90.7506 46.3353 90.7506C67.9207 90.7506 86.0158 75.6867 90.5165 55.479C90.884 53.8257 91.1595 52.1723 91.3432 50.4271C91.5269 48.7738 91.6188 47.1204 91.6188 45.3752C91.6188 43.63 91.5269 41.9767 91.3432 40.3233H81.2394H71.0437H60.6644H46.4272V50.4271H60.6644Z'
            fill='#C28831'
          />
        </svg>
      </span>

      <img src={pricingComponentImage} alt='pricing' />
      <div>
        <Div $flex $alignCenter $p='2.8rem 0'>
          <PricingTextBig>{type === 1 ? '$99' : '$0'}</PricingTextBig>
          <div>
            <PricingTextMedium>
              UNLIMITED <span>PLAN</span>
            </PricingTextMedium>
            <PricingTextSmall>PER MONTH</PricingTextSmall>
          </div>
        </Div>
        <Div $p='1.6rem 0' $mb={type === 1 ? '3rem' : '0'}>
          <Div $flex $alignCenter $gap='1.6rem'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z'
                stroke='#737373'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12 16L12 11'
                stroke='#737373'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12.0059 8L11.9969 8'
                stroke='#737373'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <Disclaimer $mb='0'>
              {type === 1
                ? '$0 for the first 15 days. '
                : 'First 15 days its free then billed $99/month'}{' '}
            </Disclaimer>
          </Div>
          {type === 1 && (
            <Div $flex $alignCenter $gap='1.6rem' $mt='0.8rem'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z'
                  stroke='#E61E29'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12 8V13'
                  stroke='#E61E29'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.9941 16H12.0031'
                  stroke='#E61E29'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <Disclaimer $mb='0'>
                Results can cause a smile on your face.
              </Disclaimer>
            </Div>
          )}
        </Div>
        {type === 1 && (
          <Link to={PAYMENT}>
            <Button $w='100%'>FREE 15 DAY TRIAL</Button>
          </Link>
        )}
      </div>
    </PricingComponentStyled>
  );
};

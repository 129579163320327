import { ErrorToast, SuccessToast } from "components/Toasts/Toasts";
import { SpinnerFullscreen } from "components/spinner/spinner";
import {
  localStorageHasEmailNode,
  remove,
  //  setHasEmailLS,
  setPhoneNumberLS,
  store,
} from "helpers/localstorage-helper";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router";
import { CMS_OTP } from "routes/routeConstantsCMS";
import styled from "styled-components";
import api from "../../api";
import { LogoCMS } from "../../assets";
import { isAdmin, isPartner, isSales, setDocumentTitle } from "../../helpers";
import { useAuth } from "../../store/auth";
import { Button, Div, H3, H4, P, SeparatorBorderCMS } from "../../styles";

export const AdminInput = styled.div<{ $round?: boolean, $noMarg?: boolean }>`
  border: 0.5px solid ${({ theme }) => theme.secondary};
  background: none;
  border-radius: ${({ theme, $round }) =>
    $round ? "50px" : theme.borderRadius.br1};
  padding: 0 2rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15rem;
  width: 100%;
  text-align: center;
  ${({ $noMarg }) => !$noMarg && 'margin-bottom:2.4rem;'}

  input {
    text-align: center;
    border: none;
    :focus {
      outline: none;
    }
  }

  position: relative;

  .PhoneInputCountry {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);

    select {
      height: 3.5rem;
    }

    .PhoneInputCountryIcon {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        object-fit: cover;
      }

      border: none;
      box-shadow: none;
      background-color: none;
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    /* height: 40px; */
    font-size: 14px;
  }

  @media (max-width: 800px) {
    /* height: 40px; */
    font-size: 14px;
  }

  :focus-visible {
    outline: ${({ theme }) => theme.primary} 1px;
  }
`;

const Form = styled.form`
  width: 100%;
`;

export const LoginAdmin = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const { removeAuthState } = useAuth((state) => state);
  async function signIn() {
    try {
      setLoading(true);
      let additionalData: { role: string; referralId?: number, referralPercentage?: number } = {
        role: isAdmin ? "admin" : isPartner ? "partner" : "salesAgent",
      };
      if (searchParams.get("referral")) {
        // additionalData.referralPercentage = Number(searchParams.get("percentage"));
        if (isSales) {
          additionalData.referralId = Number(searchParams.get("referral"));
          additionalData.referralPercentage = Number(searchParams.get("percentage"));
        }
      }
      const { data } = await api.post(`/v1/auth/sign-in`, {
        phoneNumber,
        ...additionalData,
      });

      setLoading(false);
      setPhoneNumberLS(phoneNumber);
      // setHasEmailLS(data.data.isEmailAvailable);
      SuccessToast(data.message);

      store(localStorageHasEmailNode, data?.data?.isEmailAvailable);
      navigate(
        CMS_OTP +
        (searchParams.get("referral")
          ? "?referralId=" + searchParams.get("referral")
          : "") +
        (searchParams.get("percentage")
          ? "&referralPercentage=" + searchParams.get("percentage")
          : "")
      );

    } catch (error: any) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  function handleSubmit() {
    if (!phoneNumber) return ErrorToast("Please enter your phone number");
    if (!isValidPhoneNumber(phoneNumber ?? ""))
      return ErrorToast("Check the phone number");

    signIn();
  }

  useEffect(() => {
    removeAuthState();
    !searchParams.get("referral") && remove("referralId");
    !searchParams.get("percentage") && remove("referralPercentage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setDocumentTitle(isSales ? "Grub Concierge Sales" : "Grub Concierge Partner ");

  return (
    <>
      <SpinnerFullscreen loading={loading} />
      <Div $flex $center $m="1rem 0 4rem 0">
        <LogoCMS />
      </Div>
      <SeparatorBorderCMS $m="3.2rem 0" />

      <H4 $m="2.4rem 0">
        {isPartner ? "PARTNER" : isSales ? "SALES AGENT" : "ADMIN"}
      </H4>
      <H3 $m="2.4rem 0">
        <span>Sign in</span>
      </H3>
      <P $m="2.4rem 0">
        Sign in using your mobile <br /> phone number.
      </P>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <AdminInput>
          <PhoneInput
            placeholder="Enter here"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="US"
            addInternationalOption={false}
            international
            focusInputOnCountrySelection={true}
          />
        </AdminInput>

        <Button type="submit" $w="100%" $selected>
          Continue
        </Button>
      </Form>
    </>
  );
};

import { statuses } from "pagesPartner/Dashboard/data";
import {
    AreaTooltip,
    DashboardStatusButton,
    GraphTopWrapper,
    LogoCircleBig,
    StatusButtonsWrapper,
} from "pagesPartner/Dashboard/partnerDashboard.styled";
import { useEffect, useRef, useState } from "react";
import { Div, H3, P, Disclaimer, H2, Button, HR, H5, ContentWrapper, Overlay, overlayAnimationVariant, H1, H4 } from "styles";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    BrandCard,
    BrandPictureContainer,
    BrandsCardContainer,
    HorizontalSeparator,
    NoBrandsIcon,
    RestaurantDetails,
    RestaurantDetailsWrapper,
    RestaurantGraphContainer,
    RestaurantGraphWrapper,
    RestaurantMain,
    ViewRestaurantWrapper,
    VoidedCheckContainer,
} from "./restaurants.styled";
import GrubConLogo from "../../assets/images/logos/GrubConLogo.png";
import { useNavigate, useParams } from "react-router";
import { AdminDatePicker } from "components/DatePicker/AdminDatePicker";
import { CheckIcon, CheckIconFalse, CirclePlusIcon, EditIcon, StoreIcon } from "assets";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { displayDateRange, getGraphAmount, getGraphStatusName, getGraphTotal, setTimeTo, getImage, isSales, isPartner, monthStart, existsEvenZero } from "helpers";
import { IStatuses } from "pagesPartner/Dashboard/PartnerDashboard";
import { useRestaurants } from "store/restaurants";
import { ConfigModal } from "./configModal";
import { BrandModal } from "./brandModal";
import { ImageModal } from "./imageModal";
import { PercentageModal } from "./percentageModal";

export const ViewRestaurantSales = () => {
    const [status, setStatus] = useState<IStatuses>("recovered");
    const [dates, setDates] = useState([setTimeTo(monthStart(), 'start'), setTimeTo(new Date(), 'end')]);
    const { loading, singleRestaurant, getSingleRestaurant, getRestaurantGraph, restaurantGraph, check, uploadCheck, clearSingleRestaurant } = useRestaurants(state => state)
    const [configModal, setConfigModal] = useState(false)
    const [brandModal, setBrandModal] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const [percentageModal, setPercentageModal] = useState(false)
    const ref = useRef(null)
    const { id } = useParams();
    const navigate = useNavigate();

    const renderButtons = () => {
        return statuses.map((s) => (
            <DashboardStatusButton
                active={status === s.value}
                //@ts-ignore
                onClick={() => setStatus(s.value)}
            >
                <p>{s.label}</p>
            </DashboardStatusButton>
        ));
    };

    useEffect(() => {
        getSingleRestaurant(id)
        return () => {
            clearSingleRestaurant()
        }
    }, [])

    useEffect(() => {
        getRestaurantGraph(id, status, dates)

    }, [status, dates]);


    const handleCheck = async (e: any) => {
        if (e.target.files[0]) {
            uploadCheck(e.target.files[0], id)
        }
    }


    const CustomYAxisTick = ({ x, y, payload }: any) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={-5} y={-13} dy={16} fontSize='1.5rem' textAnchor="end" fill="#666">
                    $ {payload.value}
                </text>
            </g>
        );
    };

    return (
        <ContentWrapper>
            <ViewRestaurantWrapper>
                <SpinnerFullscreen loading={loading} />
                <RestaurantDetailsWrapper>
                    <RestaurantMain>
                        <Div $flex $gap="1rem" >
                            <LogoCircleBig>
                                <img src={GrubConLogo} alt="" />
                            </LogoCircleBig>
                            <H3 $mt="3rem" $mb="2.8rem" color="primary">
                                {singleRestaurant?.name}
                            </H3>
                        </Div>
                        <HR />

                        <RestaurantDetails $mt="1rem" >
                            {!existsEvenZero(singleRestaurant?.referralPercentage) ? <Div $width="100%" onClick={() => setPercentageModal(true)} $pointer $alignCenter $height="8rem" $flex $between >
                                <H4 $noTranform $textSpacing="0" $bold $mb="1rem">Add Retail Price</H4>
                                <CirclePlusIcon />
                            </Div> :
                                <>
                                    <P $mb="0rem">Retail Price</P>
                                    <H4 $noTranform $textSpacing="0" $bold $mb="1rem">{singleRestaurant?.referralPercentage || 0} %</H4>
                                </>}
                        </RestaurantDetails>
                        <HR />

                        <RestaurantDetails $mt="1rem" $flex $column >
                            {!singleRestaurant?.referral ? <Div $width="100%" $pointer $alignCenter $height="8rem" $flex $between >
                                <H4 $noTranform $textSpacing="0" $bold $mb="1rem">No Reseller</H4>
                            </Div> :
                                <>
                                    <P $mb="0rem">Reseller</P>
                                    <H4 $noTranform $textSpacing="0" $bold $mb="1rem">{singleRestaurant?.referral?.firstName + ' ' + singleRestaurant?.referral?.lastName}</H4>
                                </>}

                        </RestaurantDetails>
                        <HR />

                        <RestaurantDetails $mt='1rem' $flex $column >
                            <P $mb="1rem">{singleRestaurant?.address}</P>
                            <P $mb="1rem">{singleRestaurant?.phoneNumber}</P>
                        </RestaurantDetails>
                    </RestaurantMain>
                    <HR />
                    <Div $mt="2rem" $flex $between $alignCenter>
                        <H3 $mb="2rem " color="primary">
                            General
                        </H3>
                        {!singleRestaurant?.last4Added && <Div $flex $center $justifyCenter $alignCenter onClick={() => setConfigModal(true)} $pointer >
                            <EditIcon />
                        </Div>}
                    </Div>
                    <HR />
                    {!singleRestaurant?.last4Added ? <><Disclaimer $mt="2rem" $mb="0">
                        Last 4 digits of EIN:
                    </Disclaimer>
                        <Disclaimer $mb="2rem">Not set</Disclaimer>
                        <HR />
                        <Disclaimer $mt="2rem" $mb="0">
                            Last 4 digits of bank account:
                        </Disclaimer>
                        <Disclaimer $mb="2rem">Not set</Disclaimer>
                    </> : <Div $mb='2rem' >
                        <Div $flex $between $height="6rem" $alignCenter>
                            <P $mb="0" >Bank Info Uploaded</P>
                            <Div $flex $gap="1rem" $alignCenter >
                                <Div $flex $center $justifyCenter $alignCenter onClick={() => setConfigModal(true)} $pointer >
                                    <EditIcon />
                                </Div>
                                <CheckIcon />
                            </Div>
                        </Div>
                        <HR />
                    </Div>
                    }
                    {!singleRestaurant?.voidCheckAdded ? <VoidedCheckContainer >
                        <Div $flex $between $alignCenter >
                            <H5 $m="0" $color="primary">
                                VOIDED CHECK
                            </H5>
                            {check && <Div $flex $center $justifyCenter $alignCenter onClick={() => (ref?.current as any)?.click()} $pointer >
                                <EditIcon />
                            </Div>}
                        </Div>
                        <div className="image-wrapper"  >
                            {check ? <img src={getImage(check)} alt="" onClick={() => setImageModal(true)} /> : <div className="button" onClick={() => (ref?.current as any)?.click()}>UPLOAD VOIDED CHECK</div>}
                            <input hidden
                                type="file"
                                accept="image/*"
                                ref={ref}
                                onChange={handleCheck} />
                        </div>
                    </VoidedCheckContainer>
                        :
                        <><Div $flex $between $height="6rem" $alignCenter>
                            <P $mb="0" >Voided Check Uploaded</P>
                            <Div $flex $gap="1rem " $alignCenter >
                                <Div $flex $center $justifyCenter $alignCenter onClick={() => (ref?.current as any)?.click()} $pointer >
                                    <EditIcon />
                                </Div>
                                {singleRestaurant?.voidCheckAdded ? <CheckIcon /> : <CheckIconFalse />}
                                <input hidden
                                    type="file"
                                    accept="image/*"
                                    ref={ref}
                                    onChange={handleCheck} />
                            </Div>
                        </Div>
                            <HR />
                        </>
                    }

                    <Div $flex $column $gap="2rem" >
                        {isSales && <Button
                            onClick={() => navigate("orders")}
                            $mt="5rem"
                            $w="100%"
                            $round
                            $selected
                        >
                            ORDERS
                        </Button>}
                        <Button
                            onClick={() => navigate("providers?name=" + singleRestaurant.name)}
                            $w="100%"
                            $round
                            $mt={isPartner ? '5rem' : '0'}
                        >
                            MANAGE PROVIDERS
                        </Button>
                    </Div>

                </RestaurantDetailsWrapper>
                <RestaurantDetailsWrapper>
                    <Div $flex $alignCenter $between>
                        <H3 $mb="0">Overview</H3>
                        <Div $flex $between $gap="1rem" $alignCenter>
                            <P $mb="0">{displayDateRange(dates)} </P>
                            <AdminDatePicker
                                range={true}
                                handleApply={(d: Date[]) => setDates(d)}
                                dt={dates}
                                click={() => 1}
                                dateFrom={dates[0]}
                                dateTo={dates[1]}
                            />
                        </Div>
                    </Div>
                    <StatusButtonsWrapper>
                        {renderButtons()}
                    </StatusButtonsWrapper>
                    <RestaurantGraphContainer>
                        <GraphTopWrapper>
                            <Div $flex $alignCenter $gap="2rem">
                                <H2 className="rest-graph" $mb="0" color="primary">
                                    {getGraphTotal(restaurantGraph)}
                                </H2>
                                <Disclaimer $mb="0">Total {getGraphStatusName(status)} </Disclaimer>
                            </Div>
                            <Div></Div>
                            <Div $flex $gap="2rem" $center>
                                <Disclaimer $mb="0" $right>
                                    Total Amount <br /> {getGraphStatusName(status)}:
                                </Disclaimer>
                                <H2 className="rest-graph" $mb="0" color="green">
                                    ${getGraphAmount(restaurantGraph)}
                                </H2>
                            </Div>
                        </GraphTopWrapper>
                        <RestaurantGraphWrapper>
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    data={restaurantGraph}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: window.innerWidth < 800 ? -30 : 0,
                                        bottom: 0,
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="uberEats" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#05C637" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#05C637" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="doorDash" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#FF2F09" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#FF2F09" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="grubHub" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#FC8800" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#FC8800" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>

                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" fontSize="1.5rem" interval='equidistantPreserveStart' />
                                    <YAxis tickFormatter={(value) => `$${value}`} tick={<CustomYAxisTick />} fontSize='1.5rem' />
                                    <Tooltip
                                        formatter={() => ""}
                                        wrapperStyle={{ outline: "none" }}
                                        content={(props) => {
                                            const uberEats = props.payload?.find(
                                                (p) => p.name === "uberEats"
                                            );
                                            const doorDash = props.payload?.find(
                                                (p) => p.name === "doorDash"
                                            );
                                            const grubHub = props.payload?.find(
                                                (p) => p.name === "grubHub"
                                            );
                                            return (
                                                <AreaTooltip>
                                                    <div className="time">
                                                        <p>{props?.label}</p>
                                                    </div>
                                                    <Div $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Door Dash: </P>
                                                        <P $m="0" style={{ color: "#FF2F09" }}>
                                                            ${doorDash?.value}
                                                        </P>
                                                    </Div>
                                                    <Div $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Grub Hub: </P>
                                                        <P $m="0" style={{ color: "#FC8800" }}>
                                                            ${grubHub?.value}
                                                        </P>
                                                    </Div>
                                                    <Div $mb="2.5rem" $flex $alignCenter $gap="1rem">
                                                        <P $m="0">Uber Eats :</P>
                                                        <P $m="0" style={{ color: "#05C637" }}>
                                                            ${uberEats?.value}
                                                        </P>
                                                    </Div>
                                                </AreaTooltip>
                                            );
                                        }}
                                    />

                                    <Area
                                        type="monotone"
                                        dataKey="doorDash"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#doorDash)"
                                        fillOpacity="1"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="uberEats"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#uberEats)"
                                        fillOpacity=".8"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="grubHub"
                                        stroke="#fff"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill="url(#grubHub)"
                                        fillOpacity=".8"
                                    />
                                </AreaChart>

                            </ResponsiveContainer>
                        </RestaurantGraphWrapper>
                    </RestaurantGraphContainer>
                </RestaurantDetailsWrapper>
                {configModal && <>
                    <ConfigModal handleClose={() => setConfigModal(false)} />
                    <Overlay
                        key="modalOverlay"
                        variants={overlayAnimationVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    /></>}
                {brandModal && <>
                    <BrandModal handleClose={() => setBrandModal(false)} />
                    <Overlay
                        key="modalOverlay"
                        variants={overlayAnimationVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    /></>}
                {percentageModal && <>
                    <PercentageModal percentage={singleRestaurant?.referralPercentage} handleClose={() => setPercentageModal(false)} />
                    <Overlay
                        key="modalOverlay"
                        variants={overlayAnimationVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    /></>}
                {imageModal && <>
                    <ImageModal image={getImage(check)} handleClose={() => setImageModal(false)} />
                    <Overlay
                        key="modalOverlay"
                        variants={overlayAnimationVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    /></>}
            </ViewRestaurantWrapper>
            <HorizontalSeparator />
            <Div $flex $alignCenter $gap="2rem" >
                {!singleRestaurant?.brands.length &&
                    <NoBrandsIcon>
                        <StoreIcon />
                    </NoBrandsIcon>}
                <Div>
                    <Div $mt="2rem" $flex $width="100%" $between $alignCenter > <H3 $mb="0" color="primary" >Brands</H3>
                    </Div>
                    <BrandsCardContainer>
                        {singleRestaurant?.brands?.length ? singleRestaurant?.brands.map(b => <BrandCard>
                            <BrandPictureContainer> <img src={b.imageUrl ?? GrubConLogo} alt="" /></BrandPictureContainer>
                            <H4>{b.name}</H4>
                            <Button onClick={() => navigate('brand/' + b.id)} >View Details</Button>
                        </BrandCard>) : <P $mt="2rem" >This restaurant currently doesn't have any brands. </P>}
                    </BrandsCardContainer>
                </Div>
            </Div>
        </ContentWrapper >
    );
};

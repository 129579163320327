import styled from 'styled-components';

export const FaqQuestionStyled = styled.div<{ expanded: boolean }>`
  width: 100%;
  background: #ffffff;
  border: 0.1rem solid ${({ theme }) => theme.secondary}33;
  box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.br1};
  margin-bottom: 2rem;
  padding: 2rem 2.5rem;
  cursor: pointer;

  h5 {
    transition: color 0.2s ease;
    color: ${({ theme, expanded }) => expanded && theme.primary};
  }

  @media (min-width: 3500px) {
    svg {
      transform: scale(2);
    }
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* standard-user-select: none; */
`;

export const Collapsable = styled.div<{
  expanded: boolean;
  elementHeight: number;
}>`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '20px' : '0px')};
  overflow: hidden;
  transition: all 0.2s ease;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '40px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '10px' : '0px')};
  }
`;

export const ChevronStyle = styled.div<{ expanded: boolean }>`
  width: 24px;
  height: 24px;

  transform: ${(props) =>
    props.expanded ? 'rotate(90deg)' : 'rotate(-90deg)'};
  transition: all 0.2s ease;
  flex: none;
  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

import api from "api";
import { SuccessToast } from "components/Toasts/Toasts";
import { handleError } from "helpers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface HomeState {
  loading: boolean;
  data: IHomeDataResponse;
  getHomeData: (dates: Date[]) => void;
  wallet: IWalletResponse;
  getWallet: () => void;
  resendEmail: () => void;
}

export const useHome = create<HomeState>()(
  devtools(
    persist(
      (set) => ({
        loading: false,
        data: null,
        wallet: null,
        getHomeData: async (dates: Date[]) => {
          try {
            set({ loading: true });
            const { data } = await api.get("v1/agents/home", {
              params: { from: dates[0], to: dates[1] },
            });
            set({
              loading: false,
              data: data.data,
            });
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getWallet: async () => {
          try {
            set({ loading: true });
            const { data } = await api.get("v1/wallet");
            set({
              loading: false,
              wallet: data.data,
            });
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        resendEmail: async () => {
          try {
            set({ loading: true });
            await api.get("v1/wallet");
            set({ loading: false });
            SuccessToast("Activation email successfully sent.");
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
      }),
      { name: "grubcon-home" }
    )
  )
);

interface IHomeDataResponse {
  agentCount: 0;
  locationCount: 0;
  earnings: 0;
  pipelineEarnings: 0;
  managerAgentCount: 0;
  locationsOrderCount: 0;
  brandsOrderCount: 0;
}

interface IWalletResponse {
  balance: {
    availableBalance: number;
    reservedBalance: number;
    currency: string;
  };
  isBankConnected: boolean;
  rtpAvailable: boolean;
  bankName: string;
  agentVerified: boolean;
}

import api, { apiImage } from 'api';
import { SuccessToast } from 'components/Toasts/Toasts';
import {  formatDataForGraph, getGraphType, handleError, isPartner, setTimeTo } from 'helpers';
import { read } from 'helpers/localstorage-helper';
import { IProvider, providersInitialState } from 'pagesPartner/Restaurants/data';
import { IProviderConfigResponse, IProviderStatusResponse } from 'pagesPartner/Restaurants/providers';
import { ILocationsRecoveryResponse, IRestaurant } from 'pagesPartner/Restaurants/restaurants';
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import { IIncompleteLocation } from './auth';

interface RestaurantsState {
    loadedRestaurants:boolean,
    restaurants:IRestaurant[],
    getRestaurants:(params?:{page:number,limit:number, query?:string}, refreshResults?:boolean)=>void,
    singleRestaurant:IRestaurant,
    getSingleRestaurant:(id:string)=>void,
    updateSingleRestaurant:(id:number,referral?:{id:number,firstName:string,lastName:string},referralPercentage?:number)=>Promise<void>,
    providers:IProvider[]
    restaurantGraph:any,
    getRestaurantGraph:(id:string,status:string, dates:Date[]) => void
    check:any,
    uploadCheck:(check:any, id:string) => void,
    loading:boolean,
    config:{
        einLast4:string,
        bankAccountLast4:string
    }
    createRestaurant:(placeId:number) =>Promise<IIncompleteLocation>,
    connectProvider:(providerName:string,storeId:string, id:string) => Promise<string>,
    disconnectProvider:(providerName:string, id:string) => Promise<void>,
    updateLast4Digits:(config:{einLast4:string,bankAccountLast4:string}, id:string) => Promise<void>,
    createBrand:(image:any, config:{locationId:number,name:string}) => Promise<void>,
    getProviderStatuses:(id:string, provider?:string) => void,
    setProviders:(providers:IProvider[]) =>void,
    clearProviderStatuses:()=>void
    clearSingleRestaurant:()=>void
    clearRestaurants:()=>void
    allSalesRestaurants:number
    allAdminRestaurants:number
    getSalesRestaurantsCount: () => void
    getAdminRestaurantsCount: () => void
    updatePercentage: (id:number,percentage:number) => Promise<void>

}

export const useRestaurants = create<RestaurantsState>()(
  devtools(
    persist(
      (set) => ({
        loadedRestaurants:false,
        restaurants:[],
        loading:false,
        singleRestaurant:null,
        check:null,
        restaurantGraph:[],
        config:null,
        providers:[],
        allSalesRestaurants:0,
        allAdminRestaurants:0,
        getRestaurants:async (params?:{page:number,limit:number, query?:string},refreshResults?:boolean)=> {
            try {
                set({loading:true})
                // set({loading:(params.page>1 ? false :true)})
                const resp = await api.get("v1/locations/recovery", {
                    params: { ...params, verified: true, from:setTimeTo(new Date(),'start'),to:setTimeTo(new Date(),'end') },
                });
                const restaurants = (
                    (resp.data.data as ILocationsRecoveryResponse[]).map((r: any) => ({
                        name: r.location.name,
                        address: r.location.address,
                        verified: r.location.verified,
                        id: r.location.id,
                        amount: (Number((r.todaysSales / 100))),
                        orders: r.todaysOrders,
                        brands:r.location.brands
                    }))
                );
                set(state=>({restaurants: refreshResults ? restaurants : [...state.restaurants,...restaurants], loading:false, loadedRestaurants:true}))
            } catch (error) {
                handleError(error)
                set({loading:false})
                console.log(error)
                //@ts-ignore
            }
        },
        getSingleRestaurant:async (id:string) => {
            try {
                set({loading:true})
                const resp = await api.get("v1/locations/" + id, {
                    params: { id, from: new Date(), to: new Date() },
                });


                if (resp.data?.data?.images[0]) {
                    set({check:resp.data.data.images[0].url})
                }

                set({loading:false,singleRestaurant:{
                    name: resp.data.data.name,
                    address: resp.data.data.address,
                    referralPercentage:resp.data.data.referralPercentage,
                    phoneNumber: resp.data.data.phone,
                    id:resp.data.data.id,
                    verified:resp.data.data.verified,
                    brands:resp.data.data.brands,
                    voidCheckAdded:resp.data.data.voidCheckAdded,
                    last4Added:resp.data.data.last4Added,
                    owner:resp.data.data.owner,
                    referral:resp.data.data.referral
                },
                config:resp.data.data.configuration
            })

                set({loading:false})

            } catch (error) {
                handleError(error);
                set({loading:false})
            }
        },
        getRestaurantGraph:async (id:string, status:string,dates:Date[],) => {
            try {
                set({loading:true})
                const resp = await api.get("v1/orders/grubcon-dashboard", {
                    params: {
                        status,
                        from: dates[0],
                        to: dates[1],
                        groupBy: getGraphType(dates),
                        locationIds: [id],
                        providers: ["grubhub", "doordash", "ubereats", "grubhubPos"],
                    },
                });
                set({restaurantGraph:formatDataForGraph(resp.data.data, getGraphType(dates), dates[0]), loading:false})
            } catch (error) {
                console.log(error)
                handleError(error);
                set({loading:false})
            }
        },
        uploadCheck:async(check:any,id:string)=>{
            try {
                set({loading:true})
                const form = new FormData()
                form.append('image', check)
                const type = { type: 'voidCheck' }
                form.append('imageData', JSON.stringify(type))
                await apiImage.post('v1/locations/' + id + '/image', form)
                SuccessToast('Void check successfully added')
                set(state=>({check,loading:false,singleRestaurant:{...state.singleRestaurant, voidCheckAdded:true} }))
    
            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },
        updateSingleRestaurant:async(id:number,referral?:{id:number,firstName:string, lastName:string},referralPercentage?:number)=>{
            try {
                set({loading:true})
                let obj:any = {}
                if(referral?.id){
                    obj.referralId = referral.id
                }
                if(referralPercentage){
                    obj.referralPercentage = referralPercentage

                }
                await api.put('v1/locations/' + id,{...obj})
                SuccessToast('Restaurant successfully updated')
                set(state=>({loading:false,singleRestaurant:{...state.singleRestaurant,referral:referral?.id ? referral : state.singleRestaurant.referral, voidCheckAdded:true, referralPercentage:referralPercentage??state.singleRestaurant.referralPercentage} }))
    
            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },
        createBrand: async(image:any, config:{locationId:number,name:string})=>{
            try {
                set({loading:true})
                const form = new FormData()
                form.append('image', image)
                const brand = { name: config.name, locationId:config.locationId, type:'RB' }
                form.append('brand', JSON.stringify(brand))
                const resp = await apiImage.post('v1/brands',form)
                set(state=>({singleRestaurant:{...state.singleRestaurant,brands:[...state.singleRestaurant.brands,resp.data.data]},loading:false}))
            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },
        createRestaurant:async (placeId:number):Promise<IIncompleteLocation> => {
            try {
                set({loading:true})
                const referralId = read('referralId')
                const referralPercentage = read('referralPercentage')
                let params:any = {placeId}
                if(isPartner && referralId){
                    params.referralId = Number(referralId)
                }

                if(isPartner && referralPercentage){
                    params.referralPercentage = Number(referralPercentage)
                }
                const {data} = await api.post('v1/locations/create', {...params})
                set({loading:false})
                return data.data
            } catch (error) {
                set({loading:false})
                handleError(error)
                //@ts-ignore
                throw new Error(error)
            }
        },
        connectProvider:async(providerName:string,storeId:string, id:string): Promise<string> =>{
            try {
                set({loading:true})
                const resp = await api.post("v1/locations/" + id + "/provider/connect", {
                    storeId,
                    provider: providerName,
                    redirectUrl:
                        process.env.REACT_APP_BASE_URL + `restaurants/${id}/providers?provider=${providerName}`,
                });
                set({loading:false})
                return resp.data.data
            } catch (error) {
                handleError(error);
                set({loading:false})
            }
        },
        disconnectProvider:async(providerName:string, id:string): Promise<void> =>{
            try {
                set({loading:true})
                const resp = await api.post("v1/locations/" + id + "/provider/disconnect", {
                    provider: providerName,
                });
                set({loading:false})
                SuccessToast('Provider successfully disconnected.')
                return resp.data.data
            } catch (error) {
                handleError(error);
                set({loading:false})
            }
        },
        updateLast4Digits:async(config:{einLast4:string,bankAccountLast4:string}, id:string): Promise<void> =>{
            try {
                set({loading:true})
                await api.put("v1/locations/" + id + "/configuration", config);
                set(state=>({loading:false, config, singleRestaurant:{...state.singleRestaurant, last4Added:true}}))
                SuccessToast('Configuration successfully updated')
            } catch (error) {
                handleError(error);
                set({loading:false})
            }
        },
        getProviderStatuses:async(id:string,provider?:string) =>{
            try {
                set({loading:true})
                const { data } = await api.get('v1/locations/' + id + '/provider-connection-statuses')
                const copy = structuredClone(providersInitialState)
                for (const p of (data.data as IProviderStatusResponse[])) {
                    const foundProviderIndex = copy.findIndex(pr => pr.provider === p.provider)
                    if(p.provider === provider){
                        copy[foundProviderIndex].isConnected = true
                    }else{
                        if(copy[foundProviderIndex]){
                            copy[foundProviderIndex].isConnected = p.isConnected
                        }
                    }
                    
                }

                const configResp = await api.get('v1/locations/' + id + '/provider/configs')
                for (const p of (configResp.data.data as IProviderConfigResponse[])) {
                    const foundProviderIndex = copy.findIndex(pr => pr.provider === p.provider)
                    delete p.provider
                    copy[foundProviderIndex].config = p
                }

                set({loading:false, providers:copy})

            } catch (error) {
                handleError(error)
                console.log(error)
                set({loading:false})


            }
        },
        getSalesRestaurantsCount: async()=>{
            try {
                set({loading:true})
                const {data} = await api.get('v1/agents/locations/count')
                
                set({allSalesRestaurants:data.data.all,loading:false})
            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },
        getAdminRestaurantsCount: async()=>{
            try {
                set({loading:true})
                const {data} = await api.get('v1/locations/verified/count')
                console.log(data.data.count)
                set({allAdminRestaurants:data.data.count,loading:false})
            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },
        updatePercentage: async(id:number,percentage:number)=>{
            try {
                set({loading:true})
                await api.put('v1/locations/'+id+'/referral',{percentage})
                set(state=>({singleRestaurant:{...state.singleRestaurant,referralPercentage:percentage},loading:false}))
                SuccessToast('Restaurant successfully updated')

            } catch (error) {
                handleError(error)
                set({loading:false})
            }
        },

        setProviders:(providers:IProvider[]) => set({providers}),
        clearProviderStatuses:()=>set({providers:structuredClone(providersInitialState)}),
        clearSingleRestaurant:()=>set({singleRestaurant:null,check:null, config:null,restaurantGraph:[]}),
        clearRestaurants:()=>set({restaurants:[], loadedRestaurants:false, allAdminRestaurants:0,allSalesRestaurants:0})
      }),
      { name: 'grubcon-restaurants' }
    )
  )
);

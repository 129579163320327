import { Layout } from 'components';
import { AnimatePresence } from 'framer-motion';
import { isAdmin, isWebsite, isPartner, isSales } from 'helpers';
import { NotFound404 } from 'pages/404/404';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { NOT_FOUND, routes } from './routeConstants';
import {
  CMS_LOGIN,
  CMS_ROOT,
  authRoutesCMS,
  routesCMS,
} from './routeConstantsCMS';
import { useAuth } from 'store/auth';
import { ReactNode } from 'react';
import { AdminLayoutRoot } from 'componentsCMS/Layout/AdminLayoutRoot';
import { authRoutesPartner, PARTNER_LOGIN, routesPartner } from './routeConstantsPartner';
import { PARTNER_ROOT, PARTNER_DASHBOARD } from './routeConstantsPartner';
import { SALES_HOME } from './routeConstantsSales';
import { authRoutesSales, routesSales, SALES_LOGIN, SALES_ROOT } from './routeConstantsSales';
import { store } from 'helpers/localstorage-helper';

export const AppRouter = () => {
  const location = useLocation();
  const { user } = useAuth(state => state)
  if (location.pathname === "/referral") {
    console.log(location)
    const id = location.search.split('=')[1].split('&')[0]
    const percentage = location.search.split('=')[2]
    console.log(percentage)
    console.log(location.search)
    if (isSales && !user) {
      return <Navigate to={SALES_LOGIN + '?referral=' + id + '&percentage=' + percentage} /> // URADITI
    } else if (isPartner && user) {
      store('referralId', id)
      store('referralPercentage', percentage)
      return <Navigate to={PARTNER_DASHBOARD} />
    }

    if (isSales && user) {
      return <Navigate to={SALES_HOME} />
    } else if (isPartner && !user) {
      store('referralId', id)
      store('referralPercentage', percentage)
      return <Navigate to={PARTNER_LOGIN + '?referral=' + id + '&percentage=' + percentage} />
    }
  }

  function PrivateRoute({
    children,
    permissions,
  }: {
    children: ReactNode;
    permissions: string[];
  }) {
    const role = useAuth((state) => state?.user?.role);
    const findRoute = (arr: IRoute[]) => arr.find(({ permissions }) =>
      permissions.includes(role)
    )



    const firstAvailableRoute = findRoute(isPartner ? routesPartner : isSales ? routesSales : routesCMS) || { path: CMS_LOGIN };

    console.log(firstAvailableRoute)

    return permissions.includes(role) || location.pathname.includes('portal') ? (
      <>{children}</>
    ) : (
      <Navigate to={firstAvailableRoute.path} />
    );

  }


  return (
    <AnimatePresence mode='wait'>
      <Routes key={location.pathname} location={location}>
        {isWebsite && (
          <Route path='/' element={<Layout />}>
            {routes.map(({ path, Page }, i) => (
              <Route key={i} path={path} element={<Page />} />
            ))}
          </Route>
        )}

        {isAdmin && (
          <Route path={CMS_ROOT} element={<AdminLayoutRoot />}>
            {authRoutesCMS.map(({ path, Page }, i) => (
              <Route key={i} path={path} element={<Page />} />
            ))}

            {routesCMS.map(({ path, Page, permissions }, i) => {
              return (
                <Route
                  key={i + 'ds'}
                  path={path}
                  element={
                    <PrivateRoute permissions={permissions}>
                      <Page />
                    </PrivateRoute>
                  }
                />
              );
            })}
          </Route>
        )}

        {isPartner && (
          <Route path={PARTNER_ROOT} element={<AdminLayoutRoot />}>
            {authRoutesPartner.map(({ path, Page }, i) => (
              <Route key={i} path={path} element={<Page />} />
            ))}

            {routesPartner.map(({ path, Page, permissions }, i) => {
              return (
                <Route
                  key={i}
                  path={path}
                  element={
                    <PrivateRoute permissions={permissions}>
                      <Page />
                    </PrivateRoute>
                  }
                />
              );
            })}
          </Route>
        )}

        {isSales && (
          <Route path={SALES_ROOT} element={<AdminLayoutRoot />}>
            {authRoutesSales.map(({ path, Page }, i) => (
              <Route key={i} path={path} element={<Page />} />
            ))}

            {routesSales.map(({ path, Page, permissions }, i) => {
              return (
                <Route
                  key={i}
                  path={path}
                  element={
                    <PrivateRoute permissions={permissions}>
                      <Page />
                    </PrivateRoute>
                  }
                />
              );
            })}
          </Route>
        )}

        <Route path={NOT_FOUND} element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  );
};


export interface IRoute {
  path: string;
  Page: () => JSX.Element;
  name: string;
  permissions: string[];
  Icon: () => JSX.Element;
}
import { Navigation } from "components";
import { StyledToastContainer } from "components/Toasts/ToastsStyled";
import { ScrollToTop, isWebsite, isSales } from "helpers";
import "react-toastify/dist/ReactToastify.min.css";
import { AppRouter } from "routes";
import { Gradient1, Gradient2 } from "styles";
import { onMessage } from "firebase/messaging";
import { messaging } from "firebase.config";
import { useAuth } from "store/auth";
import { SuccessToast } from "components/Toasts/Toasts";

export const App = () => {

  const { confirmVerification } = useAuth(state => state)
  if (isSales) {
    onMessage(messaging, (payload) => {
      if (payload?.notification.title === 'Verification Completed') {
        confirmVerification()
        SuccessToast("Your account has been verified.")
      }
    });
  }

  return (
    <div>
      <StyledToastContainer />

      {isWebsite && <Navigation />}
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
      <Gradient1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          type: "ease",
          delay: 0.4,
        }}
      />
      <Gradient2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.2,
          type: "ease",
          delay: 0.8,
        }}
      />
    </div>
  );
};

import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Div, H5 } from '../../styles';
import { ChevronStyle, Collapsable, FaqQuestionStyled } from './faq.styled';
import { ChevronLeft } from 'assets/svg';

interface FaqQuestionType {
  children: any;
  question: string;
  index: number;
  expanded: number | null;
  setExpanded: Dispatch<SetStateAction<number | null>>;
}

export const FaqQuestion = ({
  children,
  question,
  index,
  expanded,
  setExpanded,
}: FaqQuestionType) => {
  const ref = useRef<HTMLInputElement>(null);
  const elementHeight = ref?.current?.clientHeight || 0;

  return (
    <FaqQuestionStyled
      expanded={expanded === index}
      onClick={() => setExpanded(expanded === index ? null : index)}
    >
      <Div $flex $alignCenter $between $gap='20px'>
        <H5 $m='0'>{question}</H5>
        <ChevronStyle expanded={expanded === index}>
          <ChevronLeft />
        </ChevronStyle>
      </Div>

      <Collapsable expanded={expanded === index} elementHeight={elementHeight}>
        <div ref={ref}>{children}</div>
      </Collapsable>
    </FaqQuestionStyled>
  );
};

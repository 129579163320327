import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Div } from 'styles';

export const AnimationStyled = styled(motion.div)``;

export const Animation = ({ children }: any) => {
  return (
    <AnimationStyled
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
    >
      {children}
    </AnimationStyled>
  );
};

export const AdminAnimation = ({ children }: any) => {
  return (
    <AnimationStyled
      style={{ width: '100%' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
        type: 'ease',
      }}
      // layout
    >
      {children}
    </AnimationStyled>
  );
};

const initial = 'offscreen';
const whileInView = 'onscreen';

export const AnimatedDiv = ({
  children,
  delay,
  ...props
}: {
  children: any;
  delay?: any;
}) => {
  return (
    <Div
      {...props}
      initial={initial}
      whileInView={whileInView}
      viewport={{
        once: true,
        amount: 'some',
      }}
      variants={{
        offscreen: {
          y: 100,
          opacity: 0,
        },
        onscreen: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
            type: 'ease',
            delay,
          },
        },
      }}
    >
      {children}
    </Div>
  );
};

export const SliderAnimation = ({
  children,
  delay,
}: {
  children: any;
  delay?: number;
}) => {
  return (
    <AnimationStyled
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 0.2,
        type: 'ease',
        delay,
      }}
    >
      {children}
    </AnimationStyled>
  );
};

// export const Visibility = () => ({
//   in: {
//     style: {
//       visibility: (e) => `${e > 0 ? 'visible' : 'hidden'}`,
//     },
//   },
//   out: {
//     style: {
//       // visibility: (e) => `${e  1 ? 'visible' : 'hidden'}`,
//     },
//   },
// });

// const viewport = {
//   once: true,
//   amount: 'some',
// };

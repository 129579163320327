
import { OrdersButton } from "pagesPartner/Orders/ordersButton";
import { useDebounce } from "@uidotdev/usehooks";
import { AdminDatePicker } from "components/DatePicker/AdminDatePicker";
import { NoItems } from "components/NoItems/NoItems";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { displayDateRange, monthStart, setTimeTo } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useOrders } from "store/orders";
import { useRestaurants } from "store/restaurants";
import {
    ContentWrapper,
    Div,
    H4,
} from "styles";
import { DateOrders } from "pagesPartner/Orders/dateOrders";
import {
    OrdersSelectsContainer,
} from "pagesPartner/Orders/orders.styled";;

export const RestaurantOrders = () => {
    const [dates, setDates] = useState([setTimeTo(monthStart(), 'start'), setTimeTo(new Date(), 'end')]);

    const [selectedBrands, setSelectedBrands] = useState<{
        name: string;
        id: number;
    }[]>([]);
    const { loading, getOrders, orders, clearOrders, loadedOrders } = useOrders((state) => state);
    const { restaurants, singleRestaurant, getSingleRestaurant } = useRestaurants((state) => state);
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [query, setQuery] = useState('')
    const deboundecQuery = useDebounce<string>(query, 200)
    const containerRef = useRef(null)
    const { id } = useParams()


    useEffect(() => {
        getSingleRestaurant(id)
    }, [deboundecQuery]);


    useEffect(() => {
        clearOrders()
    }, [dates, selectedBrands])

    useEffect(() => {
        return (() => {
            clearOrders()
        })
    }, [])

    useEffect(() => {
        singleRestaurant && getOrders(singleRestaurant, dates, params, selectedBrands.map(b => b.id));
    }, [dates, params, selectedBrands]);


    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - scrollTop - clientHeight < 100) {
            setParams(prev => {
                const copy = structuredClone(prev)
                copy.page = copy.page + 1
                return copy
            })
        }
    };

    const throttleScroll = () => {
        let timeout: any
        return () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    handleScroll();
                }, 800); // Adjust the throttle time as needed
            }
        };
    };

    const throttledScroll = throttleScroll();


    const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }


    const handleDates = (d: Date[]) => {
        setDates(d)
        setParams(prev => ({ ...prev, page: 1 }))
    }

    const getTitle = () => {
        if (!selectedBrands.length) {
            return 'All brands'
        } else if (selectedBrands.length == 1) {
            return selectedBrands[0].name
        } else {
            return selectedBrands.length + ' brands selected'
        }
    }

    return (
        <ContentWrapper $relative $scroll ref={containerRef} onScroll={throttledScroll} >
            <Div $flex $between>
                <SpinnerFullscreen loading={loading} />
                <H4 $noTranform $bold $textSpacing="0">
                    {displayDateRange(dates)}
                </H4>
                <AdminDatePicker
                    dt={dates}
                    range={true}
                    dateFrom={null}
                    dateTo={null}
                    handleApply={(d: Date[]) => handleDates(d)}
                />
            </Div>
            <Div $flex $mt="2rem" $column $gap="2rem" $p="1rem 1.5rem" >
                <OrdersSelectsContainer
                >
                    <OrdersButton disabled results={restaurants} handleQuery={handleQuery} name={singleRestaurant?.name} setParams={setParams} />
                    <OrdersButton results={singleRestaurant?.brands} handleQuery={handleQuery} name={getTitle()} setParams={setParams} selectedBrands={selectedBrands} selectedRestaurant={null} setSelectedBrands={setSelectedBrands} brands />
                </OrdersSelectsContainer>
                {loadedOrders && orders.length < 1 ? <NoItems title="No Orders" subtitle="You currently don’t have any orders for selected restaurant." /> : orders.length > 0 && orders.map((o, k) => (
                    <DateOrders defaultOpen={true} o={o} />
                ))}
            </Div>
        </ContentWrapper>
    );
};

import { bagNWich, phillyCheez } from 'assets';
import { Quotes } from 'assets/svg';
import { AnimatedDiv } from 'components';
import { Div, Img, P, SliderPlaceholder, WrapperCenterSimpler } from 'styles';
import { H2, H4 } from 'styles/text';

export const Section6 = () => {
  return (
    <>
      <WrapperCenterSimpler $gap='3rem' $gapSM='2rem'>
        <Div $width={'45%'} $widthMD={'100%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>testimonials</H4>
          </AnimatedDiv>
          <AnimatedDiv>
            <H2 $mb='0'>
              <span>
                What our <br /> customers
              </span>{' '}
              think <br /> about us
            </H2>
          </AnimatedDiv>
        </Div>
        <Div $width={'30%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv $flex $column $gap='1.5rem' $mbSM='2rem' $box>
            <Div $flex $between $alignCenter>
              <Img $width='8rem' src={bagNWich} />
              <Quotes />
            </Div>
            <SliderPlaceholder>
              <span>
                <P $mb='0'>
                  “I don't need to worry about my tablets to 86 items, or update
                  prices, we just text and it's done.”
                </P>
              </span>

              <div>
                <P $mb='0'>
                  “I get instant updates on my phone when canceled orders get
                  recovered.”
                </P>
              </div>
            </SliderPlaceholder>

            <P $mb='0'>
              <span>Drew H. </span> <br />
              BAG N WICH - Manager
            </P>
          </AnimatedDiv>
        </Div>
        <Div $width={'30%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv $flex $column $gap='1.5rem' $mbSM='2rem' $box>
            <Div $flex $between $alignCenter>
              <Img $width='8rem' src={phillyCheez} />
              <Quotes />
            </Div>
            <P $mb='0'>
              “I don't need to worry about my tablets to 86 items, or update
              prices, we just text and it's done.”
            </P>

            <P $mb='0'>
              <span>Roman s. </span> <br />
              Philly Cheez - Manager
            </P>
          </AnimatedDiv>
        </Div>
      </WrapperCenterSimpler>
    </>
  );
};

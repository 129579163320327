import { section2Image1, section2Image2 } from 'assets';
import { CheckIcon, ChevronLeft, ChevronRight } from 'assets/svg';
import { AnimatedDiv, SliderAnimation } from 'components';
import { GetStartedButton } from 'components/getStartedButton/getStartedButton';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import {
  Div,
  Icon,
  Img,
  P,
  SliderPaginationCircle,
  SliderPlaceholder,
  WrapperCenterSimpler,
} from 'styles';
import { H3 } from 'styles/text';

const checks = [
  'Typically recover more than we charge by resolving disputes and recovering potentially lost revenue.',
  'Integrate with your delivery platforms so we are automatically notified when disputes happen.',
  'Train our team on delivery company dispute policy to maximize likelihood of successful recoveries.',
  'Automate the process so our agents are on top of things as soon as they happen.',
  'Notify the owner with details of dispute resolution over text.',
  'Gather valuable insights by analyzing the reasons behind repeat cancellations or adjustments.',
  'Prevent food wastage by recovering revenue from canceled orders that have already been prepared.',
  'Minimizes financial losses and allows restaurant owners to focus on their business.',
];

export const Section2 = () => {
  const first = [0, 1, 2, 3];
  const second = [4, 5, 6, 7];
  const [slider, setSlider] = useState(first);

  const handleSliderChange = () => {
    setSlider((prev) => (prev.includes(first[0]) ? second : first));
  };

  const SliderNavigation = () => (
    <Div $flex $alignCenter $gap='3rem' $centerSM $widthSM='100%'>
      <Icon>
        <ChevronLeft
          disabled={slider.includes(0)}
          onClick={() => !slider.includes(0) && handleSliderChange()}
        />
      </Icon>
      <SliderPaginationCircle
        $selected={slider.includes(0)}
        onClick={handleSliderChange}
      />
      <SliderPaginationCircle
        $selected={!slider.includes(0)}
        onClick={handleSliderChange}
      />
      <Icon>
        <ChevronRight
          disabled={!slider.includes(0)}
          onClick={() => slider.includes(0) && handleSliderChange()}
        />
      </Icon>
    </Div>
  );

  return (
    <>
      <WrapperCenterSimpler gap='5rem' $gapSM='3rem' $reverse>
        <AnimatedDiv $mobile $mt='4rem'>
          <GetStartedButton />
        </AnimatedDiv>
        <Div $width={'60%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <AnimatePresence mode='wait'>
              {slider.includes(0) && (
                <SliderAnimation key='section2Image1'>
                  <Div $flexSM $centerSM>
                    <Img
                      src={section2Image1}
                      alt=''
                      $width={'100%'}
                      $widthSM={'100%'}
                    />
                  </Div>
                </SliderAnimation>
              )}

              {!slider.includes(0) && (
                <SliderAnimation key={'section2Image2'}>
                  <Div $flexSM $centerSM>
                    <Img
                      src={section2Image2}
                      alt=''
                      $width={'100%'}
                      $widthSM={'100%'}
                    />
                  </Div>
                </SliderAnimation>
              )}
            </AnimatePresence>
          </AnimatedDiv>
        </Div>
        <Div $width={'40%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H3>Here’s how we do it</H3>
          </AnimatedDiv>

          <SliderPlaceholder>
            <span>
              {checks.map((text, i) => {
                if (first.includes(i)) {
                  return (
                    <div key={i}>
                      <Div
                        key={i}
                        $flex
                        $alignCenter
                        $gap='1.5rem'
                        $mb='2rem'
                        $mbSM='2rem'
                        $box
                      >
                        <Icon></Icon>
                        <P $mb='0'>{text}</P>
                      </Div>
                    </div>
                  );
                } else return null;
              })}
            </span>

            <div>
              <AnimatePresence mode='wait'>
                {checks.map((text, i) => {
                  if (slider.includes(i)) {
                    return (
                      <SliderAnimation key={i} delay={i / 15}>
                        <AnimatedDiv
                          key={i}
                          $flex
                          $alignCenter
                          $gap='1.5rem'
                          $mb='2rem'
                          $mbSM='2rem'
                          $box
                        >
                          <Icon>
                            <CheckIcon />
                          </Icon>
                          <P $mb='0'>{text}</P>
                        </AnimatedDiv>
                      </SliderAnimation>
                    );
                  } else return null;
                })}
              </AnimatePresence>
            </div>
          </SliderPlaceholder>

          <AnimatedDiv $flex $alignCenter $mt='5rem' $gap='5rem'>
            <Div $desktop>
              <GetStartedButton />
            </Div>
            <SliderNavigation />
          </AnimatedDiv>
        </Div>
      </WrapperCenterSimpler>
    </>
  );
};

import api, { apiImage } from "api";
import { SuccessToast } from "components/Toasts/Toasts";
import { handleError } from "helpers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface AgentsState {
  loading: boolean;
  loadedAgents:boolean;
  agents: IGetAgentsResponse[];
  getAgents: (params: { page: number; limit: number, deactivated?:boolean }, keppOld?:boolean) => void;
  getAdminAgents: (params: { page: number; limit: number, deactivated?:boolean, query?:string }, keepOld?:boolean) => void;
  singleAgent: ISingleAgent;
  getSingleAgent: (userId: string) => void;
  updateAgentPercentage: (id: number,percentage:number) => Promise<void>;
  deactivateAgent: (userId: string) => void;
  activateAgent: (userId: string) => void;
  postIdCard:(side:string, img:any) => Promise<void>
  clearAgents:()=>void
  clearSingleAgent:()=>void
}

export const useAgents = create<AgentsState>()(
  devtools(
    persist(
      (set) => ({
        loading: false,
        loadedAgents:false,
        agents: [],
        singleAgent: null,
        getAgents: async (params: { page: number; limit: number,deactivated?:boolean }, keepOld?:boolean) => {
          try {
            set({ loading: (params.page>1 ? false :true) });
            const { data } = await api.get("v1/agents/all", { params });
            set(state=>({ loading: false, agents: keepOld ? [...state.agents,...data.data] : data.data, loadedAgents:true }));
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getAdminAgents: async (params: { page: number; limit: number,deactivated?:boolean,query?:string }, keepOld?:boolean) => {
          try {
            set({ loading: (params.page>1 ? false :true) });
            const { data } = await api.get("v1/agents/all-verified", { params });
            set(state=>({ loading: false, agents: keepOld ? [...state.agents,...data.data] : data.data, loadedAgents:true }));
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        getSingleAgent: async (userId: string) => {
          try {
            set({ loading: true });
            const { data } = await api.get("v1/agents", { params: { userId, include:true } });
            const singleAgent: IGetSingleAgent = data.data;
            set({
              loading: false,
              singleAgent: {
                id:singleAgent.agent.id,
                firstName: singleAgent.user.firstName,
                lastName: singleAgent.user.lastName,
                deactivated:singleAgent.agent.deactivated,
                signedLocationsCount: singleAgent.signedLocationsCount,
                invitedLocationsCount: singleAgent.invitedLocationsCount,
                verifiedAgentsCount: singleAgent.verifiedAgentsCount,
                invitedAgentsCount: singleAgent.invitedAgentsCount,
                locations:singleAgent.locations.map(l=>({id:l.id,address:l.address,name:l.name})),
                referralPercentage:singleAgent.agent.referralPercentage,
                agents:singleAgent.agents.map(a=>({id:a.id,name:a.firstName + ' ' + a.lastName,signedLocationsCount:a.signedLocationsCount}))
              },
            });
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        deactivateAgent:async(userId:string)=>{
          try {
            set({ loading: true });
            await api.put("v1/agents/deactivate?userId="+userId );
            set(state=>({ loading: false, singleAgent:{...state.singleAgent, deactivated:true} }));
            SuccessToast('Agent successfully deactivated.')
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        activateAgent:async(userId:string) => {
          try {
            set({ loading: true });
            await api.put("v1/agents/activate?userId="+userId );
            set(state=>({ loading: false, singleAgent:{...state.singleAgent, deactivated:false} }));
            SuccessToast('Agent successfully activated.')
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        updateAgentPercentage:async(id:number,percentage:number) => {
          try {
            set({ loading: true });
            await api.put("v1/agents/"+id+'/referral',{percentage} );
            set(state=>({ loading: false, singleAgent:{...state.singleAgent, referralPercentage:percentage} }));
            SuccessToast('Agent percentage successfully updated.')
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        postIdCard:async(side:string, img:any) =>{
          try {
            set({ loading: true });
            const form = new FormData()
            form.append('side', side)
            form.append('file', img)
            await apiImage.post("v1/agents/id-card", form);
            set({ loading: false });
          } catch (error) {
            handleError(error);
            set({ loading: false });
            //@ts-ignore
            throw new Error(error)
          }
        },
        clearAgents:()=>set({agents:[],loadedAgents:false}),
        clearSingleAgent:()=>set({singleAgent:null})
      }),
      { name: "grubcon-agents" }
    )
  )
);

interface IGetAgentsResponse {
  id: number;
  firstName: string;
  lastName: string;
  signedLocationsCount: number;
}

interface IGetSingleAgent {
  user: {
    id: number;
    phoneNumber: string;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    deleted: boolean;
    role: string;
    completed: boolean;
    termsAccepted: boolean;
    emailVerified: boolean;
    notificationsEnabled: boolean;
    fcmToken: string;
    createdAt: string;
    updatedAt: string;
  };
  agent: {
    id: number;
    verified: boolean;
    deactivated: boolean;
    idCardFrontUrl: string;
    idCardBackUrl: string;
    w9FormUrl: string;
    signatureUrl: string;
    referralPercentage:number
    earnings: {
      id: number;
      year: number;
      endAt: string;
      amount: number;
      active: true;
      expired: true;
    }[];

    amountPerLocation: number;
  };
  locations:{
    id:number
    name:string
    verified:boolean
    address:string
    timeZone:string
    zipCode:string
    phoneNumber:string
    brands:{
      id:number
      name:string
    }[]
  }[]
  agents:{
    id:number
    firstName:string
    lastName:string
    signedLocationsCount:number
  }[]
  verifiedAgentsCount: number;
  invitedAgentsCount: number;
  invitedLocationsCount: number;
  signedLocationsCount: number;
}

interface ISingleAgent {
  id:number
  firstName: string;
  lastName: string;
  deactivated:boolean
  signedLocationsCount: number;
  verifiedAgentsCount: number;
  invitedAgentsCount: number;
  invitedLocationsCount: number;
  referralPercentage?:number
  agents:{
    id:number
    name:string
    signedLocationsCount:number
  }[]
  locations:{
    id:number
    name:string
    address:string
  }[]


}

import { Div, Img, PageWrapper } from 'styles';
import { Section1 } from './sections/section1';
import { Section2 } from './sections/section2';
import { Section3 } from './sections/section3';
import { Section4 } from './sections/section4';
import { Section5 } from './sections/section5';
import { homeBannerImage, homeBannerImageMobile } from 'assets';
import { AnimatedDiv } from 'components';
import { Section6 } from './sections/section6';

const sections = [Section1, Section2, Section3, Section4, Section5, Section6];

export const Home = () => {
  return (
    <PageWrapper $home>
      {sections.map((Section, i) => (
        <Section key={i} />
      ))}
      <AnimatedDiv>
        <Div $desktop>
          <Img src={homeBannerImage} $width='100%' />
        </Div>
        <Div $mobile>
          <Img src={homeBannerImageMobile} $width='100%' />
        </Div>
      </AnimatedDiv>
    </PageWrapper>
  );
};

import api from "api";
import { Input } from "components/input/Input";
import { Radio } from "components/radio/radio";
import { SpinnerFullscreen } from "components/spinner/spinner";
import { ErrorToast } from "components/Toasts/Toasts";
import { changeState, handleError, isSales } from "helpers";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Disclaimer, Div, H3, H4, SeparatorBorderCMS } from "styles";
import { userInfoInitialState } from "../FindRestaurant/data";
import { PARTNER_TERMS, PARTNER_PRIVACY_POLICY } from "routes/routeConstantsPartner";
import { useAuth } from "store/auth";

export const UserInfo = () => {
    const [localState, setLocalState] = useState(userInfoInitialState);
    const [loading, setLoading] = useState(false);
    const { updateAuthState } = useAuth(state => state)
    const navigate = useNavigate()
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        changeState(setLocalState, name, value);
    };

    const handleNext = async () => {
        if (!localState.confirmed) {
            ErrorToast("You must agree to terms and conditions");
            return;
        } else if (
            !localState.firstName ||
            !localState.lastName ||
            !localState.email
        ) {
            ErrorToast("You must fill all fields.");
            return;
        }

        setLoading(true);
        try {
            const forDispatch = { ...localState }
            delete forDispatch.confirmed
            const resp = await api.put("v1/users/complete", forDispatch);
            updateAuthState({ user: resp.data.data })
            setLoading(false);
            navigate('/standalone-find-restaurant')
        } catch (error) {
            setLoading(false);
            handleError(error)
            console.log(error)
        }
    };

    return (
        <>
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3.4rem" $flex $gap="2rem" $alignCenter>
                <H3 $mb="0" color="primary">
                    User Info
                </H3>
            </Div>
            <SeparatorBorderCMS />
            <H4 $mt="2.3rem" $mb="1.5rem" $noTranform $bold $textSpacing="0">
                Complete Profile
            </H4>
            <Div $mb="2rem" $flex $column $gap="2rem" $width="100%">
                <Input
                    label="First name"
                    defaultValue={localState.firstName}
                    onChange={onChange}
                    name="firstName"
                    placeholder="Enter here"
                />
                <Input
                    label="Last name"
                    defaultValue={localState.lastName}
                    onChange={onChange}
                    name="lastName"
                    placeholder="Enter here"
                />
                <Input
                    label="Email"
                    defaultValue={localState.email}
                    type="email"
                    onChange={onChange}
                    name="email"
                    placeholder="Enter here"
                />
            </Div>
            <Div $flex $gap="2rem">
                <Div $height="100%">
                    <Radio
                        checked={localState.confirmed}
                        setChecked={() =>
                            changeState(setLocalState, "confirmed", !localState.confirmed)
                        }
                    />
                </Div>
                <Disclaimer $spanPointer >
                    By checking this you agree to our
                    <span onClick={() => navigate(PARTNER_TERMS)} > Terms & Conditions </span>
                    and
                    <span onClick={() => navigate(PARTNER_PRIVACY_POLICY)} > Privacy Policy </span>
                    {isSales && <>
                        as well as our partner
                        <span> Dwolla’s Terms & Conditions </span>
                        and <br />
                        <span> Privacy Policy </span>
                    </>}
                    .
                </Disclaimer>
            </Div>
            <Button $round $selected $w="100% " onClick={handleNext}>
                NEXT
            </Button>
        </>
    );
};

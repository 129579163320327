import React, { SetStateAction } from "react"
import { IFindRestaurantState, RestaurantTabs } from "./findRestaurant"

export const confirmInitialState = {
    location:'',
    address:'',
    city:'',
    country:'',
    state:'',
    zip:'',
    phone:'',
    streetNumber:'',
}



export const userInfoInitialState ={
    firstName:'',
    lastName:'',
    email:'',
    confirmed:false
}


export const confirmInvalidFields = Object.keys(confirmInitialState).filter(f=>f!=='phone')

export const userInfoInvalidFields = Object.keys(userInfoInitialState)


export type IConfrimState = typeof confirmInitialState & {
    longitude?:string
    latitude?:string
}

export type IUserInfoState = typeof userInfoInitialState

export interface IFindRestaurantTab{
    state:IFindRestaurantState
    setState:React.Dispatch<SetStateAction<IFindRestaurantState>>
    setActive:React.Dispatch<SetStateAction<RestaurantTabs>>
}
import { CalendarIcon } from "assets";
import "flatpickr/dist/flatpickr.css";
import { AnimatePresence } from "framer-motion";
import { setTimeTo } from "helpers";
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll";
import { useEffect, useState } from "react";
import { Button, Div, Overlay, overlayAnimationVariant } from "styles";
import {
  AdminDatePickerStyled,
  CalendarInput,
  FlatpickrStyled,
} from "./AdminDatePickerStyled";
import { DropdownMenuComponent } from "./dropdownMenuComponent";

export const AdminDatePicker = ({ range, handleApply, dateFrom, dateTo,dt, ...props }) => {
  const [dropdown, setDropdown] = useState(false);
  const [date,setDate] = useState(dt || new Date())
  useDisableBodyScroll(dropdown);

  useEffect(() => {
    // props.onChange(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <AnimatePresence>
      <Div $relative>
        <Div $opened={dropdown} onClick={() => setDropdown((prev) => !prev)}>

        <CalendarInput>
          <div>
            <CalendarIcon stroke="#C28831" />
          </div>
        </CalendarInput>
        </Div>
        <DropdownMenuComponent isOpen={dropdown} setIsOpen={setDropdown} toLeft>
          <FlatpickrStyled>
            <AdminDatePickerStyled
              // onChange={onChange}
              onChange={d=>  setDate(d)}
              {...props}
              options={{
                mode: range ? 'range' : 'single',
                dateFormat: "M d, Y",
                inline: true,
                maxDate:'today',
                locale: { rangeSeparator: " - " },
              }}
              className="form-control"
            />
          </FlatpickrStyled>

          <Button
            $selected
            $w="100%"
            $mt="10px"
            $wSM="100%"
            onClick={() => {
              const dt = structuredClone(date)
              dt[0] = setTimeTo(dt[0],'start')
              if( !date[1] && range){
               dt[1] = setTimeTo(dt[0],'end')
              }else{
                dt[1] = setTimeTo(dt[1],'end')
              }
              handleApply(dt);
              setDropdown(false);
            }}
          >
            Apply
          </Button>
        </DropdownMenuComponent>
      </Div>

      {dropdown && (
        <Overlay
          key="modalOverlay"
          variants={overlayAnimationVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          onClick={() => setDropdown(false)}
        />
      )}
    </AnimatePresence>
  );
};

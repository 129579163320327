import { AnimatePresence } from 'framer-motion';
import { Div } from '../../styles';
import { DropdownStyled, NavLinkStyled } from './dropdownMenu.styled';
import { dropdownMenuRoutes } from './dropdownMenuData';
import { Dispatch, FC, SetStateAction } from 'react';

const variants = {
  initial: {
    opacity: 0,
    transform: 'scale(1, 0)',

    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
  animate: {
    opacity: 1,
    transform: 'scale(1, 1)',
  },

  exit: {
    opacity: 0,
    transform: 'scale(1, 0)',
    transition: {
      duration: 0.2,
      type: 'ease',
    },
  },
};

interface DropdownMenuType {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  location: string;
}

export const DropdownMenu: FC<DropdownMenuType> = ({
  isOpen,
  setIsOpen,
  location,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DropdownStyled
          key='DropdownMenu'
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <Div>
            {dropdownMenuRoutes.map(({ path, name }, i) => {
              return (
                <NavLinkStyled
                  key={i}
                  to={path}
                  onClick={() => location !== path && setIsOpen(false)}
                  $last={dropdownMenuRoutes.length === i + 1}
                >
                  {name}
                </NavLinkStyled>
              );
            })}
          </Div>
        </DropdownStyled>
      )}
    </AnimatePresence>
  );
};

import { partnerImage } from 'assets';
import { AnimatedDiv, Separator } from 'components';
import { Link } from 'react-router-dom';
import { ROOT } from 'routes';
import { Button, Div, H1, H4, Img, P, PageWrapper } from 'styles';

export const ThankYou = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $columnSM $alignCenter>
        <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>thank you</H4>
            <H1>
              <span>We look forward </span>
              to
              <br />
              meeting you
            </H1>
            <Div $maxWidth='50rem'>
              <P>
                You have successfully made an appointment to get in touch with
                one of our representatives. We’ll be giving you a call at your
                scheduled time.
                <br />
                <br />
                Thank you!
              </P>
            </Div>
            <Link to={ROOT}>
              <Button>GO TO HOME PAGE</Button>
            </Link>
          </AnimatedDiv>
        </Div>
        <Div $desktop $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <Img src={partnerImage} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
      </Div>
      <Separator />
    </PageWrapper>
  );
};

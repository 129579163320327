import styled from 'styled-components';
import { motion } from 'framer-motion';
import { H3 } from './text';

/* @media (min-width: 3000px) {
    max-width: 1940px;
  }

  @media (min-width: 3500px) {
    max-width: 80%;
    ${({ $home }) => !$home && `padding-top: 300px;`}
  } */

export const PageWrapper = styled.div<{
  centeredContent?: boolean;
  $home?: boolean;
}>`
  width: 100%;
  padding: 0 10rem;
  max-width: 164rem;
  margin: 0 auto;
  position: relative;
  padding-bottom: 15rem;
  min-height: calc(100vh + 5rem);

  ${({ centeredContent }) =>
    centeredContent &&
    `
   display: flex;
  align-items: center;
  `}
  ${({ $home }) => !$home && `padding-top: 15rem;`}

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: 0 3rem;
    padding-bottom: 10rem;
    ${({ $home }) => !$home && `padding-top: 10rem;`}
  }

  @media (max-width: 800px) {
    padding: 0 3rem;
    padding-bottom: 10rem;
    ${({ $home }) => !$home && `padding-top: 10rem;`}
  }
`;

export const WrapperCenter = styled.div`
  width: 100%;
  padding: 7.5rem 0;

  @media (max-width: 800px) {
    padding: 3rem 0;
  }
`;

export const WrapperCenterSimpler = styled.div<{
  $gap: string;
  $reverse: boolean;
}>`
  width: 100%;
  padding: 7.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ $gap }) => ($gap ? $gap : '10rem')};

  @media (max-width: 800px) {
    padding: 3rem 0;
    gap: 5rem;
    flex-direction: column;
    ${({ $reverse }) => $reverse && ' flex-direction: column-reverse;'}
  }
`;

export const WrapperCenterSimplerHome = styled.div`
  position: relative;
  padding: 7.5rem 0;
  display: flex;
  align-items: center;
  gap: 5rem;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 800px) {
    gap: 3rem;
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media (max-height: 700px) {
    padding-top: 5rem;
  }

  @media (max-width: 500px) {
    gap: 0;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    padding-top: 8rem;
    flex-direction: row;
  }
`;

interface IDiv{
    $desktop?: boolean;
    $mobile?: boolean;
    $box?: boolean;
    $m?: string;
    $mt?: string;
    $mb?: string;
    $ml?: string;
    $mr?: string;
    $mSM?: string;
    $mtSM?: string;
    $mbSM?: string;
    $mlSM?: string;
    $mrSM?: string;
    $width?: string;
    $height?: string;
    $maxWidth?: string;
    $minWidth?: string;
    $widthMD?: string;
    $alignEnd?: boolean;
    $alignCenter?: boolean;
    $relative?: boolean;
    $pointer?: boolean;
    $gap?: string;
    $between?: boolean;
    $around?: boolean;
    $center?: boolean;
    $flexNone?: boolean;
    $flex?: boolean;
    $justifyEnd?: boolean;
    $column?: boolean;
    $wrap?: boolean;
    $textAlignLeft?: boolean;
    $textAlignRight?: boolean;
    $textAlignCenter?: boolean;
    $justifyCenter?: boolean;
    $p?: string;
    $pSM?: string;
    $pMD?: string;
    $minWidthSM?: string;
    $heightSM?: string;
    $widthSM?: string;
    $maxWidthSM?: string;
    $reverseSM?: boolean;
    $columnSM?: boolean;
    $gapSM?: string;
    $centerSM?: boolean;
    $flexSM?: boolean;
    $gapMD?: string;
    viewport?: any;
    variants?: any;
    $cursor?:string;
    $svgGray?:boolean;
    $overflowHidden?:boolean
}


export const Div = styled(motion.div)<IDiv>`
  ${({ $box, theme }) =>
    $box &&
    `
   background: ${theme.white};
   border: 0.1rem solid ${theme.secondary}33;
  box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.br1};
  padding: 2rem;
`}

  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt};
  margin-bottom: ${(props) => props.$mb};
  margin-left: ${(props) => props.$ml};
  margin-right: ${(props) => props.$mr};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  cursor: ${({$cursor}) => $cursor ?? 'auto'};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};

  padding: ${(props) => props.$p};

  ${({ $flex }) => $flex && 'display: flex'};
  ${({ $flexNone }) => $flexNone && 'flex: none'};
  ${({ $center }) => $center && 'align-items: center; justify-content:center;'};
  ${({ $between }) => $between && 'justify-content: space-between;'};
  ${({ $around }) => $around && 'justify-content: space-around;'};
  ${({ $gap, $flex }) => $flex && $gap && `gap:${$gap}`};
  ${({ $pointer }) => $pointer && 'cursor: pointer'};
  ${({ $relative }) => $relative && 'position: relative'};
  ${({ $alignCenter }) => $alignCenter && 'align-items: center'};
  ${({ $alignEnd }) => $alignEnd && 'align-items: flex-end'};
  ${({ $justifyEnd }) => $justifyEnd && 'justify-content: flex-end'};
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center'};
  ${({ $textAlignCenter }) => $textAlignCenter && 'text-align: center'};
  ${({ $textAlignRight }) => $textAlignRight && 'text-align: right'};
  ${({ $textAlignLeft }) => $textAlignLeft && 'text-align: left'};
  ${({ $overflowHidden }) => $overflowHidden && 'overflow:hidden'};


  ${({ $column }) => $column && 'flex-direction: column'};
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'};

  ${({ $svgGray,theme }) => $svgGray && `svg{path{stroke:${theme.secondary}}}`};

  @media (max-width: 1450px) {
    width: ${(props) => props.$widthMD};
    padding: ${(props) => props.$pMD};
    ${({ $gapMD }) => $gapMD && `gap: ${$gapMD}`};
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM};
    margin-bottom: ${(props) => props.$mbSM};
    margin-left: ${(props) => props.$mlSM};
    margin-right: ${(props) => props.$mrSM};
    width: ${(props) => props.$widthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    min-width: ${(props) => props.$minWidthSM};

    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};
  }

  @media (max-width: 800px) {
    padding: ${(props) => props.$pSM};
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    min-width: ${(props) => props.$minWidthSM};
    ${({ $heightSM }) => $heightSM && `height: ${$heightSM}`};
    max-width: ${(props) => props.$maxWidthSM};
    ${({ $flexSM }) => $flexSM && 'display: flex'};
    ${({ $centerSM }) =>
      $centerSM && 'align-items: center; justify-content:center;'};
    ${({ $gapSM }) => $gapSM && `gap: ${$gapSM}`};
    ${({ $columnSM }) => $columnSM && 'flex-direction: column'};
    ${({ $reverseSM }) => $reverseSM && 'flex-direction: column-reverse'};


  }

  @media (max-width: 450px) {
    .rest-graph{
      font-size:2rem;
    }
  }

  ${({ $desktop }) =>
    $desktop &&
    `
    @media (max-width: 800px) {
    display: none;
  }

  `};

  ${({ $mobile }) =>
    $mobile &&
    `
    @media (min-width: 800px) {
    display: none;
  }
  
 

  `};
`;

export const MotionDiv = styled(motion.div)``;

export const Img = styled.img<{
  $m?: string;
  $mt?: string;
  $mb?: string;
  $ml?: string;
  $mr?: string;
  $mSM?: string;
  $mtSM?: string;
  $mbSM?: string;
  $mlSM?: string;
  $mrSM?: string;
  $widthSM?: string;
  $minWidth?: string;
  $heightSM?: string;
  $maxWidthSM?: string;
  $maxHeightSM?: string;
  $height?: string;
  $width?: string;
  $maxWidth?: string;
  $maxHeight?: string;
}>`
  margin: ${(props) => props.$m};
  margin-top: ${(props) => props.$mt}px;
  margin-bottom: ${(props) => props.$mb}px;
  margin-left: ${(props) => props.$ml}px;
  margin-right: ${(props) => props.$mr}px;
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
  max-height: ${(props) => props.$maxHeight};
  min-width: ${(props) => props.$minWidth};

  @media (max-width: 1000px) and (orientation: landscape) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    height: ${(props) => props.$heightSM};
    max-width: ${(props) => props.$maxWidthSM};
  }

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    margin-top: ${(props) => props.$mtSM}px;
    margin-bottom: ${(props) => props.$mbSM}px;
    margin-left: ${(props) => props.$mlSM}px;
    margin-right: ${(props) => props.$mrSM}px;
    width: ${(props) => props.$widthSM};
    max-width: ${(props) => props.$maxWidthSM};
    max-height: ${(props) => props.$maxHeightSM};
  }
`;

export const Icon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  @media (max-width: 800px) {
    width: 3rem;
    height: 3rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const SocialIcon = styled.div`
  svg {
    width: 4.2rem;
    height: 4.2rem;
  }

  @media (max-width: 800px) {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
`;

export const Button = styled.button<{
  $w?: string;
  $p?: string;
  $selected?: boolean;
  $negative?: boolean;
  $green?: boolean;
  $m?: string;
  $mt?: string;
  $mSM?: string;
  $maxWidth?: string;
  $maxWidthSM?: string;
  $secondary?: boolean;
  $round?:boolean
}>`
  font-family: 'Montserrat';
  position: relative;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.white};
  border-radius: ${({ theme,$round }) => $round ? '5rem' : theme.borderRadius.br1};
  box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.primary};
  border: none;
  width: ${({ $w }) => ($w ? $w : '18.0rem')};
  overflow: hidden;
  ${({$mt})=>$mt && 'margin-top:'+$mt};
  transition: all 100ms ease-in;
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  ${({ $p }) => $p && 'padding: 0 3rem;'};

  &:active {
    transform: translateY(0.3rem);
  }

  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.primary};
  }

  ${({ $negative, theme }) =>
    $negative &&
    `
    border:  0.1rem solid ${theme.secondary}33;
    box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.1);
    color: ${theme.primary};

  `}

  ${({ $secondary, theme }) =>
    $secondary &&
    `
    color: ${theme.secondary};
    background: ${theme.white};
    box-shadow: 0 0 0 0.1rem ${theme.secondary};

    &:hover {
      color: ${theme.white};
      background: ${theme.secondary};
    }
  `}

  ${({ $selected, theme, $secondary }) =>
    $selected &&
    `
    color: ${theme.white};
    background: ${$secondary ? theme.secondary : theme.primary};
  `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
  `}

  margin: ${(props) => props.$m};
  max-width: ${(props) => props.$maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.$mSM};
    max-width: ${(props) => props.$maxWidthSM};
  }
`;

export const SliderPaginationCircle = styled.div<{ $selected: boolean }>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  /* margin: 1rem; */
  cursor: pointer;

  @media (max-width: 800px) {
    width: 1.2rem;
    height: 1.2rem;
  }

  ${({ $selected, theme }) => $selected && `background: ${theme.primary};`}
  ${({ $selected, theme }) =>
    !$selected && `border: 1px solid ${theme.secondary}33;`}
`;

export const Gradient1 = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 50%;
  left: 0;
  right: 0;
  background: radial-gradient(
    circle at 55% -10%,
    rgba(164, 192, 214, 1) 0%,
    rgba(255, 255, 255, 0) 30%
  );
  z-index: -1000;

  @media (max-width: 800px) {
    background: radial-gradient(
      circle at 55% -5%,
      rgba(164, 192, 214, 1) 0%,
      rgba(255, 255, 255, 1) 20%
    );
  }
`;

export const Gradient2 = styled(motion.div)`
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(
    circle at 45% 110%,
    rgba(164, 192, 214, 1) 0%,
    rgba(255, 255, 255, 0) 30%
  );
  z-index: -1000;

  @media (max-width: 800px) {
    background: radial-gradient(
      circle at 45% 105%,
      rgba(164, 192, 214, 1) 0%,
      rgba(255, 255, 255, 1) 20%
    );
  }
`;

export const SliderPlaceholder = styled.div`
  width: 100%;
  position: relative;

  > span {
    visibility: hidden;
  }

  > div {
    position: absolute;
    top: 0;
  }
`;

export const SeparatorBorderCMS = styled.div<{ $m?: string }>`
  display: flex;
  align-items: center;
  gap: 5rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.secondary}33;
  ${({ $m }) => $m && `margin:${$m}`};
`;

export const HR = styled.div<{$white?:boolean}>`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.secondary}33;
  ${({$white})=>$white && 'background-color: white;'}
  width: 100%;
`

export const ToggleButtonWrapper = styled.div<{$active:boolean}>`

  border-radius: 50px;
  width: 5rem;
  height:3rem;
  background-color: ${({theme, $active})=> !$active ? theme.white : theme.primary};
  position: relative;
  cursor: pointer;
  ${({$active,theme})=>!$active && 'border: 1px solid  '+ theme.secondary+33};
  transition: .3s ease-out;


  div{
    height: calc(100% - 4px);
    aspect-ratio: 1/1;
    background-color:  ${({theme, $active})=> $active ? theme.white : theme.primary};
    left: 2px;
    top:2px;
    position: absolute;
    border-radius: 50px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    transition: .3s ease-out;
    ${({$active})=> $active && 'left:calc(3rem - 7px)'}
  }

`

export const RadioInputStyled = styled.div<{$selected?:boolean}>`

  padding: 0 2rem;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;  
  justify-content: space-between;
  border-radius:50px;
  font-size: 13px;
  color:${({theme})=>theme.secondary};
  border: .5px solid ${({theme})=>theme.secondary};
  outline:none;
  cursor: pointer;
  background-color: white;
  padding: 0 3rem;
    ${({theme,$selected})=> $selected && 'box-shadow:'+theme.shadows.shadow1+';'}
    ${({theme })=>'border: 1px solid'+ theme.secondary + 33 + ';' }


  &::placeholder {
  opacity: .5; 
}

    &::-ms-input-placeholder {
    opacity: .5; 

    }


@media (max-width: 1190px) {
  svg{
    max-width: 20px;
    max-height: 20px;
    height: 20px;
    width: 20px;
  }
}


`

export const InputStyled = styled.input<{$textCenter?:boolean}>`
  padding: 0 2rem;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius:50px;
  font-size: 13px;
  color:${({theme})=>theme.secondary};
  border: .5px solid ${({theme})=>theme.secondary};
  ${({$textCenter})=>$textCenter && 'text-align:center;'}
  outline:none;

  &::placeholder {
  opacity: .5; 
}

    &::-ms-input-placeholder {
    opacity: .5; 

    }
`

export const TextDisplay = styled.div`
  padding: 0 2rem;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:50px;
  font-size: 13px;
  color:${({theme})=>theme.secondary};
  border: .5px solid ${({theme})=>theme.secondary};
`

export const Overlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.secondary}4D;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

export const variants = {
  initial: {
    transform: 'scale(0)',
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.8,
    },
  },
  animate: {
    transform: 'scale(1)',
  },

  exit: {
    transform: 'scale(0)',
    transition: {
      type: 'linear',
      duration: 0.3,
    },
  },
};

interface IContentWrapper extends IDiv{
  $scroll?:boolean
}

export const ContentWrapper = styled.div<IContentWrapper>`
  min-height: calc(100vh - 15rem);
  padding: 0 1rem;
  padding-top: 2rem;
  ${({ $flex }) => $flex && 'display: flex'};
  ${({ $scroll }) => $scroll && 'max-height: calc(100vh - 15rem); overflow: auto;'};
  ${({ $column }) => $column && 'flex-direction:column'};
  ${({ $flexNone }) => $flexNone && 'flex: none'};
  ${({ $center }) => $center && 'align-items: center; justify-content:center;'};
  ${({ $between }) => $between && 'justify-content: space-between;'};
  ${({ $around }) => $around && 'justify-content: space-around;'};
  ${({ $gap, $flex }) => $flex && $gap && `gap:${$gap}`};
  ${({ $pointer }) => $pointer && 'cursor: pointer'};
  ${({ $relative }) => $relative && 'position: relative'};
  ${({ $alignCenter }) => $alignCenter && 'align-items: center'};
  ${({ $alignEnd }) => $alignEnd && 'align-items: flex-end'};
  ${({ $justifyEnd }) => $justifyEnd && 'justify-content: flex-end'};
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center'};
  ${({ $textAlignCenter }) => $textAlignCenter && 'text-align: center'};
  ${({ $textAlignRight }) => $textAlignRight && 'text-align: right'};
  ${({ $textAlignLeft }) => $textAlignLeft && 'text-align: left'};
  ${({ $p }) => $p && 'padding: '+$p};
  ${({ $relative }) => $relative && 'positioin:relative'};
  @media (max-width: 600px) {
    padding:1rem;
  }

`

export const PageDateContainer = styled.div<{$noPadding?:boolean, $noSwitch?:boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({$noPadding})=>!$noPadding && ' padding: 0 2rem;'}
  margin-bottom: 2rem;

@media (max-width: 1190px) {
  ${({$noSwitch})=>!$noSwitch && 'flex-direction: column;'}
   gap:2rem;
  align-items: flex-end;
  width: 100%;
}
`


export const DateSelectsContainer = styled.div`

width: 100%;
display: flex;
justify-content: flex-end;
gap: 1rem;
align-items: center;

@media (max-width: 800px) {

  flex-direction: column-reverse;
  align-items: flex-end;

}


`

export const NoItemsContainer = styled.div`

  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  img{
    width: 30rem;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    top:60% !important;

}
`

export const BlueLink = styled.a`
  font-size: 1.2rem;
  color: ${({theme})=>theme.blue};
  text-decoration: underline;
  cursor: pointer;
`

export const RangeInputStyled = styled.input`
   -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 30rem;

/* Removes default focus */
&:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
&::-webkit-slider-runnable-track {
   background-color: ${props=>props.theme.secondary};
   border-radius: 0.5rem;
   height: 0.3rem;  
}

/* slider thumb */
&::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
   appearance: none;
   margin-top: -12px; /* Centers thumb on the track */

   /*custom styles*/
   background-color: white;
   height: 2.5rem;
   width: 2.5rem;
   border-radius: 50%;
   border: 1px solid #E3E3E3;
}

&:focus::-webkit-slider-thumb {   

}

/******** Firefox styles ********/
/* slider track */
&::-moz-range-track {
   background-color: #053a5f;
   border-radius: 0.5rem;
   height: 0.5rem;
}

/* slider thumb */
&::-moz-range-thumb {
   border: none; /*Removes extra border that FF applies*/
   border-radius: 0; /*Removes default border-radius that FF applies*/

   /*custom styles*/
   background-color: #5cd5eb;
   height: 2rem;
   width: 1rem;
}

&:focus::-moz-range-thumb {

}



`
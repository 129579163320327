import { ReactNode } from 'react';
import styled from 'styled-components';

const AuthLayoutStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: calc(100% - 30px);
    max-width: 45rem;
    border-radius: ${({ theme }) => theme.borderRadius.br1};
    padding: 3rem;
    /* text-align: center; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    background-color: ${({ theme }) => theme.white};
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    border: 1px solid ${({ theme }) => theme.secondary}33;
  }
`;

export const AdminAuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <AuthLayoutStyled>
      <div>{children}</div>
    </AuthLayoutStyled>
  );
};

import { Navigate, Outlet, useLocation } from 'react-router';
import { CMS_LOGIN, CMS_OTP } from 'routes/routeConstantsCMS';
import { AdminAnimation } from 'components';
import { useAuth } from 'store/auth';
import { AdminAuthLayout } from './AdminLayout/AdminAuthLayout';
import { AdminLayout } from './AdminLayout/AdminLayout';
import { SALES_ACC_CREATED, SALES_USER_INFO } from 'routes/routeConstantsSales';

function PrivateRoute({ children }: any) {
  const user = useAuth((state) => state.user);
  return user ? <>{children}</> : <Navigate to={CMS_LOGIN} />;
}

export const AdminLayoutRoot = () => {
  const location = useLocation();
  const pathname = location.pathname;


  if (pathname.includes('verification') || pathname === SALES_ACC_CREATED || pathname.includes('portal')) {
    return <AdminAnimation key='admin-layout-auth-outlet'>
      <Outlet />
    </AdminAnimation>
  }

  if (pathname === CMS_LOGIN || pathname === CMS_OTP || pathname === SALES_USER_INFO || pathname.includes('standalone'))
    return (
      <AdminAuthLayout>
        <AdminAnimation key='admin-layout-auth-outlet'>
          <Outlet />
        </AdminAnimation>
      </AdminAuthLayout>
    );

  return (
    <PrivateRoute>
      <AdminLayout>
        <AdminAnimation key='admin-layout-outlet'>
          <Outlet />
        </AdminAnimation>
      </AdminLayout>
    </PrivateRoute>
  );
};

import { DollarIcon, LocationIcon, UserIcon } from "assets"
import { AdminDatePicker } from "components/DatePicker/AdminDatePicker"
import { IconCircle } from "pagesPartner/Orders/orders.styled"
import { useEffect, useState } from "react"
import { ContentWrapper, Div, H2, H3, H4, HR, P, PageDateContainer } from "styles"
import { BankCard, BlueButton, HomeSection, BankContainer } from "./home.styled"
import VPay from '../../assets/images/logos/VPay.png'
import PlaidLogo from '../../assets/images/logos/PlaidLogo.png'
import DwollaLogo from '../../assets/images/logos/DwollaLogo.png'
import { useNavigate } from "react-router"
import { SALES_CONNECT_BANK } from "routes/routeConstantsSales"
import { useHome } from "store/home"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { displayDateRange, setDocumentTitle, setTimeTo } from "helpers"

export const SalesHome = () => {
    const [dates, setDates] = useState([setTimeTo(new Date(), 'start'), setTimeTo(new Date(), 'end')])
    const { loading, getHomeData, data, wallet, getWallet, resendEmail } = useHome(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        getHomeData(dates)
        getWallet()
    }, [dates])

    setDocumentTitle("Grub Concierge Sales");


    return <ContentWrapper>
        <SpinnerFullscreen loading={loading} />
        <PageDateContainer $noPadding >
            <H4 $noTranform $m="0" $bold $textSpacing="0" >{displayDateRange(dates)}</H4>
            <Div $flex $between $gap="1rem" $alignCenter>
                <AdminDatePicker range={true} handleApply={(d: Date[]) => setDates(d)} dt={dates} dateFrom={new Date()} dateTo={new Date()} />
            </Div>
        </PageDateContainer>
        <Div $flex $column $gap="1rem" >
            <HomeSection>
                <Div $flex $gap="2rem" $alignCenter >
                    <IconCircle>
                        <DollarIcon />
                    </IconCircle>
                    <H4 $noTranform $textSpacing="0" $bold $mb="0" >Earnings</H4>
                </Div>
                <H3 $mb="0" color="primary" >${data?.earnings.toFixed(2) || 0}</H3>
            </HomeSection>
            <HomeSection>
                <Div $flex $gap="2rem" $alignCenter >
                    <IconCircle>
                        <DollarIcon />
                    </IconCircle>
                    <H4 $noTranform $textSpacing="0" $bold $mb="0" >Pipeline  Earnings</H4>
                </Div>
                <H3 $mb="0" color="primary" >${data?.pipelineEarnings.toFixed(2) || 0}</H3>
            </HomeSection>
            <HomeSection>
                <Div $flex $gap="2rem" $alignCenter >
                    <IconCircle>
                        <LocationIcon />
                    </IconCircle>
                    <H4 $noTranform $textSpacing="0" $bold $mb="0" >Locations</H4>
                </Div>
                <H3 $mb="0" color="primary" >{data?.locationCount || 0}</H3>
            </HomeSection>
            <HomeSection>
                <Div $flex $gap="2rem" $alignCenter >
                    <IconCircle>
                        <UserIcon />
                    </IconCircle>
                    <H4 $noTranform $textSpacing="0" $bold $mb="0" >Sales Agents</H4>
                </Div>
                <H3 $mb="0" color="primary" >{data?.agentCount || 0}</H3>
            </HomeSection>
        </Div>
        {/* <BankContainer >
            <BankCard>
                <Div $flex $alignCenter $between $width="100% ">
                    <img src={VPay} alt="" />
                    <Div>
                        <P $right $color="white" $mb="0" >Virtual Order</P>
                        <P $right $color="white" $mb="0" >Available Balance </P>
                        <H2 $mb="0" color="white" >${wallet?.balance.availableBalance || 0}</H2>
                    </Div>
                </Div>
                <HR $white />
                <BlueButton><H4 $mb="0" $textSpacing="0" $bold $noTranform color="lightBlue" onClick={() => navigate(SALES_CONNECT_BANK)} >Connect Bank</H4></BlueButton>
            </BankCard>
            <Div $width="100%" >
                <P $mb="0">Powered by:</P>
                <Div $m="3rem 0" $flex $gap="3rem" >
                    <img src={PlaidLogo} alt="" />
                    <img src={DwollaLogo} alt="" />
                </Div >
                <HR />
                {wallet?.agentVerified && <><P $mt="4rem" $mb="1rem" >You email is not activated yet!</P>
                    <H4 $bold $noTranform $textSpacing="0" color="primary" onClick={resendEmail} >Resend activation link</H4></>}
            </Div>
        </BankContainer> */}

    </ContentWrapper>
}
import api from "api";
import { SuccessToast } from "components/Toasts/Toasts";
import { handleError, isSales } from "helpers";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface AuthState {
  user: IUserState;
  agent:any; //srediti interaface
  loading: boolean;
  tokens: any;
  updateAuthState: (data: any, isFromComplete?:boolean) => void;
  removeAuthState: () => void;
  updateNotificationStatus: (data: boolean) => void;
  deleteAccount: () => void;
  completeAccount: (info: ICompleteUserPayload) => void;
  sendCodeToEmail:(role:string,phoneNumber:string)=>void
  cancelLocation:(id:string)=>void
  isVerificationInfoSent:(b:boolean)=>void
  confirmVerification:()=>void
}

export const useAuth = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        loading: false,
        tokens: null,
        agent:null,
        updateNotificationStatus: (data) =>
          set((state) => ({
            user: { ...state.user, notificationsEnabled: data },
          })),
        updateAuthState: (data, isFromComplete) =>
            set((state) => {
              let obj:any = {
                user: { ...data.user },
                agent: data.agent,
                tokens: data.tokens,
              }
              if(isFromComplete){
                obj.agent = state.agent
              }else if(isSales && state.agent?.idCardBackUrl && state.agent?.idCardFrontUrl){
                obj.verificationSent = true
              }
              return obj
            }
          ),
        removeAuthState: () =>
          set(() => ({ user: null, tokens: null, loading:false })),
        deleteAccount: async () => {
          try {
            set({ loading: true });
            await api.delete("v1/users");
            set({ loading: false });
            SuccessToast("Account successfully deleted");
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        completeAccount: async (info: ICompleteUserPayload) => {
          try {
            set({ loading: true });
            const { data } = await api.put("v1/users/complete", info);
            set(state=>({ loading: false, user: {...data.data, incompleteLocation:state.user.incompleteLocation} }));
            SuccessToast("Account successfully completed");
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        sendCodeToEmail:async (role:string,phoneNumber:string)=>{
          try {
            set({ loading: true });
            await api.post("v1/auth/resend-otp-email", {role,phoneNumber});
            set({ loading: false });
            SuccessToast("Code successfully sent to email");
          } catch (error) {
            handleError(error);
            set({ loading: false });
          }
        },
        cancelLocation:async(id:string)=>{
          try {
              set({loading:true})
              await api.delete("v1/locations/" + id + "/cancel-creation");
              set(state=>({loading:false, user:{...state.user}}))
          } catch (error) {
              handleError(error);
              set({loading:false})
          }
      },
      isVerificationInfoSent:(b:boolean)=>set((state) => ({ agent:{...state.agent,verificationSent:b} })),
      confirmVerification:()=>set((state) => ({ agent:{...state.agent, verified:true} }))
      }),
      { name: "grubcon-auth" }
    )
  )
);

interface ICompleteUserPayload {
  firstName: string;
  lastName: string;
  email: string;
}

interface ISalesUserResponse {
  id: number;
  phoneNumber: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  deleted: boolean;
  role: string;
  completed: boolean;
  termsAccepted: boolean;
  emailVerified: boolean;
  notificationsEnabled: boolean;
  fcmToken: string;
  createdAt: string;
  updatedAt: string;
}

interface IUserState extends ISalesUserResponse {
  incompleteLocation?: IIncompleteLocation;
}

export interface IIncompleteLocation {
  id: number;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  name: string;
  confirmed: boolean;
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  streetNumber: string;
  phone: string;
  menuSignInProviders: {
    name: string;
    image: string;
  }[];

  menuSignInProvidersSkipped: boolean;
  businessLicense: string;
}

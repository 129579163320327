import { useDebounce } from "@uidotdev/usehooks"
import { CirlceCheckIcon } from "assets"
import { SingleRestaurantOption } from "components/dropdownButton/dropdownButton.styled"
import { Input } from "components/input/Input"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { isAdmin } from "helpers"
import useDidMountEffect from "hooks/useDidMountEffect"
import { MiniCircle } from "pagesPartner/FindRestaurant/findRestaurants.styled"
import { OptionsWrapper, ResellerOptionsContainer } from "pagesPartner/Orders/orders.styled"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { useAgents } from "store/agents"
import { useRestaurants } from "store/restaurants"
import { Button, Div, H4, variants } from "styles"

export const ResellersModal = ({ handleClose, referralId }: { handleClose: any, referralId?: number }) => {

    const { getAgents, loading, agents, clearAgents, getAdminAgents } = useAgents(state => state)
    const [selectedAgent, setSelectedReseller] = useState({ id: referralId || null, firstName: '', lastName: '' })
    const { updateSingleRestaurant } = useRestaurants(state => state)
    const { id } = useParams()
    const [query, setQuery] = useState('')
    const deboundedQuery = useDebounce<string>(query, 200)
    const containerRef = useRef(null)
    const [params, setParams] = useState({ page: 1, limit: 10, query: '' })

    const fetchData = (q?: boolean) => {
        if (isAdmin) {
            const p: any = { ...params }
            if (q) {
                p.page = 1
            }
            if (deboundedQuery) {
                p.query = deboundedQuery
            }
            getAdminAgents(p, !q)
        } else {
            getAgents(params, !q)
        }
    }

    useEffect(() => {
        fetchData()
    }, [params])


    useDidMountEffect(() => {
        fetchData(true)
    }, [deboundedQuery])

    useEffect(() => {
        return (() => {
            clearAgents()
        })
    }, [])

    const handleConfirm = async () => {

        if (selectedAgent.id === referralId) {
            handleClose()
            return
        }

        await updateSingleRestaurant(Number(id), selectedAgent, null)
        handleClose()
    }


    const handleSelect = (r: any) => {
        setSelectedReseller(r)
    }

    const getIcon = (id: number) => {

        let check = selectedAgent.id == id

        return check ? <CirlceCheckIcon stroke="#C28831" /> : <MiniCircle />

    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollHeight - scrollTop - clientHeight < 100) {
            setParams(prev => {
                const copy = structuredClone(prev)
                copy.page = copy.page + 1
                return copy
            })
        }
    };

    const throttleScroll = () => {
        let timeout: any
        return () => {
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    handleScroll();
                }, 800); // Adjust the throttle time as needed
            }
        };
    };

    const throttledScroll = throttleScroll();

    const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

    return <ResellerOptionsContainer
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{
            type: 'spring',
            bounce: 0.4,
            duration: 0.8,
        }}

    >
        <SpinnerFullscreen loading={loading} />
        <Input $width='36rem' onChange={handleQuery} $search placeholder="Search Agents" />
        <OptionsWrapper ref={containerRef} onScroll={throttledScroll} >
            {agents?.map((r: any) => (
                <SingleRestaurantOption key={r.id} onClick={(e) => handleSelect(r)}>
                    <Div $width="100%" $flex $between $alignCenter >
                        <Div $height="100%" $flex $column $between>
                            <H4 $bold $noTranform $textSpacing="0" $mb="0">
                                {r.firstName + ' ' + r.lastName}
                            </H4>

                        </Div>
                        {getIcon(r.id)}
                    </Div>
                </SingleRestaurantOption>
            ))}
        </OptionsWrapper>
        <Div $flex $gap="1rem" $width="100%" style={{ padding: '0 2rem' }} >
            <Button $mt="2rem" $round $selected $w="100% " onClick={handleConfirm}>
                CONFIRM
            </Button>

            <Button $mt="2rem" $round $secondary $w="100% " onClick={handleClose}>
                CANCEL
            </Button>
        </Div>
    </ResellerOptionsContainer>
}
import { CloseIcon, PlusCircleIcon } from "assets"
import { Input } from "components/input/Input"
import { SpinnerFullscreen } from "components/spinner/spinner"
import { ErrorToast, SuccessToast } from "components/Toasts/Toasts"
import { AnimatePresence } from "framer-motion"
import { getImage, isAdmin } from "helpers"
import React, { useRef, useState } from "react"
import { useParams } from "react-router"
import { useRestaurants } from "store/restaurants"
import { Button, Div, H3, SeparatorBorderCMS } from "styles"
import { BrandImageCircle, ProvidersModalContainer } from "./restaurants.styled"

export const BrandModal = ({ handleClose }: { handleClose: any }) => {

    const { createBrand, loading } = useRestaurants(state => state)
    const [name, setName] = useState('')
    const ref = useRef()
    const [image, setImage] = useState(null)
    const { id } = useParams()
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    };

    const handleConfirm = async () => {

        if (!name || name.length < 3) {
            ErrorToast('Please enter brand name')
            return
        }

        if (!image) {
            ErrorToast('Please select brand image')
            return
        }
        await createBrand(image, { locationId: Number(id), name })
        SuccessToast('Brand successfully added.')
        handleClose()
    }

    const handleCheck = async (e: any) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    }

    return <AnimatePresence>
        <ProvidersModalContainer $maxWidth="90vw" $width="50rem" >
            <SpinnerFullscreen loading={loading} />
            <Div $mb="3rem" $flex $gap="2rem" $alignCenter $width="100%" $between >
                <H3 $mb="0" color="primary">
                    Add New Brand
                </H3>
                <Div $pointer onClick={() => handleClose()} >
                    <CloseIcon />
                </Div>
            </Div>
            <SeparatorBorderCMS />
            <Div $mt="3rem" $mb="3rem" $width="100%" $flex $center >
                <BrandImageCircle onClick={() => (ref as any)?.current.click()} >
                    {image ? <img src={getImage(image)} alt="" /> : <PlusCircleIcon />}
                    <input hidden
                        type="file"
                        accept="image/*"
                        ref={ref}
                        onChange={handleCheck} />
                </BrandImageCircle>
            </Div>
            <Div $mt="2rem" $mb="2rem" $flex $column $gap="2rem" $width="100%">
                <Input
                    label="Brand Name"
                    defaultValue={name}
                    onChange={onChange}
                    name="brandName"
                    placeholder="Enter here"
                />
            </Div>
            <Div $flex $gap="1rem" >
                <Button $mt="2rem" $round $selected $w="100% " onClick={handleConfirm}>
                    CONFIRM
                </Button>

                <Button $mt="2rem" $round $secondary $w="100% " onClick={handleClose}>
                    CANCEL
                </Button>
            </Div>

        </ProvidersModalContainer>
    </AnimatePresence>
}

import { partnerImage } from 'assets';
import { AnimatedDiv, FrameWrapper, Separator } from 'components';
import { WIDGET_BOOKING_URL, partnerBookingId } from 'helpers';
import { Div, H1, H4, Img, P, PageWrapper } from 'styles';

export const Partner = () => {
  return (
    <PageWrapper>
      <Div $flex $gap='50px' $columnSM $alignCenter>
        <Div $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <H4>Partner</H4>
            <H1>
              Reach out to us! <br />
              <span>Let’s partner up. </span>
            </H1>
            <Div $maxWidth='50rem'>
              <P>
                <b>
                  Are you a business, POS, Delivery Provider, looking to provide
                  top notch customer support to your client restaurateurs?
                  <br />
                  <br />
                  <span>We’ve got you covered.</span>
                </b>
                <br />
                <br />
                Set up a time to speak with a GRUBCON representative by filling
                out the form below.
              </P>
            </Div>
          </AnimatedDiv>
        </Div>
        <Div $desktop $width={'50%'} $widthMD={'50%'} $widthSM={'100%'}>
          <AnimatedDiv>
            <Img src={partnerImage} alt='' $width={'100%'} $widthSM={'100%'} />
          </AnimatedDiv>
        </Div>
      </Div>
      <AnimatedDiv>
        <FrameWrapper
          height={850}
          title='contact-dorm'
          src={`${WIDGET_BOOKING_URL}${partnerBookingId}`}
          id={partnerBookingId}
        ></FrameWrapper>
      </AnimatedDiv>
      <Separator />
    </PageWrapper>
  );
};
